const fields = [
  {
    label: 'Caption',
    name: 'caption',
    default: '',
    required: true
  },
  {
    label: 'Description',
    name: 'description',
    default: '',
    required: true
  },
  {
    label: 'From Date',
    name: 'from',
    default: '',
    required: true
  },
  {
    label: 'To Date',
    name: 'to',
    default: '',
    required: true
  },
  {
    label: 'Target Value',
    name: 'targetValue',
    default: 0,
    required: true
  }
];

export default fields;
