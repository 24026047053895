const getRecordTypes = (objectType) => {
  switch (objectType) {
    case 'lead':
      return ['Web Lead', 'Email Lead'];
    case 'contact':
      return ['Default'];
    case 'deal':
      return ['Web', 'Email'];
    case 'order':
      return ['Default'];
    case 'account':
      return ['Business Account', 'Person Account'];
    case 'task':
      return ['Default'];
    case 'case':
      return ['Default'];
    case 'user':
      return ['Default'];
    case 'tenant':
        return ['Internal', 'Customer'];
    case 'tenant-plan':
      return ['Standard', 'Custom'];
    default:
      return ['Default'];
  }
};

export default getRecordTypes;
