const fields = [
    {
        label: 'Record Type',
        name: 'recordType',
        id: 'contactRecordType',
        type: 'picklist',
        options: ['Contact'],
        default: '',
        required: true
    },
    {
        label: 'Contact Owner',
        name: 'owner',
        id: 'contactOwner',
        type: 'lookup',
        lookupTo: 'users',
        placeholder: 'Search Users...',
        default: null,
        required: false
    },
    {
        label: 'First Name',
        name: 'firstName',
        id: 'contactFirstName',
        type: 'text',
        default: '',
        required: true
    },
    {
        label: 'Last Name',
        name: 'lastName',
        id: 'contactLastName',
        type: 'text',
        default: '',
        required: true
    },
    {
        label: 'Title',
        name: 'salutation',
        id: 'contactSalutation',
        type: 'picklist',
        options: ['Mr.', 'Ms.', 'Mrs.', 'Dr.', 'Prof.'],
        default: '',
        required: false
    },
    {
        label: 'Job Title',
        name: 'title',
        id: 'contactTitle',
        type: 'text',
        default: '',
    },
    {
        label: 'Department',
        name: 'department',
        id: 'contactDepartment',
        type: 'text',
        default: '',
    },
    {
        label: 'Assistant',
        name: 'assistant',
        id: 'contactAssistant',
        type: 'text',
        default: '',
    },
    {
        label: 'Asst. Phone',
        name: 'assistantPhone',
        id: 'contactAssistantPhone',
        type: 'text',
        default: '',
    },
    {
        label: 'Birthday',
        name: 'birthday',
        id: 'contactBirthday',
        type: 'date',
        default: '',
    },
    {
        label: 'Lead Source',
        name: 'leadSource',
        id: 'contactLeadSource',
        type: 'picklist',
        options: ['Web', 'Email'],
        default: '',
        required: true
    },
    {
        label: 'Email',
        name: 'email',
        id: 'contactEmail',
        type: 'email',
        default: '',
        required: true
    },
    {
        label: 'Email Opt-Out',
        name: 'emailOptOut',
        id: 'contactEmailOptOut',
        type: 'checkbox',
        default: false,
    },
    {
        label: 'Phone',
        name: 'phone',
        id: 'contactPhone',
        type: 'text',
        default: '',
    },
    {
        label: 'Mobile',
        name: 'mobile',
        id: 'contactMobile',
        type: 'text',
        default: '',
    },
    {
        label: 'Home Phone',
        name: 'homePhone',
        id: 'contactHomePhone',
        type: 'text',
        default: '',
    },
    {
        label: 'Other Phone',
        name: 'otherPhone',
        id: 'contactOtherPhone',
        type: 'text',
        default: '',
    },
    {
        label: 'Mailing Street',
        name: 'mailingAddress.street',
        id: 'contactMailingStreet',
        type: 'text',
        default: '',
        required: false
    },
    {
        label: 'Mailing City',
        name: 'mailingAddress.city',
        id: 'contactMailingCity',
        type: 'text',
        default: '',
    },
    {
        label: 'Mailing Province',
        name: 'mailingAddress.province',
        id: 'contactMailingProvince',
        type: 'text',
        default: '',
    },
    {
        label: 'Mailing Zip',
        name: 'mailingAddress.zip',
        id: 'contactMailingZip',
        type: 'text',
        default: '',
    },
    {
        label: 'Mailing Country',
        name: 'mailingAddress.country',
        id: 'contactMailingCountry',
        type: 'text',
        default: '',
        required: false
    },
    {
        label: 'Other Street',
        name: 'otherAddress.street',
        id: 'contactOtherStreet',
        type: 'text',
        default: '',
    },
    {
        label: 'Other City',
        name: 'otherAddress.city',
        id: 'contactOtherCity',
        type: 'text',
        default: '',
    },
    {
        label: 'Other Province',
        name: 'otherAddress.province',
        id: 'contactOtherProvince',
        type: 'text',
        default: '',
    },
    {
        label: 'Other Zip',
        name: 'otherAddress.zip',
        id: 'contactOtherZip',
        type: 'text',
        default: '',
    },
    {
        label: 'Other Country',
        name: 'otherAddress.country',
        id: 'contactOtherCountry',
        type: 'text',
    },
    {
        label: 'Contact Currency',
        name: 'currency',
        id: 'contactCurrency',
        type: 'picklist',
        options: ['EUR - Euro', 'ZAR - South African Rand', 'USD - US Dollar'],
        default: 'ZAR - South African Rand',
        required: false
    },
    {
        label: 'Account Name',
        name: 'account',
        id: 'contactAccountLookup',
        type: 'lookup',
        lookupTo: 'accounts',
        placeholder: 'Search Accounts...',
        default: null,
        required: false
    },
    {
        label: 'Reports To',
        name: 'reportsTo',
        id: 'contactReportsTo',
        type: 'lookup',
        lookupTo: 'contacts',
        placeholder: 'Search Contacts...',
        default: null,
    },
    {
        label: 'Engagement History',
        name: 'engagementHistory',
        id: 'contactEngagementHistory',
        type: 'text',
        default: '',
    },
    {
        label: 'Description',
        name: 'description',
        id: 'contactDescription',
        type: 'textarea',
        default: '',
        required: false
    },
    {
        label: 'Fax',
        name: 'fax',
        id: 'contactFax',
        type: 'text',
        default: '',
        required: false
    }
];

export default fields;