import { useContext, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../../../contexts/AuthContext';
import { getRecords } from '../../../api/crud';
import details from '../../../objectManager/account/objectDetails';
import Header from '../../../layouts/header/Header';
import RecordForm from './recordForm/RecordForm';
import ListViewTable from '../../../components/listViewTable/ListViewTable';
import DetailsPage from '../../../components/detailsPage/DetailsPage';
import { beginningOfDay, endingOfDay } from '../../../utils/dateUtils';
import SEO from '../../../components/seo/SEO';
import CustomLink from '../../../components/customLink/CustomLink';

const Accounts = ({ showDetailsPage }) => {
  const { getUserId } = useContext(AuthContext);
  const { listId } = useParams();
  const { data: records, refetch, isLoading} = useQuery({
    queryFn: () => getRecords(details.apiNamePlural, listId ? listViews.find(list => list._id === listId) : null),
    queryKey: [details.apiNamePlural, listId]
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name', // Accessor for searching
        id: 'name',
        header: 'Name',
        Cell: ({ row }) => (
          <CustomLink to={`/account/${row.original._id}`} text={row.original.name} />
        ),
      },
      {
        accessorKey: 'industry',
        header: 'Industry'
      },
      {
        accessorKey: 'type',
        header: 'Type'
      },
      {
        accessorKey: 'website',
        header: 'Website'
      },
      {
        accessorFn: (row) => row.owner ? `${row.owner.firstName} ${row.owner.lastName}` : '', //accessorFn used to join multiple data into a single cell
        id: 'owner', //id is still required when using accessorFn instead of accessorKey
        header: 'Account Owner'
      },
    ],
  []
  );

  const importColumns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Name'
      },
      {
        accessorKey: 'industry',
        header: 'Industry'
      },
      {
        accessorKey: 'type',
        header: 'Type'
      },
      {
        accessorKey: 'website',
        header: 'Website'
      },
    ],
  []
  );

  const listViews = [
    {
      _id: 'bc89a859-cb91-4f70-b1d2-9211dde5138e',
      label: 'All Accounts',
      filterLogic: null,
      filters: []
    },
    {
      _id: '70b0d86f-db1a-404e-8ff9-83a9c4026985',
      label: 'My Accounts',
      filterLogic: null,
      filters: [
        {
          field: 'owner',
          operator: 'equals',
          value: getUserId()
        }
      ]
    },
    {
      _id: '6153902b-f2a7-4955-9aba-1813cad5d7a3',
      label: 'Added Today',
      filterLogic: '1 AND 2',
      filters: [
        {
          'field': 'createdAt',
          'operator': 'greaterOrEqual',
          'value': beginningOfDay // Start timestamp for today
        },
        {
          'field': 'createdAt',
          'operator': 'lessOrEqual',
          'value': endingOfDay // End timestamp for today
        }
      ]
    }
  ];

  return (
    <>
      <SEO
        title={`${details.pluralLabel} | Salesdam`}
        description={details.description}
      />
      <Header
        objectType={details.apiName}
        infoText={details.description}
        type='listview'
        listViews={listViews}
      />
      {showDetailsPage && (
        <DetailsPage
          objectType={details.apiName}
          listViewLink={`/${details.apiNamePlural}`}
          listViewQueryKey={details.apiNamePlural}
          RecordForm={<RecordForm />}
        />
      )}
      <div className="list-view">
        <ListViewTable
          enableRowActions={true}
          enableRowSelection={true}
          isLoading={isLoading}
          objectType={details.apiName}
          objectTypePlural={details.apiNamePlural}
          refetch={refetch}
          listViewQueryKey={details.apiNamePlural}
          columns={columns}
          importColumns={importColumns}
          data={records}
          RecordForm={<RecordForm />}
        />
      </div>
    </>
  );
};

export default Accounts;