import Card from '@mui/material/Card';
// import EditSalesTargetModal from './EditSalesTargetModal';
import EditChartModal from '../modals/editChartModal/EditChartModal';
import GaugeChart from '../charts/gauge/GaugeChart';
import formatToRand from '../../utils/formatToRands';

const SalesTargets = ({ chartRecord, caption, target, value, from, to, isLoading }) => {

  return (
    <Card style={{ position: 'relative' }}>
      {chartRecord && <EditChartModal chartRecord={chartRecord} />}
      <GaugeChart
        chartRecord={chartRecord}
        caption={caption}
        target={target}
        value={value}
        fromDate={from}
        toDate={to}
        bottomText={`${formatToRand(value)}/${formatToRand(target)}`}
        isLoading={isLoading}
      />
    </Card>
  );
};

export default SalesTargets;
