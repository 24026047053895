import styles from './css/Stages.module.css';

export default function Stages({ isClosedSuccessful, stages, isEditable, currentStage, markCurrentStageClick }) {
  const currentStageIndex = stages.findIndex((stage) => stage.value === currentStage);

  const isNotEditableStyles = {
    // cursor: 'none'
  };

  return (
    <ul className={`${styles['stages']} prevent-select`}>
      {stages?.map((stage, index) => (
        <li
          key={stage.value}
          style={!isEditable ? isNotEditableStyles : {}}
          className={index <= currentStageIndex ? (!isClosedSuccessful ? styles['closed-unsuccessful'] : styles['active']) : ''}
          onClick={() => isEditable ? markCurrentStageClick(stage) : ''}
        >
          {stage.label}
        </li>
      ))}
    </ul>
  );
}
