import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {IconButton, Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions, Button} from '@mui/material';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import Field from '../field/Field';

const SettingsMenu = ({objectType, objectTypePlural}) => {
  const navigate = useNavigate();
  const [listViewLabel, setListViewLabel] = useState('');
  const [sharingSettings, setSharingSettings] = useState('Share Filter with Everyone');
  const [field, setField] = useState(null);
  const [operator, setOperator] = useState('AND');
  const [value, setValue] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogType, setDialogType] = useState('');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (type) => {
    if(type === 'kanban'){
      navigate(`/${objectTypePlural}/kanban`); // navigate to objectType/kanban
      handleClose();
    } else {
      setDialogType(type);
      setOpenDialog(true);
      handleClose();
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <div>
      <IconButton onClick={handleClick} aria-label='settings'>
        <SettingsOutlinedIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {/* <MenuItem onClick={() => handleMenuItemClick('edit')}>
          Edit Current List View
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick('create')}>
          Create New List View
        </MenuItem> */}
        {(objectType === 'task' || objectType === 'case' || objectType === 'lead' || objectType === 'deal') && (
          <MenuItem onClick={() => handleMenuItemClick('kanban')}>
            kanban View
          </MenuItem>
        )}
        {(objectType === 'contact' || objectType === 'account' || objectType === 'user') && (
          <MenuItem>
            Empty
          </MenuItem>
        )}
      </Menu>

      {/* Dialog for Edit or Create */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth='xl'
        fullWidth
        PaperProps={{
          style: {
            height: '100vh',
            width: '50%', // Adjust width as needed
            position: 'absolute',
            top: 0,
            right: 0,
            margin: 0,
          },
        }}
      >
        <DialogTitle sx={{display: 'flex', justifyContent: 'space-between'}}>
            {dialogType === 'edit' ? 'Edit Current List View' : 'Create New List View'}
            <IconButton onClick={handleCloseDialog} aria-label='cancel'>
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent>
          <Field
            id='listViewLabel'
            label='List view label'
            name='listViewLabel'
            type='text'
            onChange={(e) => setListViewLabel(e.target.value)}
            value={listViewLabel}
            required={true}
          />
          <Field
            id='sharingSettings'
            label='Sharing Settings'
            name='sharingSettings'
            type='radio'
            options={['Just For Me', 'Share Filter with Everyone']}
            onChange={(e) => setSharingSettings(e.target.value)}
            value={sharingSettings}
            required={true}
          />
          <h4 style={{margin: '8px 0'}}>Add Filters</h4>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '10px' }}>
            <Field
                id='field'
                label='Field'
                name='field'
                type='lookup'
                lookupTo=''
                onChange={(e) => setField(e.target.value)}
                value={field}
                required={true}
            />
            <Field
                id='operator'
                label='Operator'
                name='operator'
                type='picklist'
                options={['AND', 'OR']}
                onChange={(e) => setOperator(e.target.value)}
                value={operator}
                required={true}
            />
            <Field
                id='value'
                label='Value'
                name='Value'
                type='text'
                onChange={(e) => setValue(e.target.value)}
                value={value}
            />
            <IconButton aria-label='add'>
                <AddIcon />
            </IconButton>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              color: 'var(--primary-color)'
            }}
            onClick={handleCloseDialog}
          >
            Cancel
          </Button>
          <Button
            style={{
              color: 'var(--primary-color)'
            }}
            onClick={handleCloseDialog}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SettingsMenu;
