import { useState, useEffect } from 'react';
import styles from './css/FieldMapping.module.css';
import { Button } from '@mui/material';
import toCamelCase from '../../../utils/convertToCamelCase';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const FieldMapping = ({ columns, fields, onMappingComplete }) => {
  const [mapping, setMapping] = useState({});
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const initialMapping = {};
    columns.forEach((column) => {
      const camelCaseColumn = toCamelCase(column);
      const matchingField = fields.find(
        (field) =>
          field.label.toLowerCase() === column.toLowerCase() ||
          field.name.toLowerCase() === column.toLowerCase() ||
          field.name === camelCaseColumn
      );
      if (matchingField) {
        initialMapping[column] = matchingField.name;
      }
    });
    setMapping(initialMapping);
  }, [columns, fields]);

  const handleMappingChange = (e, column) => {
    setMapping({
      ...mapping,
      [column]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if all required fields are mapped
    const requiredFields = fields.filter((field) => field.required);
    const mappedFields = Object.values(mapping);
    const missingRequiredFields = requiredFields.filter((field) => !mappedFields.includes(field.name));

    if (missingRequiredFields.length > 0) {
      // Show error message for missing required fields
      const missingFields = missingRequiredFields.map((field) => field.label).join(', ');
      setErrorMessage(`Please map required field(s): ${missingFields}`);
      setShowError(true);
      return;
    }

    // If all required fields are mapped, proceed with mapping
    onMappingComplete(mapping);
  };

  return (
    <>
      <h4 className={styles['map-fields-header']}>Map fields on the spreadsheet with fields on Salesdam <InfoOutlinedIcon/> </h4>
      <form className={styles.form_container} onSubmit={handleSubmit}>
        {columns.map((column) => (
          <div key={column}>
            <label>{column}</label>
            <select
              value={mapping[column] || ''}
              onChange={(e) => handleMappingChange(e, column)}
              className={showError && !mapping[column] ? styles.error : ''}
            >
              <option value="">Select a field</option>
              {fields.map((field) => (
                <option key={field.name} value={field.name} title={field.name}>
                  {field.label}
                </option>
              ))}
            </select>
          </div>
        ))}
        {showError && <div className={styles['error-message']}>{errorMessage}</div>}
        <Button
          style={{backgroundColor: 'var(--primary-color)'}}
          size='small'
          variant='contained'
          type='submit'
        >
          Map Fields
        </Button>
      </form>
    </>
  );
};

export default FieldMapping;