const fields = [
    {
        label: 'Name',
        name: 'name',
        id: 'tenantPlanName',
        type: 'text',
        default: '',
        required: true
    },
    {
        label: 'Record Type',
        name: 'recordType',
        id: 'tenantPlanRecordType',
        type: 'picklist',
        options: ['Standard', 'Custom'], // Custom Plans are customizable according to the Customer's needs
        default: 'Standard',
        required: true
    },
    {
        label: 'Description',
        name: 'description',
        id: 'tenantPlanDescription',
        type: 'textarea',
        default: '',
        required: true
    },
    {
        label: 'Maximum Number of Users',
        name: 'maxUsers',
        id: 'tenantPlanMaxUsers',
        type: 'number',
        default: 0,
        required: true
    },
    {
        label: 'Maximum Storage (mb)',
        name: 'maxStorage',
        id: 'tenantPlanMaxStorage',
        type: 'number',
        default: 0,
        required: true
    },
    {
        label: 'Maximum Cases',
        name: 'maxCases',
        id: 'tenantPlanMaxCases',
        type: 'number',
        default: 0,
        required: true
    },
    {
        label: 'Maximum Leads',
        name: 'maxLeads',
        id: 'tenantPlanMaxLeads',
        type: 'number',
        default: 0,
        required: true
    },
    {
        label: 'Maximum Tasks',
        name: 'maxTasks',
        id: 'tenantPlanMaxTasks',
        type: 'number',
        default: 0,
        required: true
    },
    {
        label: 'Maximum Deals',
        name: 'maxDeals',
        id: 'tenantPlanMaxDeals',
        type: 'number',
        default: 0,
        required: true
    },
    {
        label: 'Maximum Accounts',
        name: 'maxAccounts',
        id: 'tenantPlanMaxAccounts',
        type: 'number',
        default: 0,
        required: true
    },
    {
        label: 'Maximum Contacts',
        name: 'maxContacts',
        id: 'tenantPlanMaxContacts',
        type: 'number',
        default: 0,
        required: true
    },
    {
        label: 'Is Active',
        name: 'isActive',
        id: 'tenantPlanIsActive',
        type: 'checkbox',
        default: true
    },
    {
        label: 'Price per month (R)',
        name: 'price',
        id: 'tenantPlanPrice',
        type: 'number',
        default: 0,
        required: true
    },
];

export default fields;