import { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { isLocalhost, getProtocol } from '../utils/isLocalhost';
import { useCookies } from 'react-cookie';

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, logout } = useContext(AuthContext);
  const [cookies,] = useCookies([
    'subdomain',
  ]);

  const subdomain = cookies.subdomain;
  const appSubdomain = window.location.hostname.split('.')[0];

  if(subdomain && (subdomain !== appSubdomain)){
    logout();
  }

  if (!isAuthenticated()) {
    // Check if the app is running on localhost or 127.0.0.1
    const isLocal = isLocalhost();
    const protocol = getProtocol();
    
    // Retrieve the port number, if it exists
    const port = window.location.port ? `:${window.location.port}` : '';
    
    // Get the production domain from the environment variables
    const prodDomain = process.env.REACT_APP_DOMAIN;
    const loginSubDomain = process.env.REACT_APP_LOGIN_SUB_DOMAIN;
    
    // Construct the URL for local development, including the port number
    const localURL = `${protocol}://${loginSubDomain}.localhost.com${port}`;
    
    // Construct the URL for the production environment
    const prodURL = `${protocol}://${loginSubDomain}.${prodDomain}`;

    // Redirect to the appropriate URL based on the environment
    window.location.href = isLocal ? localURL : prodURL;

    // Return null to prevent rendering any component before redirection
    return null;
  }

  // If authenticated, render the protected children components
  return children;
};

export default ProtectedRoute;
