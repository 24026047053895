import './App.css';
import PageRouter from './pageRouter/PageRouter';
import { QueryClient, QueryClientProvider } from 'react-query';
import { HelmetProvider } from 'react-helmet-async';
import { AuthProvider } from './contexts/AuthContext';

const queryClient = new QueryClient();
const helmetContext = {};

function App() {
  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <HelmetProvider context={helmetContext}>
          <div className='App'>
            <PageRouter />
          </div>
        </HelmetProvider>
      </QueryClientProvider>
    </AuthProvider>
  );
}

export default App;
