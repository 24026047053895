import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getRecords } from '../../../api/crud';
import details from '../../../objectManager/tenantPlan/objectDetails';
import Header from '../../../layouts/header/Header';
import RecordForm from './recordForm/RecordForm';
import ListViewTable from '../../../components/listViewTable/ListViewTable';
import DetailsPage from '../../../components/detailsPage/DetailsPage';
import SEO from '../../../components/seo/SEO';
import CustomLink from '../../../components/customLink/CustomLink';

const TenantPlans = ({ showDetailsPage }) => {
  const { listId } = useParams();
  const { data: records, refetch, isLoading} = useQuery({
    queryFn: () => getRecords(details.apiNamePlural, listId ? listViews.find(list => list._id === listId) : null),
    queryKey: [details.apiNamePlural, listId]
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name', // Accessor for searching
        id: 'name',
        header: 'Name',
        Cell: ({ row }) => (
          <CustomLink to={`/tenant-plan/${row.original._id}`} text={row.original.name} />
        ),
      },
      {
        accessorKey: 'recordType',
        header: 'Record Type'
      },
      {
        accessorFn: (row) => `${row.maxUsers} users`, //accessorFn used to join multiple data into a single cell
        id: 'maxUsers',
        accessorKey: 'maxUsers',
        header: 'Maximum Users'
      },
      {
        accessorFn: (row) => `${row.maxLeads} leads`, //accessorFn used to join multiple data into a single cell
        id: 'maxLeads',
        header: 'Maximum Leads'
      },
      {
        accessorFn: (row) => `${row.maxLeads} deals`, //accessorFn used to join multiple data into a single cell
        id: 'maxDeals',
        header: 'Maximum Deals'
      },
    ],
  []
  );

  const importColumns = useMemo(
    () => [
      {
        accessorKey: 'name', 
        header: 'Name',
      },
      {
        accessorKey: 'recordType',
        header: 'Record Type'
      },
      {
        accessorKey: 'maxUsers',
        header: 'Maximum Users'
      },
      {
        accessorKey: 'maxLeads',
        header: 'Maximum Leads'
      },
      {
        accessorKey: 'maxDeals',
        header: 'Maximum Deals'
      },
    ],
  []
  );

  const listViews = [
    {
      _id: 'fdebffc6-2c48-4398-9114-047f9f943132',
      label: 'All Tenant Plans',
      filterLogic: null,
      filters: []
    },
    {
      _id: '586604ee-9bda-4ad3-821d-d65f11f7438c',
      label: 'Active Tenant Plans',
      filterLogic: null,
      filters: [
        {
          field: 'isActive',
          operator: 'equals',
          value: true
        }
      ]
    },
    {
      _id: '052f8054-81ec-408c-91fa-12693388b534',
      label: 'Inactive Tenant Plans',
      filterLogic: null,
      filters: [
        {
          field: 'isActive',
          operator: 'equals',
          value: false
        }
      ]
    },
    {
      _id: '447b1fc4-11b0-4c48-bd21-7414bd1933c8',
      label: 'Standard Tenant Plans',
      filterLogic: null,
      filters: [
        {
          field: 'recordType',
          operator: 'equals',
          value: 'Standard'
        }
      ]
    },
    {
        _id: '76bef10a-2a30-4bdb-9c73-ed3daf97810f',
        label: 'Custom Tenant Plans',
        filterLogic: null,
        filters: [
          {
            field: 'recordType',
            operator: 'equals',
            value: 'Custom'
          }
        ]
      }
  ];

  return (
    <>
      <SEO
        title={`${details.pluralLabel} | Salesdam`}
        description={details.description}
      />
      <Header
        objectType={details.apiName}
        infoText={details.description}
        type='listview'
        listViews={listViews}
      />
      {showDetailsPage && (
        <DetailsPage
          objectType={details.apiName}
          listViewLink={`/${details.apiNamePlural}`}
          refetch={refetch}
          RecordForm={<RecordForm />}
        />
      )}
      <div className="list-view">
        <ListViewTable
          enableRowActions={true}
          enableRowSelection={true}
          isLoading={isLoading}
          objectType={details.apiName}
          objectTypePlural={details.apiNamePlural}
          refetch={refetch}
          listViewQueryKey={details.apiNamePlural}
          columns={columns}
          importColumns={importColumns}
          data={records}
          RecordForm={<RecordForm />}
        />
      </div>
    </>
  );
};

export default TenantPlans;