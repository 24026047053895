import isAddressField from './isAddressField';

const useEffectformValues = (isEditing, record, fields, userId) => {
  const defaultValues = {};

  fields?.forEach(field => {
    if (!field?.name || isAddressField(field)) {
      // Skip if field or field.name is not defined
      return;
    }
  
    const fieldName = field?.name;
    let fieldValue;
  
    if (isEditing) {
      if (field?.type === 'lookup') {
        fieldValue = record?.[fieldName]?._id; // Access the ID if it's a lookup
      } else {
        fieldValue = record?.[fieldName]; // Regular field
      }
    } else {
      fieldValue = field?.lookupTo === 'users' ? userId : field?.default; // Default value when not editing
    }
  
    // Assign the value to defaultValues
    defaultValues[fieldName] = fieldValue;
  });

  return defaultValues;
}

export default useEffectformValues;