const fields = [
    {
        label: 'Record Type',
        name: 'recordType',
        id: 'accountRecordType',
        type: 'picklist',
        options: ['Business Account', 'Person Account'],
        default: '',
        required: true
    },
    {
        label: 'Type',
        name: 'type',
        id: 'accountType',
        type: 'picklist',
        options: ['Analyst', 'Competitor', 'Customer', 'Integrator', 'Investor', 'Partner', 'Press', 'Prospect', 'Reseller', 'Other'],
        default: '',
        required: false
    },
    {
        label: 'Account Owner',
        name: 'owner',
        id: 'accountOwner',
        type: 'lookup',
        lookupTo: 'users',
        default: null,
        required: false
    },
    {
        label: 'Parent Account',
        name: 'parentAccount',
        id: 'accountParentAccount',
        type: 'lookup',
        lookupTo: 'accounts',
        default: null,
        required: false
    },
    {
        label: 'Account Name',
        name: 'name',
        id: 'accountName',
        type: 'text',
        default: '',
        required: true
    },
    {
        label: 'Account Number',
        name: 'accountNumber',
        id: 'accountNumber',
        type: 'text',
        default: '',
        required: true
    },
    {
        label: 'Account Currency',
        name: 'currency',
        id: 'accountCurrency',
        type: 'picklist',
        options: ['EUR - Euro', 'ZAR - South African Rand', 'USD - US Dollar'],
        default: 'ZAR - South African Rand',
        required: false
    },
    {
        label: 'Site',
        name: 'site',
        id: 'accountSite',
        type: 'text',
        default: '',
    },
    {
        label: 'Source',
        name: 'source',
        id: 'accountSource',
        type: 'text',
        default: '',
    },
    {
        label: 'First Name',
        name: 'firstName',
        id: 'accountFirstName',
        type: 'text',
        default: '',
        required: true
    },
    {
        label: 'Last Name',
        name: 'lastName',
        id: 'accountLastName',
        type: 'text',
        default: '',
        required: true
    },
    {
        label: 'Revenue',
        name: 'revenue',
        id: 'accountRevenue',
        type: 'number',
        default: 0,
    },
    {
        label: 'Title',
        name: 'salutation',
        id: 'salutationSalutation',
        type: 'picklist',
        options: ['Mr.', 'Ms.', 'Mrs.', 'Dr.', 'Prof.'],
        default: '',
        required: false
    },
    {
        label: 'Annual Revenue',
        name: 'annualRevenue',
        id: 'accountAnnualRevenue',
        type: 'number',
        default: 0,
    },
    {
        label: 'Employees',
        name: 'employees',
        id: 'accountEmployees',
        type: 'text',
        default: '',
    },
    {
        label: 'Industry',
        name: 'industry',
        id: 'accountIndustry',
        type: 'picklist',
        options: ['Agriculture', 'Apparel', 'Banking', 'Biotechnology', 'Chemicals', 'Communications', 'Construction', 'Consulting', 'Education', 'Electronics', 'Energy', 'Engineering', 'Entertainment', 'Environmental', 'Finance', 'Food & Beverage', 'Government', 'Healthcare', 'Hospitality', 'Insurance', 'Machinery', 'Manufacturing', 'Media', 'Mining', 'Not For Profit', 'Recreation', 'Retail', 'Shipping', 'Technology', 'Telecommunications', 'Transportation', 'Utilities', 'Other'],
        default: '',
        required: false
    },
    {
        label: 'Type',
        name: 'type',
        id: 'accountType',
        type: 'text',
        default: '',
    },
    {
        label: 'Description',
        name: 'description',
        id: 'accountDescription',
        type: 'textarea',
        default: '',
    },
    {
        label: 'Rating',
        name: 'rating',
        id: 'accountRating',
        type: 'text',
        default: '',
    },
    {
        label: 'Website',
        name: 'website',
        id: 'accountWebsite',
        type: 'text',
        default: '',
    },
    {
        label: 'Phone',
        name: 'phone',
        id: 'accountPhone',
        type: 'text',
        default: '',
    },
    {
        label: 'Email',
        name: 'email',
        id: 'accountEmail',
        type: 'text',
        default: '',
        required: true
    },
    {
        label: 'Billing Street',
        name: 'billingAddress.street',
        id: 'accountBillingStreet',
        type: 'text',
        default: '',
        required: false
    },
    {
        label: 'Billing City',
        name: 'billingAddress.city',
        id: 'accountBillingCity',
        type: 'text',
        default: '',
    },
    {
        label: 'Billing Province',
        name: 'billingAddress.province',
        id: 'accountBillingProvince',
        type: 'text',
        default: '',
    },
    {
        label: 'Billing Zip',
        name: 'billingAddress.zip',
        id: 'accountBillingZip',
        type: 'text',
        default: '',
    },
    {
        label: 'Billing Country',
        name: 'billingAddress.country',
        id: 'accountBillingCountry',
        type: 'text',
        default: '',
        required: false
    },
    {
        label: 'Shipping Street',
        name: 'shippingAddress.street',
        id: 'accountShippingStreet',
        type: 'text',
        default: '',
    },
    {
        label: 'Shipping City',
        name: 'shippingAddress.city',
        id: 'accountShippingCity',
        type: 'text',
        default: '',
    },
    {
        label: 'Shipping Province',
        name: 'shippingAddress.province',
        id: 'accountShippingProvince',
        type: 'text',
        default: '',
    },
    {
        label: 'Shipping Zip',
        name: 'shippingAddress.zip',
        id: 'accountShippingZip',
        type: 'text',
        default: '',
    },
    {
        label: 'Shipping Country',
        name: 'shippingAddress.country',
        id: 'accountShippingCountry',
        type: 'text',
        default: '',
    }
]

export default fields;