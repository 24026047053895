import React from 'react';
import styles from './css/Table.module.css';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import Skeleton from '@mui/material/Skeleton';

const Table = ({ caption, data, columns, isLoading }) => {
  /**
   * This function renders a cell in the table. It uses different approaches
   * depending on whether data is being loaded or not. If `isLoading` is true,
   * it returns a skeleton. Otherwise, it returns data based on column configuration.
   */
  const renderCell = (row, column) => {
    if (isLoading) {
      // When loading, return a skeleton element to simulate text content.
      return <Skeleton variant="text" width="100%" height={20} />;
    }

    // Use `accessorFn` to get custom content from the row data.
    if (column?.accessorFn) {
      return column?.accessorFn(row);
    }

    // Use `accessorKey` to access nested property in the row data.
    if (column?.accessorKey) {
      return getNestedValue(row, column?.accessorKey);
    }

    return null; // If no `accessorFn` or `accessorKey`, return null.
  };

  /**
   * Utility function to get nested property values from an object.
   * It traverses the object based on a dot-separated path.
   */
  const getNestedValue = (obj, path) => {
    return path?.split('.')?.reduce((acc, part) => (acc ? acc[part] : undefined), obj);
  };

  /**
   * This function decides which content to render based on the `isLoading` state.
   * If `isLoading` is true, it displays skeleton rows. Otherwise, it renders the actual data.
   */
  const renderTableContent = () => {
    if (isLoading) {
      return (
        <tbody>
          {/* Display multiple skeleton rows to simulate table content during loading */}
          {Array.from({ length: 5 }, (_, rowIndex) => (
            <tr key={`skeleton-${rowIndex}`}>
              {columns?.map((column, colIndex) => (
                <td key={`skeleton-${rowIndex}-${colIndex}`}>
                  {column.isClickable && <Skeleton variant="circular" width={20} height={20} />} {/* Circular skeleton for clickable icons */}
                  {renderCell(null, column)} {/* Skeleton for text content */}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      );
    }

    // If not loading, render the actual table content with data.
    return (
      <tbody>
        {data?.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {columns?.map((column, colIndex) => (
              <td key={column?.id || colIndex} className={column.isClickable ? styles['clickable-column'] : ''}>
                {column.isClickable && <OpenInNewOutlinedIcon fontSize="small" />} {/* Open icon for clickable columns */}
                {renderCell(row, column)} {/* Render cell content */}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    );
  };

  return (
    <div className={styles['table-container']}>
      <h4 className={styles['caption']}>{caption}</h4> {/* Table caption */}
      <table className={styles['table']}>
        <thead className={styles['table-header']}>
          <tr>
            {/* Render table headers from the columns array */}
            {columns?.map((column, index) => (
              <th key={column?.id || index} style={{ width: column?.size || 'auto' }}>
                {column?.header}
              </th>
            ))}
          </tr>
        </thead>
        {/* Render the table content based on loading state */}
        {renderTableContent()}
      </table>
    </div>
  );
};

export default Table;