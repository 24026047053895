import styles from './css/Field.module.css';
import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { getRecords } from '../../api/crud';
import {
  lookupSearchFilter,
  lookupFieldDisplayValue,
} from '../../utils/lookupFunctions';
import SearchIcon from '@mui/icons-material/Search';
import { Popover } from '@mui/material';

const LookupField = ({
  id,
  name,
  disabled,
  lookupTo,
  onChange,
  placeholder,
  lookupRecordId,
}) => {
  const { data: options, isLoading } = useQuery({
    queryFn: () => getRecords(lookupTo),
    queryKey: [`${lookupTo}_lookup_${name}`],
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    // Set the default value based on the value on the record
    setSearchTerm(
      lookupRecordId
        ? lookupFieldDisplayValue(lookupTo, getDefaultValue(lookupRecordId))
        : ''
    );

    setFilteredOptions(options);
  }, [options, lookupRecordId]);

  const open = Boolean(anchorEl);
  const popoverId = open ? id : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleInputChange = (event) => {
    const { value } = event.target;

    setSearchTerm(value);

    if (!value) {
      // Creating a custom event object for the onChange event
      const customEvent = {
        target: {
          name: name,
          type: 'lookup',
          value: null,
        },
      };
      onChange(customEvent);
    }

    const filtered = lookupSearchFilter(lookupTo, options, value);
    setFilteredOptions(filtered);
  };

  const handleSelectOption = (option) => {
    // Creating a custom event object for the onChange event
    const event = {
      target: {
        name: name,
        type: 'lookup',
        value: option?._id ? option?._id : null,
      },
    };
    onChange(event);

    setSearchTerm(lookupFieldDisplayValue(lookupTo, option)); //Set field value based on the selected option and lookup object
    handleClose();
  };

  const getDefaultValue = (lookupRecordId) => {
    const defaultValue = options?.find(
      (option) => option?._id === lookupRecordId
    );
    return defaultValue;
  };

  const popoverStyles = {
    zIndex: 5000,
  };

  return (
    <div className={styles['lookup-field-container']}>
      <div className={styles['input-icon-container']}>
        <input
          disabled={disabled}
          id={id}
          type="search"
          placeholder={placeholder}
          onClick={(event) => handleClick(event)}
          value={searchTerm}
          onChange={handleInputChange}
          className={styles['lookup-field-input']}
          readOnly={open} // Set readOnly when the popover is open
        />
        {!searchTerm && (
          <SearchIcon fontSize="small" className={styles['search-icon']} />
        )}
      </div>
      <Popover
        style={popoverStyles}
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            sx: {
              width: anchorEl?.clientWidth || 0, // Match the width of the anchor elemen
            },
          },
        }}
      >
        <div className={styles['popover-container']}>
          <input
            className={styles['lookup-search-filter']}
            id="lookup-search"
            type="search"
            placeholder={placeholder}
            value={searchTerm}
            onChange={handleInputChange}
          />
          <ul className={styles['lookup-field-dropdown']}>
            {filteredOptions?.slice(0, 5).map((option) => (
              <li
                key={option?._id}
                onClick={() => handleSelectOption(option)}
                className={styles['lookup-field-option']}
              >
                {lookupFieldDisplayValue(lookupTo, option)}
              </li>
            ))}
          </ul>
        </div>
      </Popover>
    </div>
  );
};

export default LookupField;
