import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useParams, useLocation } from 'react-router-dom';
import { getRecords, updateRecord } from '../../../api/crud';
import details from '../../../objectManager/order/objectDetails';
import Header from '../../../layouts/header/Header';
import RecordForm from './recordForm/RecordForm';
import ListViewTable from '../../../components/listViewTable/ListViewTable';
import KanbanBoard from '../../../components/kanbanBoard/KanbanBoard';
import DetailsPage from '../../../components/detailsPage/DetailsPage';
import { formatDate } from '../../../utils/dateUtils';
import SEO from '../../../components/seo/SEO';
import { toast } from 'react-toastify'; // ToastContainer in PageLayout component
import CustomLink from '../../../components/customLink/CustomLink';
import formatToRand from '../../../utils/formatToRands';

const Orders = ({ showDetailsPage }) => {
  const location = useLocation();
  const { listId } = useParams();
  const { data: records, refetch, isLoading} = useQuery({
    queryFn: () => getRecords(details.apiNamePlural, listId ? listViews.find(list => list._id === listId) : {}),
    queryKey: [details.apiNamePlural, listId]
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name', // Accessor for searching
        id: 'name',
        header: 'Name',
        Cell: ({ row }) => (
          <CustomLink to={`/order/${row.original._id}`} text={row.original.name} />
        ),
      },
      {
        accessorKey: 'client',
        header: 'Client'
      },
      {
        accessorKey: 'orderNumber',
        header: 'Order Number'
      },
      {
        accessorFn: (row) => formatToRand(row.orderValue || 0),
        id: 'orderValue',
        header: 'Order Value'
      },
      {
        accessorKey: 'invoiceNumber',
        header: 'Invoice Number'
      },
      {
        accessorFn: (row) => formatDate(row.deliveryDate),
        id: 'deliveryDate',
        header: 'Delivery Date'
      },
      {
        accessorFn: (row) => row.projectManager ? `${row.projectManager.firstName} ${row.projectManager.lastName}` : '', //accessorFn used to join multiple data into a single cell
        id: 'projectManager', //id is still required when using accessorFn instead of accessorKey
        header: 'Project Manager'
      },
      {
        accessorKey: 'endUser', 
        header: 'End User'
      },
    ],
  []
  );

  const importColumns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Name'
      },
      {
        accessorKey: 'client',
        header: 'Client'
      },
      {
        accessorKey: 'orderNumber',
        header: 'Order Number'
      },
      {
        accessorKey: 'projectCost',
        header: 'Order Value'
      },
      {
        accessorFn: (row) => formatDate(row.deliveryDate),
        id: 'deliveryDate',
        header: 'Delivery Date'
      },
    ],
  []
  );

  // List of available list views
  // operator: equals, notEqual, lessThan, greaterThan, lessOrEqual, greaterOrEqual, contains, notContain, startsWith, includes, excludes
  // filterLogic: '1 AND 2', and or, '1 OR 2', etc
  const listViews = [
    {
      _id: '102a69bd-39b4-4ab0-90d3-96609ef5153n',
      label: 'All Orders',
      filterLogic: null,
      filters: [
        {
          field: 'isConverted',
          operator: 'notEqual',
          value: true
        }
      ]
    }
  ];

  const updateOrderstatus = (leadId, destinationColumn) => {
    const updatePromise = updateRecord(details.apiName, leadId, { status: destinationColumn });
  
    toast.promise(
      updatePromise,
      {
        pending: `Updating ${details.label} status...`,
        success: 'Successfully updated record!',
        error: 'Error updating record',
      }
    );
  
    return updatePromise; // Return the promise to maintain consistency
  };

  return (
    <>
      <SEO
        title={`${details.pluralLabel} | Salesdam`}
        description={details.description}
      />
      <Header
        objectType={details.apiName}
        infoText={details.description}
        type='listview'
        listViews={listViews}
      />
      {showDetailsPage && (
        <DetailsPage
          objectType={details.apiName}
          listViewLink={`/${details.apiNamePlural}`}
          refetch={refetch}
          RecordForm={<RecordForm />}
        />
      )}
      <div className='list-view'>
        {location.pathname === `/${details.apiNamePlural}/kanban` ? (
          <KanbanBoard
            items={records}
            field="status"
            //values={statusValues}
            onDragEnd={(result) => {
              updateOrderstatus(result.draggableId, result.destination.droppableId);
            }}
            objectType={details.apiName}
            objectTypePlural={details.apiNamePlural}
            navigateTo={`/${details.apiNamePlural}`}
          />
          ) : (
          <ListViewTable
            enableRowActions={true}
            enableRowSelection={true}
            isLoading={isLoading}
            objectType={details.apiName}
            objectTypePlural={details.apiNamePlural}
            refetch={refetch}
            listViewQueryKey={details.apiNamePlural}
            columns={columns}
            importColumns={importColumns}
            data={records}
            RecordForm={<RecordForm />}
          />
        )}
      </div>
    </>
  );
};

export default Orders;