const fields = [
    {
        label: 'Record Type',
        name: 'recordType',
        id: 'caseRecordType',
        type: 'picklist',
        options: ['Case'],
        default: '',
        required: true
    },
    {
        label: 'Case Owner',
        name: 'owner',
        id: 'caseOwner',
        type: 'lookup',
        lookupTo: 'users',
        default: null,
    },
    {
        label: 'Case Currency',
        name: 'currency',
        id: 'caseCurrency',
        type: 'picklist',
        options: ['EUR - Euro', 'ZAR - South African Rand'],
        default: 'ZAR - South African Rand',
        required: true
    },
    {
        label: 'Subject',
        name: 'subject',
        id: 'caseSubject',
        type: 'text',
        default: '',
        required: false
    },
    {
        label: 'Description',
        name: 'description',
        id: 'caseDescription',
        type: 'textarea',
        default: '',
        required: false
    },
    {
        label: 'Internal Comments',
        name: 'internalComments',
        id: 'caseInternalComments',
        type: 'textarea',
        default: '',
        required: false
    },
    {
        label: 'Web Email',
        name: 'webEmail',
        id: 'caseWebEmail',
        type: 'text',
        default: '',
        required: false
    },
    {
        label: 'Web Name',
        name: 'webName',
        id: 'caseWebName',
        type: 'text',
        default: '',
        required: false
    },
    {
        label: 'Web Company',
        name: 'webCompany',
        id: 'caseWebCompany',
        type: 'text',
        default: '',
        required: false
    },
    {
        label: 'Web Phone',
        name: 'webPhone',
        id: 'caseWebPhone',
        type: 'text',
        default: '',
        required: false
    },
    {
        label: 'Type',
        name: 'type',
        id: 'caseType',
        type: 'picklist',
        options: ['Problem', 'Feature Request', 'Question'],
        default: '',
        required: false
    },
    {
        label: 'Case Reason',
        name: 'caseReason',
        id: 'caseCaseReason',
        type: 'picklist',
        options: ["User didn't attend training", 'Complex functionality', 'Existing problem', 'Instructions not clear', 'New problem'],
        default: '',
        required: false
    },
    {
        label: 'Status',
        name: 'status',
        id: 'caseStatus',
        type: 'picklist',
        options: ['New', 'Escalated', 'On Hold'],
        default: 'New',
        required: true
    },
    {
        label: 'Case Origin',
        name: 'origin',
        id: 'caseOrigin',
        type: 'picklist',
        options: ['Email', 'Phone', 'Web'],
        default: '',
        required: true
    },
    {
        label: 'Priority',
        name: 'priority',
        id: 'casePriority',
        type: 'picklist',
        options: ['Low', 'Medium', 'High'],
        default: 'Low',
        required: true
    },
    {
        label: 'Priority',
        name: 'priority',
        id: 'casePriority',
        type: 'picklist',
        options: ['Low', 'Medium', 'High'],
        default: 'Low',
        required: true
    },
    {
        label: 'Assigned To',
        name: 'assignedTo',
        id: 'caseAssignedTo',
        type: 'lookup',
        lookupTo: 'users',
        default: null,
    },
    {
        label: 'Account',
        name: 'account',
        id: 'caseAccount',
        type: 'lookup',
        lookupTo: 'accounts',
        default: null,
    },
    {
        label: 'Related Lead',
        name: 'lead',
        id: 'caseLead',
        type: 'lookup',
        lookupTo: 'leads',
        default: null,
    },
    {
        label: 'Contact',
        name: 'contact',
        id: 'caseContact',
        type: 'lookup',
        lookupTo: 'contacts',
        default: null,
    }
];

export default fields;