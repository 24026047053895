const fields = [
    {
        label: 'Record Type',
        name: 'recordType',
        id: 'leadRecordType',
        type: 'picklist',
        options: ['Web Lead', 'Email Lead'],
        default: '',
        required: false
    },
    {
        label: 'Is Converted',
        name: 'isConverted',
        id: 'leadIsConverted',
        type: 'checkbox',
        default: false
    },
    {
        label: 'Quote Validity Period',
        name: 'quoteValidity',
        id: 'leadQuoteValidity',
        type: 'number',
        default: 0,
    },
    {
        label: 'Closing Date',
        name: 'closingDate',
        id: 'leadClosingDate',
        type: 'datetime',
        default: '',
        required: true
    },
    {
        label: 'First Name',
        name: 'firstName',
        id: 'leadFirstName',
        type: 'text',
        default: '',
    },
    {
        label: 'Last Name',
        name: 'lastName',
        id: 'leadLastName',
        type: 'text',
        default: '',
    },
    {
        label: 'Email',
        name: 'email',
        id: 'leadEmail',
        type: 'email',
        default: '',
    },
    {
        label: 'End User',
        name: 'endUser',
        id: 'leadEndUser',
        type: 'text',
        default: '',
    },
    {
        label: 'Sales Rep',
        name: 'salesRep',
        id: 'leadSalesRep',
        type: 'lookup',
        lookupTo: 'users',
        default: null,
        required: true
    },
    {
        label: 'Phone',
        name: 'phone',
        id: 'leadPhone',
        type: 'text',
        default: '',
    },
    {
        label: 'Lead Currency',
        name: 'currency',
        id: 'leadCurrency',
        type: 'picklist',
        options: ['EUR - Euro', 'ZAR - South African Rand', 'USD - US Dollar'],
        default: 'ZAR - South African Rand',
    },
    {
        label: 'Client',
        name: 'client',
        id: 'client',
        type: 'text',
        default: '',
        required: true
    },
    {
        label: 'Website',
        name: 'website',
        id: 'leadWebsite',
        type: 'text',
        default: '',
        required: false
    },
    {
        label: 'Job Title',
        name: 'jobTitle',
        id: 'leadJobTitle',
        type: 'text',
        default: '',
        required: false
    },
    {
        label: 'Title',
        name: 'title',
        id: 'leadTitle',
        type: 'picklist',
        options: ['Mr.', 'Ms.', 'Mrs.', 'Dr.', 'Prof.'],
        default: '',
        required: false
    },
    {
        label: 'Street',
        name: 'address.street',
        id: 'leadStreet',
        type: 'text',
        default: '',
        required: false
    },
    {
        label: 'City',
        name: 'address.city',
        id: 'leadCity',
        type: 'text',
        default: '',
        required: false
    },
    {
        label: 'Province',
        name: 'address.province',
        id: 'leadProvince',
        type: 'text',
        default: '',
        required: false
    },
    {
        label: 'Zip',
        name: 'address.zip',
        id: 'leadZip',
        type: 'text',
        default: '',
        required: false
    },
    {
        label: 'Country',
        name: 'address.country',
        id: 'leadCountry',
        type: 'picklist',
        options: ['South Africa', 'Eswatini', 'Other'],
        default: 'South Africa',
    },
    {
        label: 'Industry',
        name: 'industry',
        id: 'leadIndustry',
        type: 'picklist',
        options: ['Agriculture', 'Apparel', 'Banking', 'Biotechnology', 'Chemicals', 'Communications', 'Construction', 'Consulting', 'Education', 'Electronics', 'Energy', 'Engineering', 'Entertainment', 'Environmental', 'Finance', 'Food & Beverage', 'Government', 'Healthcare', 'Hospitality', 'Insurance', 'Machinery', 'Manufacturing', 'Media', 'Mining', 'Not For Profit', 'Recreation', 'Retail', 'Shipping', 'Technology', 'Telecommunications', 'Transportation', 'Utilities', 'Other'],
        default: '',
    },
    {
        label: '',
        name: 'internalComments',
        id: 'leadInternalComments',
        type: 'textarea',
        default: '',
        required: false
    },
    {
        label: 'Rating',
        name: 'rating',
        id: 'leadRating',
        type: 'picklist',
        options: ['Hot', 'Warm', 'Cold'],
        default: 'Warm',
        required: false
    },
    {
        label: 'Lead Status',
        name: 'status',
        id: 'leadStatus',
        type: 'picklist',
        options: ['Open', 'Contacted', 'Unqualified', 'Qualified'],
        default: 'Open',
        required: false
    },
    {
        label: 'Approval Status',
        name: 'approvalStatus',
        id: 'leadApprovalStatus',
        type: 'picklist',
        options: ['Pending', 'Approved', 'Denied', 'Recalled'],
        default: '',
        required: false
    },
    {
        label: 'Lead Source',
        name: 'source',
        id: 'leadSource',
        type: 'picklist',
        options: ['Email', 'Web'],
        default: '',
        required: false
    },
    {
        label: 'Quote Submission Location',
        name: 'quoteSubmissionLocation',
        id: 'leadQuoteSubmissionLocation',
        type: 'textarea',
        default: '',
    },
    {
        label: 'Project Code',
        name: 'projectCode',
        id: 'leadProjectCode',
        type: 'text',
        default: '',
    },
    {
        label: 'RFQ Number',
        name: 'rfqNumber',
        id: 'leadRfqNumber',
        type: 'text',
        default: '',
    },
    {
        label: 'Product Type',
        name: 'productType',
        id: 'leadProductType',
        type: 'picklist',
        options: ['Generator', 'UPS Solar Backup Power', 'Mechanical Engeneering', 'Manufacturing', 'Transformers', 'Other'],
        default: '',
    },
    {
        label: 'CIDB Number',
        name: 'cidbNumber',
        id: 'leadCIDBNumber',
        type: 'text',
        default: '',
        required: false
    },
    {
        label: 'Technical Scope',
        name: 'technicalScope',
        id: 'leadTechnicalScope',
        type: 'textarea',
        default: '',
        required: true
    },
    {
        label: 'Compulsory Briefing?',
        name: 'compulsoryBriefing',
        id: 'leadCompulsoryBriefing',
        type: 'picklist',
        options: ['Yes', 'No'],
        default: '',
    },
    {
        label: 'Date/Time of Briefing',
        name: 'dateOfBriefing',
        id: 'leadDateOfBriefing',
        type: 'datetime',
        default: '',
        required: false
    },
    {
        label: 'Briefing Address',
        name: 'briefingAddress',
        id: 'leadBriefingAddress',
        type: 'textarea',
        default: '',
        required: false
    },
    {
        label: 'Created Date',
        name: 'createdAt',
        id: 'leadCreatedAt',
        type: 'output',
        required: false
    },
    {
        label: 'Last Updated Date',
        name: 'updatedAt',
        id: 'leadUpdatedAt',
        type: 'output',
        required: false
    },
    {
        label: 'Product Name',
        name: 'productName',
        id: 'leadProductName',
        type: 'text',
        default: '',
    },
    {
        label: 'Product Description',
        name: 'productDescription',
        id: 'leadProductDescription',
        type: 'textarea',
        default: '',
    }
];

export default fields;