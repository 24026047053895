import Header from '../../../layouts/header/Header';
import SEO from '../../../components/seo/SEO';
import DealsSummary from './charts/dealsSummary/DealsSummary';

const DealsDashboard = () => {
    const infoText = 'The Deals Dashboard provides a detailed view of all ongoing deals in your sales pipeline. With this dashboard, you can identify sales trends, and gain insights into your overall sales performance.';

    return (
        <>
            <SEO
                title='Deals Dashboard | Salesdam'
                description={infoText}
            />
            <Header
                headerText='Deals Dashboard'
                infoText={infoText}
            />
            <div className='dashboard-charts'>
                <DealsSummary />
            </div>
        </>
    );
};

export default DealsDashboard;