const fields = [
    {
        label: 'Order Owner',
        name: 'owner',
        id: 'orderOwner',
        type: 'lookup',
        lookupTo: 'users',
        default: null,
        required: false
    },
    {
        label: 'Order Name',
        name: 'name',
        id: 'orderOrderName',
        type: 'text',
        default: '',
        required: true
    },
    {
        label: 'Order Number',
        name: 'orderNumber',
        id: 'orderOrderNumber',
        type: 'text',
        default: '',
        required: true
    },
    {
        label: 'Delivery Date',
        name: 'deliveryDate',
        id: 'orderDeliveryDate',
        type: 'date',
        default: '',
    },
    {
        label: 'Order Date',
        name: 'orderDate',
        id: 'orderDate',
        type: 'date',
        default: '',
    },
    {
        label: 'Order Value (R)',
        name: 'orderValue',
        id: 'orderOrderValue',
        type: 'number',
        default: 0,
    },
    {
        label: 'Project Manager',
        name: 'projectManager',
        id: 'orderProjectManager',
        type: 'lookup',
        lookupTo: 'users',
        default: null,
    },
    {   label: 'Project Code',
        name: 'projectCode',
        id: 'orderProjectCode',
        type: 'text',
        default: '',
    },
    {
        label: 'Sales Rep',
        name: 'salesRep',
        id: 'orderSalesRep',
        type: 'lookup',
        lookupTo: 'users',
        default: null,
    },
    {
        label: 'End User',
        name: 'endUser',
        id: 'orderEndUser',
        type: 'text',
        default: '',
    },
    {
        label: 'Last Modified By',
        name: 'lastModifiedBy',
        id: 'orderLastModifiedBy',
        type: 'text',
        default: '',
        required: false
    },
    {
        label: 'Budget Cost (R)',
        name: 'budgetPurchaseAmount',
        id: 'orderBudgetPurchaseAmount',
        type: 'number',
        default: 0,
    },
    {
        label: 'Actual Cost (R)',
        name: 'actualPurchaseAmount',
        id: 'orderActualPurchaseAmount',
        type: 'number',
        default: 0,
    },
    {
        label: 'Delivery Note Number',
        name: 'deliveryNoteNumber',
        id: 'orderDeliveryNoteNumber',
        type: 'text',
        default: '',
    },
    {
        label: 'Invoice Number',
        name: 'invoiceNumber',
        id: 'orderInvoiceNumber',
        type: 'text',
        default: '',
    },
    {
        label: 'Invoice Date',
        name: 'invoiceDate',
        id: 'orderInvoiceDate',
        type: 'date',
        default: '',
        required: false
    },
    {
        label: 'Payment Terms',
        name: 'paymentTerms',
        id: 'orderPaymentTerms',
        type: 'number',
        default: '',
        required: true
    },
    {
        label: 'Payment Date',
        name: 'paymentDate',
        id: 'orderPaymentDate',
        type: 'output',
        default: '',
    },
    {
        label: 'Client',
        name: 'client',
        id: 'orderClient',
        type: 'text',
        default: '',
        required: true
    },
    {
        label: 'Product Name',
        name: 'productName',
        id: 'orderProductNames',
        type: 'text',
        default: '',
    },
    {
        label: 'Commission',
        name: 'commission',
        id: 'orderCommission',
        type: 'text',
        default: '',
        required: false
    },
    {
        label: 'Product Name',
        name: 'productName',
        id: 'orderProductNames',
        type: 'text',
        default: '',
    } 
]

export default fields;