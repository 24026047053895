/**
 * Converts an object with key-value pairs into an array of objects with `label` and `value` properties.
 * @param {Object} obj - The input object.
 * @returns {Array} - An array of objects with `value` and `label`.
 */
const convertObjectToArray= (obj) => {
  if(!obj) {
    return [];
  }

  return Object?.entries(obj)?.map(([key, value]) => ({
    label: key,
    value: value
  }));
};

export default convertObjectToArray;
  