import { useState, useMemo } from 'react';
import { useQuery } from 'react-query';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { getRecords } from '../../api/crud';
import Header from '../../layouts/header/Header';
import SEO from '../../components/seo/SEO';
import ListViewTable from '../../components/listViewTable/ListViewTable';
import details from '../../objectManager/tender/objectDetails';
import CustomLink from '../../components/customLink/CustomLink';
import TenderDialog from './TenderDialog';
import { formatDateTime } from '../../utils/dateUtils';

const Tenders = () => {
  const { data, isLoading } = useQuery({
    queryFn: () => getRecords('tenders', {}),
    queryKey: ['find-tenders'],
  });

  const tenders = data?.map((tender) => ({
    tenderTitle: tender.tender.description,
    tenderNumber: tender.tender.title,
    client: tender.buyer.name,
    category: tender.tender.mainProcurementCategory,
    closingDate: tender.date,
  }));

  const wordWrapStyles = {
    whiteSpace: 'normal',
    wordWrap: 'break-word',
  };

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedTender, setSelectedTender] = useState(null); // State to store selected tender

  const columns = useMemo(
    () => [
      {
        accessorKey: 'tenderTitle',
        id: 'tenderTitle',
        header: 'Tender Title',
        Cell: ({ row }) => (
          <div style={wordWrapStyles}>
            <CustomLink
              // to=""
              onClick={() => handleRowClick(row)}
              text={row?.original?.tenderTitle}
              classes="truncate-ellipsis-3-lines"
            />
          </div>
        ),
        size: 460,
      },
      {
        accessorKey: 'client',
        header: 'Client',
      },
      {
        accessorKey: 'category',
        header: 'Category',
      },
      {
        accessorFn: (row) => formatDateTime(row.closingDate),
        id: 'closingDate',
        header: 'Closing Date',
      },
    ],
    []
  );

  const handleRowClick = (row) => {
    setSelectedTender(row?.original);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedTender(null); // Clear selected tender
  };

  return (
    <>
      <SEO
        title={`${details.apiNamePlural} | Salesdam`}
        description={details.description}
      />
      <Header headerText="Tenders" infoText={details.description} />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '5px',
          margin: '7px 0',
        }}
      >
        <InfoOutlinedIcon style={{ fontSize: 20 }} />
        <small>Tenders filtered by Admin</small>
      </div>
      <div className="list-view">
        <ListViewTable
          hideNewButton={true}
          hideMore={true}
          hideRefresh={true}
          hideSettings={true}
          enableRowActions={false}
          enableRowSelection={false}
          isLoading={isLoading}
          objectType={details.apiName}
          objectTypePlural={details.apiNamePlural}
          listViewQueryKey={details.apiNamePlural}
          columns={columns}
          data={tenders || []}
        />
      </div>
      {openDialog && (
        <TenderDialog
          open={openDialog}
          handleClose={handleCloseDialog}
          tender={selectedTender}
        />
      )}
    </>
  );
};

export default Tenders;
