import styles from './css/DetailsPageModal.module.css';
import { useQuery } from 'react-query';
import { Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {BeatLoader } from 'react-spinners';
import { getUserPermissions } from '../../../api/authentication';
import getObjectPluralName from '../../../utils/getObjectPluralName';
import getObjectDetailsByName from '../../../utils/getObjectDetailsByName';

const CustomLoader = ({loading}) => {
  return (
    <BeatLoader
      color='var(--primary-color)'
      loading={loading}
      // cssOverride={override}
      size={10}
      aria-label='Loading Spinner'
      data-testid='loader'
    />
  );
}

const DetailsPageModal = ({ isLoading, record, objectType, isOpen, onClose, modalTitle, modalContent, tabs, handleConvert, handleAddUser, handleCancelEdit, fieldsDisabled, setFieldsDisabled }) => {
  const { data: role, isLoading: isPermissionLoading } = useQuery({
    queryFn: () => getUserPermissions(),
    queryKey: ['logged-in-user-permissions']
  });

  const permissions = role?.permissions;
  const objectTypePlural = getObjectPluralName(objectType);
  const objectPermissions = permissions[objectTypePlural];

  const allObjectsOnPermission = Object.keys(permissions); // get all the objects with permissions defined

  const getObjectDetails = getObjectDetailsByName(objectType);
  const getMongooseModelCollectionName = getObjectDetails?.mongooseModelCollectionName;
  
  // ensures canEdit default to true if mongoose model/object is not part of allObjectsOnPermission i.e. it has no permissions defined
  const canEdit = allObjectsOnPermission?.includes(getMongooseModelCollectionName) ? objectPermissions?.edit : true;

  return (
    <>
      <div className={styles['overlay']} onClick={onClose}></div>
      <dialog className={styles['details-page-modal']} open={isOpen}>
        <header className={styles['details-page-modal-header']}>
          <div className={styles['title-and-tabs-container']}>
            <p>{isLoading || isPermissionLoading ? <CustomLoader loading={isLoading} /> :  modalTitle}</p>
            <ul className={styles['details-page-tabs']}>
              {tabs?.map(tab => (
                <li
                  key={tab?.title}>
                  {tab?.title}
                </li>
              ))}
            </ul>
          </div>
          <div className={styles['quick-actions-and-close-icon-container']}>
            {isLoading || isPermissionLoading ? <CustomLoader loading={isLoading || isPermissionLoading} /> : (
              <>
                {(fieldsDisabled && canEdit) && (
                  <Button
                    onClick={() => {setFieldsDisabled(!fieldsDisabled)}}
                    style={{color: 'var(--primary-color)'}}
                    variant="text"
                    size='small'>
                    EDIT
                  </Button>
                )}
                {objectType === 'tenant' && (
                  <Button
                    onClick={handleAddUser}
                    style={{border: '1px solid var(--primary-color)',color: 'var(--primary-color)'}}
                    variant="outlined"
                    size='small'>
                    ADD USER
                  </Button>
                )}
                {((objectType === 'lead' && record?.status !== 'Unqualified' && record?.status !== 'Open' && record?.status !== 'Contacted') || 
                  (objectType === 'deal' && record?.stage === 'Closed Won') ) 
                  && (
                  <Button
                    onClick={handleConvert}
                    style={{border: '1px solid var(--primary-color)',color: 'var(--primary-color)'}}
                    variant="outlined"
                    size='small'>
                    Convert
                  </Button>
                )}
                {!fieldsDisabled && (
                  <Button
                    form={`${objectType}-details-page-form`}
                    type="submit"
                    style={{backgroundColor: 'var(--primary-color)'}}
                    variant="contained"
                    size='small'>
                    SAVE
                  </Button>
                )}
                {!fieldsDisabled && (
                  <Button
                    onClick={handleCancelEdit}
                    style={{border: '1px solid var(--primary-color)', color: 'var(--primary-color)'}}
                    variant="outlined"
                    size='small'>
                    Cancel
                  </Button>
                )}
              </>
            )}
            {/* <Button
              onClick={handleDelete}
              style={{background: 'var(--delete-button-color)'}}
              variant="contained"
              size='small'>
              Delete
            </Button> */}
            <IconButton aria-label="close modal" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </header>
        <div className={styles['modal-content']}>
          {modalContent}
        </div>
      </dialog>
    </>
  );
};

export default DetailsPageModal;
