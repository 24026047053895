import styles from './css/Header.module.css';
import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckIcon from '@mui/icons-material/Check';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

/**
 * possible values for type: listview, and, normal
 * if type is not specified, it displays the normal header
 */
const Header = ({objectType, headerText, infoText, type, listViews}) => {
    const navigate = useNavigate();
    const { listId } = useParams();
    const [ currentListView, setCurrentListView ] = useState(type === 'listview' ? (listId ? listViews.find(list => list._id === listId)?.label : listViews[0]?.label) : '');

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'popup-list' : undefined;

    const handleListViewClick = (listView) => {
        setCurrentListView(listView.label);
        navigate(`/${objectType}/list/${listView._id}`);
        handleClose();
    };

    return (
        <div className={styles['tab-header']}>
            <div
                className={type === 'listview' ? `${styles['selected-listview']} prevent-select` : ''}
                onClick={type === 'listview' ? handleClick : null}>
                <p className={styles['header-text']}>{type === 'listview' ? currentListView : headerText}</p>
                {type === 'listview' && (
                    <ArrowDropDownIcon fontSize='large' />
                )}
            </div>
            <p className={styles['info-text']}>{infoText}</p>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <List>
                    {listViews?.map((view, index) => (
                        <ListItemButton key={index} onClick={() => handleListViewClick(view)}>
                            <ListItemIcon>
                                {currentListView === view.label ? <CheckIcon /> : null}
                            </ListItemIcon>
                            <ListItemText primary={view?.label} />
                        </ListItemButton>
                    ))}
                </List>
            </Popover>
        </div>
    );
};

export default Header;