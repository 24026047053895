import styles from './css/MultiSelectPicklist.module.css';
import { useState, useRef, useEffect } from 'react';

const MultiSelectPicklist = ({
  id,
  name,
  label,
  disabled,
  placeholder,
  options,
  value,
  onChange,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const [dropdownStyle, setDropdownStyle] = useState({
    top: 0,
    left: 0,
    width: 'auto',
  });
  const [availableOptions, setAvailableOptions] = useState(options);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const inputRef = useRef(null);

  useEffect(() => {
    setSelectedOptions(value || []);
    setAvailableOptions(options.filter((opt) => !value?.includes(opt)));
  }, [value, options]);

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleFocus = () => {
    const rect = inputRef.current.getBoundingClientRect();
    const shouldDisplayAbove = rect.bottom + 250 > window.innerHeight;
    setDropdownStyle({
      top: shouldDisplayAbove ? rect.top - 250 : rect.bottom,
      left: rect.left,
      width: rect.width,
    });
    setShowOptions(true);
  };

  // Prevent closing when clicking options
  const handleBlur = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setShowOptions(false);
    }
  };

  const handleOptionClick = (option) => {
    setSelectedOptions((prevSelectedOptions) => [
      ...prevSelectedOptions,
      option,
    ]);
    setAvailableOptions((prevAvailableOptions) =>
      prevAvailableOptions.filter((opt) => opt !== option)
    );
    setSearchTerm('');
    onChange({ target: { name, value: [...selectedOptions, option] } });
  };

  const handleRemoveSelected = (option) => {
    setAvailableOptions((prevAvailableOptions) => [
      ...prevAvailableOptions,
      option,
    ]);
    setSelectedOptions((prevSelectedOptions) =>
      prevSelectedOptions.filter((opt) => opt !== option)
    );
    onChange({
      target: { name, value: selectedOptions.filter((opt) => opt !== option) },
    });
  };

  const filteredOptions = availableOptions.filter((option) =>
    option.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className={styles['multi-select-container']}>
      <input
        ref={inputRef}
        id={id}
        name={name}
        disabled={disabled}
        type="text"
        placeholder={placeholder}
        value={searchTerm || ''}
        onChange={handleInputChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />

      {showOptions && filteredOptions.length > 0 && (
        <div
          className={styles['options-container']}
          style={{
            top: `${dropdownStyle.top}px`,
            left: `${dropdownStyle.left}px`,
            width: `${dropdownStyle.width}px`,
          }}
        >
          <h4 className={styles['options-header']}>Search: {label}</h4>
          <ul className={styles['options-list']}>
            {filteredOptions.map((option) => (
              <li
                className={styles['option']}
                key={option}
                onMouseDown={() => handleOptionClick(option)}
              >
                {option}
              </li>
            ))}
          </ul>
        </div>
      )}

      {selectedOptions?.length > 0 && (
        <ul className={styles['selected-options']}>
          {selectedOptions?.map((option) => (
            <li key={option} className={styles['selected-option']}>
              {option}
              <span
                className={styles['remove-option']}
                onClick={() => handleRemoveSelected(option)}
              >
                &times;
              </span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default MultiSelectPicklist;
