import fields from './fields';
import getFieldsByName from '../../utils/getFieldsByName';

const commonLayout = [
    {
        label: 'Deals Information',
        leftFields: getFieldsByName(fields, ['name', 'accountName', 'currency', 'purchasePrice', 'orderValue']),
        rightFields: getFieldsByName(fields, ['salesRep', 'recordType', 'stage'])
    },
    {
        label: 'RFQ Details',
        leftFields: getFieldsByName(fields, ['rfqNumber', 'projectCode', 'cidbNumber', 'productType', 'technicalScope', 'endUser' ]),
        rightFields: getFieldsByName(fields, ['closingDate', 'quoteValidity', 'quoteSubmissionLocation', 'compulsoryBriefing', 'dateOfBriefing', 'briefingAddress'])
    },
    {
        label: 'Product Details',
        leftFields: getFieldsByName(fields, ['productName', 'productDescription'])
    },
    {
        label: 'Associated Contact',
        leftFields: getFieldsByName(fields, ['title', 'firstName', 'lastName', 'client',]),
        rightFields: getFieldsByName(fields, ['industry', 'email', 'phone', 'website'])
    },
    {
        label: 'Internal Comments',
        fullWidthFields: getFieldsByName(fields, ['internalComments'])
    },
];

// Define sections
const sectionsByRecordType = {
    'Web': commonLayout,
    'Email': commonLayout
};

const getSectionsByRecordType = (recordType)  => {
    return sectionsByRecordType[recordType] || [];
}

export default getSectionsByRecordType;