import Header from '../../../layouts/header/Header';

const Activities = () => {
    return (
        <>
            <Header
                headerText="Activities"
                infoText="Track and manage all your important activities here."
            />
        </>
    );
};

export default Activities;