import styles from '../css/Parameters.module.css';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import Field from '../../../components/field/Field';
import { Button } from '@mui/material';
import { toast } from 'react-toastify';
import { updateRecord } from '../../../api/crud';

const CompanyInfoForm = ({ tenantId, data, isLoading }) => {
  const queryClient = useQueryClient();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    description: '',
    website: '',
    subDomain: '',
    startOfFinancialYear: ''
  });

  useEffect(() => {
    setFormData({
      name: data?.name || '',
      email: data?.email || '',
      phone: data?.phone || '',
      description: data?.description || '',
      website: data?.website || '',
      subDomain: data?.subDomain || '',
      startOfFinancialYear: data?.startOfFinancialYear || '',
    });
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const onSave = () => {
    setIsSubmitDisabled(true);
    toast
      .promise(updateRecord('tenant', tenantId, formData), {
        pending: 'Updating...',
        success: 'Company info updated successfully!',
        error: 'Error updating company info',
      })
      .then(() => {
        setIsSubmitDisabled(false);
        queryClient.invalidateQueries(['company-info']);
      })
      .catch((error) => {
        setIsSubmitDisabled(false);
        console.error('Error updating company info', error);
      });
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSave();
      }}
      className={styles['form']}
    >
      <Field
        isLoading={isLoading}
        label="Company Name"
        type="text"
        name="name"
        value={formData.name}
        onChange={handleChange}
        required={true}
      />
      <Field
        isLoading={isLoading}
        label="Email"
        type="email"
        name="email"
        value={formData.email}
        onChange={handleChange}
        required={true}
      />
      <Field
        isLoading={isLoading}
        label="Phone"
        type="text"
        name="phone"
        value={formData.phone}
        onChange={handleChange}
        required={true}
      />
      <Field
        isLoading={isLoading}
        label="Description"
        type="text"
        name="description"
        value={formData.description}
        onChange={handleChange}
        required={true}
      />
      <Field
        isLoading={isLoading}
        label="Website"
        type="text"
        name="website"
        value={formData.website}
        onChange={handleChange}
        required={true}
      />
      <Field
        isLoading={isLoading}
        label="SubDomain"
        type="output"
        name="subDomain"
        value={formData.subDomain}
        disabled={true}
      />
      <Field
        isLoading={isLoading}
        label="Select month for the start of the financial year"
        name="startOfFinancialYear"
        type="picklist"
        options={[
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ]}
        value={formData.startOfFinancialYear}
        onChange={handleChange}
        required={true}
      />
      <div className={styles['button-container']}>
        <Button
          size="small"
          type="submit"
          variant="contained"
          style={{ background: 'var(--primary-color)' }}
          disabled={isSubmitDisabled}
        >
          {isSubmitDisabled ? 'Saving...' : 'Save Changes'}
        </Button>
      </div>
    </form>
  );
};

export default CompanyInfoForm;
