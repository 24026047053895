import { useState, useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import { AuthContext } from '../../contexts/AuthContext';
import { getRecord } from '../../api/crud';
import { useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Card from '@mui/material/Card';
import SEO from '../../components/seo/SEO';
import Header from '../../layouts/header/Header';
import CompanyInfoForm from './sections/CompanyInfoForm';
import RevenueTargetsForm from './sections/RevenueTargetsForm';
import SalesTargetsForm from './sections/SalesTargetsForm';
import EmailToLeadForwardingConfig from './sections/EmailToLeadForwardingConfig';
import EmailToCaseForwardingConfig from './sections/EmailToCaseForwardingConfig';
import TendersConfig from './sections/TendersConfig';

export default function ParameterTabs() {
  const location = useLocation();
  const navigate = useNavigate();
  const { getUserId } = useContext(AuthContext);
  const userId = getUserId();

  // Tab mapping to paths
  const tabs = [
    { label: 'Company Info', path: '/parameter/company-info' },
    { label: 'Revenue Targets', path: '/parameter/revenue-targets' },
    { label: 'Sales Targets', path: '/parameter/sales-targets' },
    { label: 'Email to Lead Config', path: '/parameter/email-to-lead-config' },
    { label: 'Email to Case Config', path: '/parameter/email-to-case-config' },
    { label: 'Tenders Config', path: '/parameter/tenders-config' },
  ];

  const currentTab = tabs.find((tab) => tab.path === location.pathname);

  const [value, setValue] = useState(currentTab.label);

  const { data: user } = useQuery({
    queryFn: () => getRecord('user', userId),
    queryKey: ['logged-in-user'],
  });

  const tenantId = user?.tenantId;

  const { data: companyInfo, isLoading } = useQuery({
    queryFn: () => getRecord('tenant', tenantId),
    queryKey: ['company-info'],
    enabled: !!user,
  });

  // Update the active tab based on the path location
  useEffect(() => {
    const currentTab = tabs.find((tab) => location.pathname.includes(tab.path));
    if (currentTab) {
      setValue(currentTab.label);
    }
  }, [location.pathname]);

  const handleChange = (event, newValue) => {
    const selectedTab = tabs.find((tab) => tab.label === newValue);
    if (selectedTab) {
      navigate(selectedTab.path);
    }
  };

  const infoText =
    'On this page you can update your company info, chart configurations, and more';

  const renderForm = () => {
    switch (value) {
      case 'Company Info':
        return (
          <CompanyInfoForm
            isLoading={isLoading}
            tenantId={companyInfo?._id}
            data={companyInfo}
          />
        );
      case 'Revenue Targets':
        return (
          <RevenueTargetsForm
            isLoading={isLoading}
            tenantId={companyInfo?._id}
            data={companyInfo}
          />
        );
      case 'Sales Targets':
        return (
          <SalesTargetsForm
            isLoading={isLoading}
            tenantId={companyInfo?._id}
            data={companyInfo}
          />
        );
      case 'Email to Lead Config':
        return (
          <EmailToLeadForwardingConfig
            isLoading={isLoading}
            tenantId={companyInfo?._id}
            data={companyInfo}
          />
        );
      case 'Email to Case Config':
        return (
          <EmailToCaseForwardingConfig
            isLoading={isLoading}
            tenantId={companyInfo?._id}
            data={companyInfo}
          />
        );
      case 'Tenders Config':
        return (
          <TendersConfig
            isLoading={isLoading}
            tenantId={companyInfo?._id}
            data={companyInfo}
          />
        );
      default:
        return (
          <Box sx={{ color: 'var(--red-color)' }}>Selected Tab is Invalid</Box>
        );
    }
  };

  return (
    <Box>
      <SEO title="Parameters | Salesdam" description={infoText} />
      <Header headerText="Parameters" infoText={infoText} />
      <Box
        sx={{
          flexGrow: 1,
          maxWidth: { xs: '100%', sm: '100%' },
          bgcolor: 'var(--background-color)',
          marginTop: '20px',
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          aria-label="visible arrows tabs example"
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              '&.Mui-disabled': { opacity: 0.3 },
            },
          }}
        >
          {tabs.map((tab) => (
            <Tab key={tab.label} label={tab.label} value={tab.label} />
          ))}
        </Tabs>
      </Box>
      <Card sx={{ marginTop: '30px', padding: '20px' }}>{renderForm()}</Card>
    </Box>
  );
}
