import styles from '../css/EmailToLeadForwardingConfig.module.css';
import { useEffect, useState } from 'react';
import { getRecords, updateRecord } from '../../../api/crud';
import { useQuery, useQueryClient } from 'react-query';
import { Button } from '@mui/material';
import Field from '../../../components/field/Field';
import leadFields from '../../../objectManager/lead/fields';
import caseFields from '../../../objectManager/case/fields';
import { toast } from 'react-toastify';
import Skeleton from '@mui/material/Skeleton';
import CreateEmailParsingRules from './CreateEmailParsingRules';
import capitalizeFirstLetter from '../../../utils/capitalizeFirstLetter';

const FieldMappingForm = ({ objectType }) => {
  const queryClient = useQueryClient();
  const [ isSubmitting, setIsSubmitting ] = useState(false);

  // Get the email lead field mapping for the tenant
  const { data, isLoading } = useQuery({
    queryFn: () => getRecords(`email-to-${objectType}-mappings`, {}),
    queryKey: ['email-to-lead-field-map'],
  });

  const [fieldMapData, setFieldMapData] = useState({});
  const [errors, setErrors] = useState({}); // Store error messages

  // Initialize field mapping when data is loaded
  const fieldMap = isLoading ? {} : data ? data[0]?.mappings : {};

  useEffect(() => {
    if (!isLoading && data) {
      setFieldMapData(fieldMap);
    }
  }, [isLoading, data]);

  // Lead fields to be included in mapping
  const leadFieldsToInclude = [
    'client',
    'email',
    'technicalScope',
    'projectCode',
  ];

  // Case fields to be included in mapping
  const caseFieldsToInclude = [
    'subject',
    'description',
    'internalComments'
  ];

  // Lead fields to be used for parsing rules
  const fieldsToIncludeForParsingRules = [
    'client',
    'phone',
    'email',
    'technicalScope',
    'projectCode',
    'industry',
    'quoteSubmissionLocation',
    'closingDate',
    'briefingAddress',
    'cidbNumber',
    'website',
    'compulsoryBriefing'
  ];

  // Email fields available for mapping
  const emailFields = {
    subject: 'Subject',
    fromName: 'From Name',
    fromEmail: 'From Email',
    text: 'Text Body',
    html: 'Html Body',
    textAsHtml: 'Text As Html',
    messageId: 'Message Id',
  };

  // Filter lead fields based on the list
  const leadFieldsToMap = leadFields.filter((field) =>
    leadFieldsToInclude.includes(field.name)
  );

  // Filter case fields based on the list
  const caseFieldsToMap = caseFields.filter((field) =>
    caseFieldsToInclude.includes(field.name)
  );

  const fieldsToMap = {
    lead: leadFieldsToMap,
    case: caseFieldsToMap
  }

  // Filter lead fields based on the list
  const fieldsForParsingRules = leadFields.filter((field) =>
    fieldsToIncludeForParsingRules.includes(field.name)
  );

  // Handle changes to the email field mapping
  const handleFieldMappingChange = (field, selectedEmailField) => {
    setFieldMapData((prevData) => ({
      ...prevData,
      [field]: Object.keys(emailFields).find(
        (key) => emailFields[key] === selectedEmailField
      ), // Store the key (e.g., 'subject', 'fromName') instead of the label
    }));

    // Remove error message for this field when a value is selected
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: '',
    }));
  };

  // Validate the form before submitting
  const validateForm = () => {
    const newErrors = {};

    fieldsToMap[objectType].forEach((field) => {
      if (!fieldMapData[field.name]) {
        newErrors[field.name] = `${field.label} is required.`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission for saving the mappings
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      toast.error('Please fill out all required fields.');
      return;
    }

    setIsSubmitting(true);

    try {
      // Assume we are updating the first mapping in the list
      const mappingId = data[0]?._id;
      await updateRecord(`email-to-${objectType}-mapping`, mappingId, {
        mappings: fieldMapData,
      });
      queryClient.invalidateQueries(['email-to-lead-field-map']);
      toast.success('Field mappings saved successfully.');
      setIsSubmitting(false);
    } catch (error) {
      console.error('Failed to save field mappings:', error);
      toast.error(`Failed to save field mappings: ${error.message}`);
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {objectType === 'lead' && (
        <div>
          <h4 className={styles['field-map-heading']}>Define Your Custom Parsing Rules</h4>
          <small>Parsing rules dictate how to extract data from the email body and store it in your chosen fields.</small><br/>
          <small style={{ color: 'red', fontWeight: 'bold' }}>
            Note that if a parsing rule for a field is already defined below, it will take precedence over the field mappings specified on the "Custom Field Mapping" section below.
          </small>
          <CreateEmailParsingRules
            data={data}
            isLoading={isLoading}
            fieldsForParsingRules={fieldsForParsingRules}
          />
        </div>
      )}
      <h4 className={styles['field-map-heading']}>Custom Field Mapping</h4>
      <div className={styles['field-mapping-form']}>
        <form onSubmit={handleSubmit}>
          <table>
            <thead>
              <tr>
                <th scope="col">{`${capitalizeFirstLetter(objectType)}${objectType.slice(1)} Fields`}</th>
                <th scope="col">Email Fields</th>
              </tr>
            </thead>
            <tbody className={styles['table-body']}>
              {fieldsToMap[objectType].map((field) => (
                <tr key={field?.name}>
                  <th scope="row">{field?.label}</th>
                  <td>
                    {isLoading ? (
                      <Skeleton variant="rounded" width="100%" height={35} />
                    ) : (
                      <Field
                        id={field?.name}
                        name={field?.name}
                        type="picklist"
                        options={Object.values(emailFields)}
                        errorMessage={errors[field.name]} // Display error message
                        value={emailFields[fieldMapData[field?.name]] || ''}
                        onChange={(e) =>
                          handleFieldMappingChange(field?.name, e.target.value)
                        }
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="2">
                  <Button
                    size="small"
                    type="submit"
                    variant="contained"
                    style={{ background: 'var(--primary-color)' }}
                    disabled={isSubmitting || isLoading}
                  >
                    {isSubmitting ? 'Saving' : 'Save Mapping'}
                  </Button>
                </td>
              </tr>
            </tfoot>
          </table>
        </form>
      </div>
    </>
  );
};

export default FieldMappingForm;