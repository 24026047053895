const departments = [
  'ESKOM',
  'Agricultural Research Council',
  'Passenger Rail Agency of South Africa',
  'Transnet SOC Ltd',
  'Public Works',
  'Rand Water',
  'Johannesburg City Parks and Zoo',
  'Gauteng – Community Education and Training College',
  'Johannesburg Water',
  'Ethekwini Metropolitan Municipality',
  'City of Cape Town',
  'Kwazulu Natal - Transport',
  'Independent Development Trust',
  'South African National Roads Agency Soc Limited (SANRAL)',
  'Airports Company of South Africa',
  'National Research Foundation',
  'Justice & Constitutional Development',
  'Industrial Development Corporation of South Africa Limited',
  'South African Weather Service',
  'Department of Agriculture, Land Reform & Rural Development',
  'The South African Bureau of Standards (SABS)',
  'City of Tshwane',
  'Transnet National Ports Authority',
  'Umgeni Water',
  'Water and Sanitation',
  'Marine Living Resources Fund',
  'City Council of Johannesburg',
  'National - Environment, Forestry and Fisheries',
  'Mnquma Local Municipality',
  'Sentech Limited',
  'South African National Parks',
  'Council for Scientific and Industrial Research (CSIR)',
  'George Municipality',
  'South African Revenue Service',
  'Broadband Infraco SOC',
  'THE MVULA TRUST',
  'Stellenbosch Municipality',
  'Air Traffic and Navigation Services Company Limited',
  'Development Bank of Southern Africa',
  'South African Local Government Association',
  'Kwa-Zulu Natal - Ezemvelo KwaZulu-Natal Wildlife',
  'Cape Winelands District Municipality',
  'South African Civil Aviation Authority',
  'Automotive Industry Development Centre (AIDC)',
  'Kwazulu Natal - Economic Development, Tourism and Environ Affairs',
  'South African Social Security Agency',
  'South African Broadcasting Corporation Limited',
  'Overstrand Municipality',
  'Mbombela Local Municipality',
  'Ingquza Hill Local Municipality',
  'Border Management Authority',
  'Oudtshoorn Municipality',
  'South African Diamond and Precious Metals Regulator',
  'Inxuba Yethemba Local Municipality',
  'South African Nuclear Energy Corporation Limited',
  'State Information Technology Agency',
  'Eastern Cape - Rural Development and Agrarian Reform',
  'Swellendam Municipality',
  'Iziko Museums of Cape Town',
  'South African Post Office Limited',
  'Dr Nkosazana Dlamini Zuma Local Municipality',
  'Tourism',
  'Prince Albert Municipality',
  'Kouga Municipality',
  'Agrément South Africa',
  'Solplaatje Local Municipality',
  'Perishable Products Export Control Board',
  'Cape Agulhas Municipality',
  'Mossel Bay Municipality',
  'Limpopo - Public Works',
  'CENTLEC SOC LTD',
  'National Lotteries Commission',
  'Cross-Border Road Transport Agency',
  'Mangaung Metropolitan Municipality',
  'Thulamela Local Municipality',
  'Commission for Conciliation Mediation & Arbitration',
  'North West - Department of Public Works and Roads',
  'National Health Laboratory Service',
  'Rustenburg Local Municipality',
  'Council for Geoscience',
  'Western Cape - Health',
  'Msunduzi Local Municipality',
  'Defence',
  'Umzimvubu Local Municipality',
  'Newcastle Local Municipality',
  'Manufacturing, Engineering and Related Services SETA (merSETA)',
  'National Museum Bloemfontein',
  'Nelson Mandela Bay Municipality',
  'Kwazulu Natal - Public Works (Head Office)',
  'Overberg District Municipality',
  'Artscape',
  'Winnie Madikizela-Mandela - EC Local Municipality',
  'South African National Space Agency',
  'Lepelle-Nkumpi Municipality',
  'Umzimkhulu Local Municipality',
  'Johannesburg Development Agency',
  'Human Sciences Research Council',
  'Bojanala District Municipality',
  'Langeberg Municipality',
  'South African Airways (Pty) Limited',
];

export default departments;
