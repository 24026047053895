const StageIndicator = ({ stage }) => {

    const commonStyles = {
        color: 'var(--secondary-text-color)',
        padding: '2px',
        borderRadius: '5px',
        fontSize: '12px',
    };

    const closedWonStyles = {
        ...commonStyles,
        background: 'var(--green-color)',
        color: 'var(--secondary-text-color)'
    };
    const closedLostStyles = {
        ...commonStyles,
        background: 'var(--red-color)'
    };

    const getStageStyle = () => {
        switch (stage){
            case 'Closed Won':
            case 'Qualified':
                return closedWonStyles;
            case 'Closed Lost':
            case 'Unqualified':
                return closedLostStyles;
            case 'Work in Progress':
            case 'Open':
                return {
                    ...commonStyles,
                    backgroundColor: 'var(--blue-color)'
                };
            case 'Submission':
            case 'Contacted':
                return {
                    ...commonStyles,
                    backgroundColor: '#FF8042'
                };
            default:
                return {};
        }
    }

    const getStageText = () => {
        switch (stage){
            case 'Closed Won':
                return 'Won';
            case 'Closed Lost':
                return 'Lost';
            default:
                return stage;
        }
    }
  
    return <span style={getStageStyle()}>{getStageText()}</span>;
};
  
export default StageIndicator;