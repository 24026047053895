import styles from '../css/Parameters.module.css';
import { useState, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import Field from '../../../components/field/Field';
import { Button } from '@mui/material';
import { toast } from 'react-toastify';
import { updateRecord } from '../../../api/crud';

const RevenueTargetsForm = ({ tenantId, data, isLoading }) => {
  const queryClient = useQueryClient();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [formData, setFormData] = useState({
    revenueTargets: {
      quarterOne: 0,
      quarterTwo: 0,
      quarterThree: 0,
      quarterFour: 0,
    }
  });

  useEffect(() => {
    setFormData({
        revenueTargets: {
          quarterOne: data?.revenueTargets?.quarterOne || 0,
          quarterTwo: data?.revenueTargets?.quarterTwo || 0,
          quarterThree: data?.revenueTargets?.quarterThree || 0,
          quarterFour: data?.revenueTargets?.quarterFour || 0,
        }
    });
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const [mainKey, subKey] = name.split('.');

    // Update form data state with nested structure using functional update
    setFormData((prevFormData) => ({
      ...prevFormData,
      [mainKey]: {
        ...prevFormData[mainKey],
        [subKey]: value,
      },
    }));
  };

  const onSave = () => {
    setIsSubmitDisabled(true);
    toast.promise(
        updateRecord('tenant', tenantId, formData),
        {
            pending: 'Updating...',
            success: 'Revenue targets updated successfully!',
            error: 'Error updating revenue targets'
        }
    ).then(() => {
      setIsSubmitDisabled(false);
      queryClient.invalidateQueries(['company-info']);
    }).catch(error => {
      setIsSubmitDisabled(false);
      console.error('Error updating revenue targets', error);
    });
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSave();
      }}
      className={styles['form']}
    >
      <Field
        isLoading={isLoading}
        label="First Quarter Target"
        type="number"
        name="revenueTargets.quarterOne"
        value={formData?.revenueTargets?.quarterOne}
        onChange={handleChange}
        required={true}
      />
      <Field
        isLoading={isLoading}
        label="Second Quarter Target"
        type="number"
        name="revenueTargets.quarterTwo"
        value={formData?.revenueTargets?.quarterTwo}
        onChange={handleChange}
        required={true}
      />
      <Field
        isLoading={isLoading}
        label="Third Quarter Target"
        type="number"
        name="revenueTargets.quarterThree"
        value={formData?.revenueTargets?.quarterThree}
        onChange={handleChange}
        required={true}
      />
      <Field
        isLoading={isLoading}
        label="Fourth Quarter Target"
        type="number"
        name="revenueTargets.quarterFour"
        value={formData?.revenueTargets?.quarterFour}
        onChange={handleChange}
        required={true}
      />
      <div className={styles['button-container']}>
        <Button
          size="small"
          type="submit"
          variant="contained"
          style={{ background: 'var(--primary-color)', marginTop: '20px' }}
          disabled={isSubmitDisabled}
        >
          {isSubmitDisabled ? 'Saving...' : 'Save Changes'}
        </Button>
      </div>
    </form>
  );
};

export default RevenueTargetsForm;
