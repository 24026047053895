import { getGlobalItems, getObjects, getSettings } from './sidebarTabs';

// Combine the sidebar list items
const sidebarTabs = getGlobalItems.concat(getObjects).concat(getSettings);

// This function takes the name of the item and returns its corresponding icon from the getGlobalItems and getObjects
const getIconByName = (name) => {
    const tab = sidebarTabs.find(tab => tab.name === name);
    return tab ? tab.icon : null;
};

export default getIconByName;