// Function to get fields by an array of names. e.g firstName, lastName, etc
const getFieldsByName = (fields, names) => {
    const result = [];
    names.forEach(name => {
        const field = fields.find(field => field.name === name);
        if (field) {
            result.push(field);
        }
    });
    return result;
};

export default getFieldsByName;