const getCurrentStage = (record, objectType) => {
    switch (objectType) {
      case 'lead':
      case 'task':
        return record?.status;
      case 'deal':
        if(record?.stage === 'Closed Won' || record?.stage === 'Closed Lost'){
          return 'Closed';
        }
        return record?.stage;
      default:
        return null;
    }
};

export default getCurrentStage;