import api from './api';

const configureEmailToLead = async (data) => {
  try {
    await api.post('/api/email-to-lead/configure', data);
  } catch (error) {
    console.error('Error sending email', error);
    throw error;
  }
};

export { configureEmailToLead };
