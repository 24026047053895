import styles from './css/RelatedList.module.css';
import { useState } from 'react';
import { useQuery } from 'react-query';
import IconContainer from '../iconContainer/IconContainer';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import getIconByName from '../../utils/getIconByName';
import { getRecords } from '../../api/crud';
import Table from '../charts/table/Table'

const RelatedList = ({ filter, title, relatedItem, columns, isExpandedByDefault }) => {
    const [isExpanded, setIsExpanded] = useState(isExpandedByDefault);
    const { data: records, isLoading} = useQuery({
        queryFn: () => getRecords(relatedItem, filters),
        queryKey: [`related-${relatedItem}-${filter?.value}`]
    });

    const filters = {
        filterLogic: null,
        filters: [
          {
            field: filter.field,
            operator: 'equals',
            value: filter.value
          },
        ]
    };
  
    const toggleAccordion = () => {
      setIsExpanded(!isExpanded);
    };

    return (
        <div className={styles['accordion-container']}>
            <div className={styles['accordion']} onClick={toggleAccordion}>
                <div className={styles['accordion-header']}>
                    <IconContainer isActive={true} icon={getIconByName(title === 'Users' ? 'Manage Users' : title)} />
                    <p className={styles['text']}>{`${title} (${records?.length || 0})`}</p>
                </div>
                <KeyboardArrowRightOutlinedIcon
                    className={`${styles['accordion-arrow']} ${isExpanded ? styles['rotated'] : ''}`}
                />
            </div>
            <div className={`${styles['accordion-content']} ${isExpanded ? styles['expanded'] : ''}`}>
                <Table isLoading={isLoading} data={records} columns={columns} />
            </div>
        </div>
    );
};

export default RelatedList;