import { useState, useEffect, useMemo, useContext } from 'react';
import { AuthContext } from '../../../../contexts/AuthContext';
import getSectionsByRecordType from '../../../../objectManager/contact/layouts';
import DynamicForm from '../../../../components/dynamicForm/DynamicForm';
import formatDateAndTime from '../../../../utils/formatDateAndTime';
import fields from '../../../../objectManager/contact/fields';
import useEffectformValues from '../../../../utils/formValues';
import RelatedList from '../../../../components/relatedList/RelatedList';
import CustomLink from '../../../../components/customLink/CustomLink';

const defaultAddress = {
  street: '',
  city: '',
  province: '',
  zip: '',
  country: ''
};

const RecordForm = ({ isLoading, record, isEditing, onClose, fieldsDisabled, setFieldsDisabled, recordType, handleDelete }) => {
  const { getUserId } = useContext(AuthContext);
  const [formValues, setFormValues] = useState({});

  const defaultValues = useEffectformValues(isEditing, record, fields, getUserId());

  useEffect(() => {
    setFormValues({
      ...defaultValues,
      recordType: isEditing ? record?.recordType : recordType,
      mailingAddress: isEditing && record?.mailingAddress ? record?.mailingAddress : defaultAddress,
      otherAddress: isEditing && record?.otherAddress ? record?.otherAddress : defaultAddress,
      createdAt: isEditing ? formatDateAndTime(record?.createdAt) : '',
      updatedAt: isEditing ? formatDateAndTime(record?.updatedAt) : ''
    });
  }, [record, isEditing]);

  const caseColumns = useMemo(
    () => [
      {
        accessorFn: (row) => <CustomLink to={`/case/${row._id}`} text={row.subject} />,
        id: 'subject',
        header: 'Subject',
      },
      {
        accessorKey: 'status', 
        header: 'Status'
      }
    ],
  []
  );

  const filter = {
    field: 'contact',
    value: record?._id
  }

  return (
    <div className={isEditing ? 'record-form' : ''}>
      {!isLoading && 
        <DynamicForm
          objectType='contact'
          listViewQueryKey='contacts'
          onClose={onClose}
          isEditing={isEditing}
          record={record}
          sections={getSectionsByRecordType(formValues?.recordType)}
          formValues={formValues}
          setFormValues={setFormValues}
          fieldsDisabled={fieldsDisabled}
          setFieldsDisabled={setFieldsDisabled}
          handleDelete={handleDelete}
        />
      }
      {(!isLoading && isEditing) && (
        <div className='related-list'>
          <RelatedList
            title='Cases'
            filter={filter}
            relatedItem='cases'
            columns={caseColumns}
            isExpandedByDefault={true}
          />
        </div>
      )}
    </div>
  );
};

export default RecordForm;
