import styles from './css/RecordForm.module.css';
import { useState, useEffect, useMemo, useContext } from 'react';
import { AuthContext } from '../../../../contexts/AuthContext';
import getSectionsByRecordType from '../../../../objectManager/lead/layouts';
import DynamicForm from '../../../../components/dynamicForm/DynamicForm';
import formatDateAndTime from '../../../../utils/formatDateAndTime';
import fields from '../../../../objectManager/lead/fields';
import useEffectformValues from '../../../../utils/formValues';
import RelatedList from '../../../../components/relatedList/RelatedList';
import CustomLink from '../../../../components/customLink/CustomLink';
import EmailView from '../../../../components/email/EmailView';
import { Attachments } from '../../../../components/attachments/Attachments';

const defaultAddress = {
  street: '',
  city: '',
  province: '',
  zip: '',
  country: ''
};

const RecordForm = ({ isLoading, record, isEditing, onClose, fieldsDisabled, setFieldsDisabled, recordType, handleDelete }) => {
  const { getUserId } = useContext(AuthContext);
  const [formValues, setFormValues] = useState({});

  const defaultValues = useEffectformValues(isEditing, record, fields, getUserId());

  useEffect(() => {
    setFormValues({
      ...defaultValues,
      recordType: isEditing ? record?.recordType : recordType,
      address: isEditing && record?.address ? record?.address : defaultAddress,
      createdAt: isEditing ? formatDateAndTime(record?.createdAt) : '',
      updatedAt: isEditing ? formatDateAndTime(record?.updatedAt) : ''
    });
  }, [record, isEditing]);

  const caseColumns = useMemo(
    () => [
      {
        accessorFn: (row) => <CustomLink to={`/case/${row._id}`} text={row.subject} />,
        id: 'subject',
        header: 'Subject',
      },
      {
        accessorKey: 'status', 
        header: 'Status'
      }
    ],
  []
  );

  const caseFilter = {
    field: 'lead',
    value: record?._id
  }

  return (
    <div className={styles['lead-record-form']}>
      <div className={isEditing ? 'record-form' : ''}>
        {!isLoading && 
          <DynamicForm
            objectType='lead'
            listViewQueryKey='leads'
            onClose={onClose}
            isEditing={isEditing}
            record={record}
            sections={getSectionsByRecordType(formValues?.recordType)}
            formValues={formValues}
            setFormValues={setFormValues}
            fieldsDisabled={fieldsDisabled}
            setFieldsDisabled={setFieldsDisabled}
            handleDelete={handleDelete}
          />
        }
        {(!isLoading && isEditing) && (
          <div className='related-list'>
            <RelatedList
              title='Cases'
              filter={caseFilter}
              relatedItem='cases'
              columns={caseColumns}
              isExpandedByDefault={false}
            />
          </div>
        )}
      </div>
      {(record?.recordType === 'Email Lead' && record?.emailContent) && (
        <>
          <EmailView emailContent={record?.emailContent} />
          <Attachments objectType="lead" relatedRecordId={record?._id} />
        </>
      )}
    </div>
  );
};

export default RecordForm;
