const fields = [
    {
        label: 'Record Type',
        name: 'recordType',
        id: 'taskRecordType',
        type: 'picklist',
        options: ['Default'],
        default: 'Default',
        required: true
    },
    {
        label: 'Subject',
        name: 'subject',
        id: 'taskSubject',
        type: 'text',
        default: '',
        required: true
    },
    {
        label: 'Description',
        name: 'description',
        id: 'taskDescription',
        type: 'text',
        default: '',
        required: true
    },
    {
        label: 'Activity Currency',
        name: 'currency',
        id: 'taskCurrency',
        type: 'picklist',
        options: ['EUR - Euro', 'ZAR - South African Rand'],
        default: 'ZAR - South African Rand',
        required: true
    },
    {
        label: 'Due Date',
        name: 'dueDate',
        id: 'taskDueDate',
        type: 'date',
        default: '',
        required: true
    },
    {
        label: 'Status',
        name: 'status',
        id: 'taskStatus',
        type: 'picklist',
        options: ['Not Started', 'In Progress', 'Completed', 'Waiting on someone else', 'Deferred'],
        default: 'In Progress',
        required: true
    },
    {
        label: 'Priority',
        name: 'priority',
        id: 'taskPriority',
        type: 'picklist',
        options: ['Low', 'Normal', 'High'],
        default: 'Low',
        required: true
    },
    {
        label: 'Assigned To',
        name: 'assignedTo',
        id: 'taskAssignedTo',
        type: 'lookup',
        default: null,
        lookupTo: 'users'
    },
    {
        label: 'Related To',
        name: 'relatedToModel',
        id: 'taskRelatedToModel',
        type: 'picklist',
        options: ['Leads', 'Deals', 'Orders', 'Accounts', 'Contacts', 'Cases'],
        default: '',
    }
];

export default fields;