import styles from './css/ImportRecords.module.css';
import { useState, useContext } from 'react';
import { AuthContext } from '../../../contexts/AuthContext';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import ConvertExcelToJSON from '../../convertExcelToJSON/ConvertExcelToJSON';
import { toast } from 'react-toastify'; // ToastContainer in PageLayout component
import { MaterialReactTable } from 'material-react-table';
import { createRecords } from '../../../api/crud';
import { useQueryClient } from 'react-query';
import getFieldsByObjectType from '../../../utils/getFieldsByObjectType';

const ImportRecords = ({heading, open, onClose, submitButtonLabel, objectType, objectTypePlural, columns}) => {
    const { getUserId } = useContext(AuthContext);
    const queryClient = useQueryClient();
    const [ isSubmitting, setIsSubmitting ] = useState(false);
    const [ isImporting, setIsImporting ] = useState(false);
    const [ records, setRecords ] = useState([]);
    const [ isImportSuccessFul, setImportSuccessful ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ showBack, setShowBack ] = useState(false);

    const handleCancel = () => {
        setRecords([]);
        setImportSuccessful(false);
        onClose(); // Close the modal
    };

    const handleBack = () => {
        setRecords([]);
        setImportSuccessful(false);
        setShowBack(false);
    };

    const handleImportRecords = async () => {
        setIsImporting(true);
        setIsSubmitting(true);
        setLoading(true);
        try {
            await createRecords(objectTypePlural, records);

            showSuccessToast('Records Imported Successfully');
            queryClient.invalidateQueries([objectTypePlural]); // Invalidate and refetch the query
            setImportSuccessful(true);
            setIsImporting(false);
            setIsSubmitting(false);
            setLoading(false);
            setRecords([]);
            setImportSuccessful(false);
            onClose(); // Close the modal
        } catch (error) {
            console.error(error);
            showErrorToast(error.response.data.message);
            setIsImporting(false);
            setIsSubmitting(false);
            setLoading(false);
        }
    };

    const showErrorToast = (message) => {
        toast.error(message,);
    }

    const showSuccessToast = (message) => {
        toast.success(message);
    }

    const handleFileProcessed = (data) => {
        if(data){
            const updatedData = data.map(record => {
                return {
                    ...record,
                    owner: getUserId() // getUserId() is a function that returns the owner ID from the AuthContext
                };
            });
            setRecords(updatedData);
            setShowBack(true); // Show back button
        }
    };

    return (
        <Dialog fullWidth open={open}>
            {!isImportSuccessFul && <DialogTitle textAlign='center'>{records.length === 0 ? heading : 'Preview Records'}</DialogTitle>}
            <DialogContent>
                {loading && (
                    <div className={styles.loading}>
                        <CircularProgress size={68} />
                    </div>
                )}
                {!loading && (
                    <div>
                        {records.length === 0 && <ConvertExcelToJSON onFileProcessed={handleFileProcessed} fields={getFieldsByObjectType(objectType)} />}
                        {records.length > 0 && (
                            <MaterialReactTable
                                key={records._id}
                                getRowId={(row) => row._id} //give each row a more useful id
                                columns={columns}
                                data={records}
                                enableColumnActions={false}
                                enableColumnFilters={false}
                                enablePagination={false}
                                enableSorting={false}
                                enableBottomToolbar={false}
                                enableTopToolbar={false}
                                muiTableBodyRowProps={{ hover: false }}
                            />
                        )}
                    </div>
                )}
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }}>
                {!showBack && <Button style={{color: 'var(--primary-color)'}} size='small' onClick={handleCancel}>Cancel</Button>}
                {showBack && <Button style={{color: 'var(--primary-color)'}} size='small' onClick={handleBack}>Back</Button>}
                {!isImportSuccessFul && (
                    <Button
                        style={{backgroundColor: 'var(--primary-color)'}}
                        size='small'
                        onClick={handleImportRecords}
                        disabled={isSubmitting || records.length === 0}
                        variant='contained'
                    >
                        { isImporting ? 'Importing Records...' : submitButtonLabel}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}

export default ImportRecords;