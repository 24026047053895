import './css/DynamicForm.css';
import { useState } from 'react';
import { useQueryClient, useQuery } from 'react-query';
import { updateRecord, createRecord } from '../../api/crud';
import validateForm from '../../utils/formValidation/validateForm';
import { toast } from 'react-toastify'; // ToastContainer in PageLayout component
import Button from '@mui/material/Button';
import Accordion from '../accordion/Accordion';
import Field from '../field/Field';
import combineFields from '../../utils/combineFields';
import isAddressField from '../../utils/isAddressField';
import { getUserPermissions } from '../../api/authentication';
import getObjectPluralName from '../../utils/getObjectPluralName';

const cancelButtonStyles = {
  color: 'var(--primary-color)',
  border: 'var(--thin-border)',
  backgroundColor: 'var(--secondary-text-color)'
};

const saveButtonStyles = {
  backgroundColor: 'var(--primary-color)'
};

const DynamicForm = ({ objectType, listViewQueryKey, onClose, isEditing, record, sections, formValues, setFormValues, fieldsDisabled, setFieldsDisabled, handleDelete }) => {
  const { data: role, isLoading: isPermissionLoading } = useQuery({
    queryFn: () => getUserPermissions(),
    queryKey: ['logged-in-user-permissions']
  });
  const queryClient = useQueryClient();
  const [errors, setErrors] = useState({});
  const [formErrorMessage, setFormErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const permissions = role?.permissions;
  const objectTypePlural = getObjectPluralName(objectType);

  // object level permissions
  const objectPermissions = permissions[objectTypePlural]; 
  const canDelete = objectPermissions?.delete;

  // field level permissions
  const fieldPermissions = objectPermissions?.fields;

  // function to get the field permissions using the field name
  const getFieldPermissionsByFieldName = (fieldName) => {
    const allFieldsOnPermission = fieldPermissions?.map(field => field?.name);

    if(!allFieldsOnPermission?.includes(fieldName)){
      // build permission object for the field
      // users should be able to view fields by default if it is not part of the field permissions
      const permission = {
        name: fieldName,
        view: true,
        edit: true
      }
      return permission;
    }

    return fieldPermissions?.find(field => field?.name === fieldName);
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if(name?.includes('billingAddress')){
      // If the name includes 'billingAddress', update the nested billingAddress field
      setFormValues({
        ...formValues,
        billingAddress: {
          ...formValues?.billingAddress,
          [name?.split('.')[1]]: value,
        },
      });
    } else if(name?.includes('shippingAddress')){
      // If the name includes 'shippingAddress', update the nested shippingAddress field
      setFormValues({
        ...formValues,
        shippingAddress: {
          ...formValues?.shippingAddress,
          [name?.split('.')[1]]: value,
        },
      });
    } else if(name?.includes('mailingAddress')){
      // If the name includes 'mailingAddress', update the nested mailingAddress field
      setFormValues({
        ...formValues,
        mailingAddress: {
          ...formValues?.mailingAddress,
          [name?.split('.')[1]]: value,
        },
      });
    } else if(name?.includes('otherAddress')){
      // If the name includes 'otherAddress', update the nested otherAddress field
      setFormValues({
        ...formValues,
        otherAddress: {
          ...formValues?.otherAddress,
          [name?.split('.')[1]]: value,
        },
      });
    } else if (name?.includes('address')) {
      // If the name includes 'address', update the nested address field
      setFormValues({
        ...formValues,
        address: {
          ...formValues?.address,
          [name?.split('.')[1]]: value,
        },
      });
    } else if(event.target.type === 'checkbox'){
      setFormValues({...formValues, [name]: event.target.checked});
    } else {
      // Otherwise, update the top-level field
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const onCancel = () => {
    onClose();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Extracting only the fields from the sections on the details form
    const allFieldsOnForm = combineFields(sections);

    const validationErrors = validateForm(formValues, objectType, allFieldsOnForm); // Call the validation function to get errors
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      if (isEditing) {
        // Logic to Update existing record
        setIsSubmitting(true); // Disable the button when clicked
        try {
          await updateRecord(
            objectType,
            record?._id,
            formValues
          );
          setIsSubmitting(false);
          // Invalidate and refetch the query
          queryClient.invalidateQueries([listViewQueryKey]); // refresh the records on the list view
          queryClient.invalidateQueries([objectType]); // refresh the record being updated
          toast.success('Successfully updated record!');
          setFieldsDisabled(!fieldsDisabled);
        } catch (error) {
          setIsSubmitting(false);
          console.error('Error updating record:', error);
          toast.error(error.response.data.message);
        }
      } else {
        // Logic to create a new record
        setIsSubmitting(true); // Disable the button when clicked
        try {
          await createRecord(objectType, formValues);
          setIsSubmitting(false);
          // Invalidate and refetch the query
          queryClient.invalidateQueries([listViewQueryKey]); // refresh the records on the list view
          toast.success('Successfully created Record!');
          onClose(); // close modal
        } catch (error) {
          setIsSubmitting(false);
          console.error('Error creating record:', error);
          toast.error(error.response.data.message);
        }
      }
    } else {
      const message = 'Oops! Looks like there are some missing or incorrect fields. Please review and fix them.';
      setFormErrorMessage(message)
      // toast.error(message);
    }
  };

  const handleAddressValue = (form, formField) => {
    if (!form || !formField || !formField.name) {
      return undefined; // If form or formField is not defined, return undefined
    }
  
    const fieldName = formField.name; // Get the full name of the field
    const key = fieldName.split('.')[1]; // Extract the key after the dot (e.g., "city")
  
    if (fieldName.includes('billingAddress')) {
      return form.billingAddress ? form.billingAddress[key] : undefined;
    } else if (fieldName.includes('shippingAddress')) {
      return form.shippingAddress ? form.shippingAddress[key] : undefined;
    } else if (fieldName.includes('mailingAddress')) {
      return form.mailingAddress ? form.mailingAddress[key] : undefined;
    } else if (fieldName.includes('otherAddress')) {
      return form.otherAddress ? form.otherAddress[key] : undefined;
    } else if (fieldName.includes('address')) {
      return form.address ? form.address[key] : undefined;
    }
  
    return undefined; // Default return value if no match is found
  };

  return (
    <>
      <form className='details-page-form' id={`${objectType}-details-page-form`} onSubmit={handleSubmit}>
        {sections?.map((section) => (
          section.label === 'System Information' && !isEditing ? null : (
          <Accordion
            key={section.label}
            headerText={section?.label}
            accordionContent={
              <div className='fields-layout'>
                <div className='fields-left'>
                  {section?.leftFields?.map((field) => (
                    (getFieldPermissionsByFieldName(field?.name) && getFieldPermissionsByFieldName(field?.name)?.view) && (
                      <Field
                        key={field.id}
                        id={field.id}
                        label={field.label}
                        name={field?.name}
                        type={field.type}
                        lookupTo={field?.lookupTo}
                        required={field.required}
                        disabled={fieldsDisabled || field.disabled || (field.name === 'recordType' && !isEditing) || (getFieldPermissionsByFieldName(field?.name) && !getFieldPermissionsByFieldName(field?.name)?.edit)}
                        options={field?.options}
                        placeholder={field.placeholder}
                        onChange={handleInputChange}
                        value={isAddressField(field) ? handleAddressValue(formValues, field) : formValues[field?.name]}
                        errorMessage={errors[field.name]}
                      />
                    )
                  ))}
                </div>
                <div className='fields-right'>
                  {section?.rightFields?.map((field) => (
                    (getFieldPermissionsByFieldName(field?.name) && getFieldPermissionsByFieldName(field?.name)?.view) && (
                      <Field
                        key={field.id}
                        id={field.id}
                        label={field.label}
                        name={field?.name}
                        type={field.type}
                        lookupTo={field?.lookupTo}
                        required={field.required}
                        disabled={fieldsDisabled || field.disabled || (field.name === 'recordType' && !isEditing) || (getFieldPermissionsByFieldName(field?.name) && !getFieldPermissionsByFieldName(field?.name)?.edit)}
                        options={field?.options}
                        placeholder={field.placeholder}
                        onChange={handleInputChange}
                        value={isAddressField(field) ? handleAddressValue(formValues, field) : formValues[field?.name]}
                        errorMessage={errors[field.name]}
                      />
                    )
                  ))}
                </div>
                <div className='full-width-field'>
                  {section?.fullWidthFields?.map((field) => (
                    (getFieldPermissionsByFieldName(field?.name) && getFieldPermissionsByFieldName(field?.name)?.view) && (
                      <Field
                        key={field.id}
                        id={field.id}
                        label={field.label}
                        name={field?.name}
                        type={field.type}
                        lookupTo={field?.lookupTo}
                        required={field.required}
                        disabled={fieldsDisabled || field.disabled || (field.name === 'recordType' && !isEditing) || (getFieldPermissionsByFieldName(field?.name) && !getFieldPermissionsByFieldName(field?.name)?.edit)}
                        options={field?.options}
                        placeholder={field.placeholder}
                        onChange={handleInputChange}
                        value={isAddressField(field) ? handleAddressValue(formValues, field) : formValues[field?.name]}
                        errorMessage={errors[field.name]}
                      />
                    )
                  ))}
                </div>
              </div>
            }
          />
        )))}
        {!fieldsDisabled && (
          <>
            {formErrorMessage && <small style={{color: 'red', textAlign: 'center', display: 'block'}}>{formErrorMessage}</small>}
            <section className='details-form-actions'>
              {!isEditing &&
                <Button
                  size='small'
                  variant='outlined'
                  style={cancelButtonStyles}
                  onClick={onCancel}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
              }
              <Button
                size='small'
                variant='contained'
                style={saveButtonStyles}
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                Save
              </Button>
              {(isEditing && canDelete && !isPermissionLoading) && 
                <Button
                  onClick={handleDelete}
                  style={{background: 'var(--delete-button-color)'}}
                  variant="contained"
                  size='small'>
                  Delete
                </Button>
                }
            </section>
          </>
        )}
      </form>
    </>
  );
};

export default DynamicForm;