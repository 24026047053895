const detailsPageStages = [
    {
      label: 'Work in Progress',
      value: 'Work in Progress',
    },
    {
      label: 'Submission',
      value: 'Submission'
    },
    {
      label: 'Closed',
      value: 'Closed'
    }
];

const kanbanStages = [
    {
        label: 'Work in Progress',
        value: 'Work in Progress',
    },
    {
        label: 'Submission',
        value: 'Submission'
    },
    {
        label: 'Closed Won',
        value: 'Closed Won'
    },
    {
        label: 'Closed Lost',
        value: 'Closed Lost'
    }
];

export {
    detailsPageStages,
    kanbanStages
};