import { useState, useEffect, useMemo, useContext } from 'react';
import { AuthContext } from '../../../../contexts/AuthContext';
import getSectionsByRecordType from '../../../../objectManager/tenant/layouts';
import DynamicForm from '../../../../components/dynamicForm/DynamicForm';
import formatDateAndTime from '../../../../utils/formatDateAndTime';
import fields from '../../../../objectManager/tenant/fields';
import useEffectformValues from '../../../../utils/formValues';
import RelatedList from '../../../../components/relatedList/RelatedList';
import CustomLink from '../../../../components/customLink/CustomLink';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';

const RecordForm = ({isLoading, record, isEditing, onClose, fieldsDisabled, setFieldsDisabled, recordType, handleDelete}) => {
  const { getUserId } = useContext(AuthContext);
  const [formValues, setFormValues] = useState({});

  const defaultValues = useEffectformValues(isEditing, record, fields, getUserId());

  useEffect(() => {
    setFormValues({
      ...defaultValues,
      recordType: isEditing ? record?.recordType : recordType,
      lastModifiedBy: isEditing ? record?.lastModifiedBy?._id : null,
      createdAt: isEditing ? formatDateAndTime(record?.createdAt) : '',
      updatedAt: isEditing ? formatDateAndTime(record?.updatedAt) : ''
    });
  }, [record, isEditing]);

  const userColumns = useMemo(
    () => [
      {
        accessorFn: (row) => <CustomLink to={`/user/${row._id}`} text={`${row.firstName} ${row.lastName}`} />,
        id: 'name',
        header: 'Name',
      },
      {
        accessorFn: (row) => row.isActive ? <CheckBoxOutlinedIcon /> : <CheckBoxOutlineBlankOutlinedIcon /> , 
        id: 'isActive',
        header: 'Is Active'
      },
    ],
  []
  );

  const filter = {
    field: 'tenantId',
    value: record?._id
  }

  return (
    <div className={isEditing ? 'record-form' : ''}>
      {!isLoading &&
        <DynamicForm
          objectType='tenant'
          listViewQueryKey='tenants'
          onClose={onClose}
          isEditing={isEditing}
          record={record}
          sections={getSectionsByRecordType(formValues?.recordType)}
          formValues={formValues}
          setFormValues={setFormValues}
          fieldsDisabled={fieldsDisabled}
          setFieldsDisabled={setFieldsDisabled}
          handleDelete={handleDelete}
        />
      }
      {(!isLoading && isEditing) && (
        <div className='related-list'>
          <RelatedList
            title='Users'
            filter={filter}
            relatedItem='users'
            columns={userColumns}
            isExpandedByDefault={true}
          />
        </div>
      )}
    </div>
  );
};

export default RecordForm;
