import { useContext, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useParams, useLocation } from 'react-router-dom';
import { AuthContext } from '../../../contexts/AuthContext';
import { getRecords, updateRecord } from '../../../api/crud';
import details from '../../../objectManager/task/objectDetails';
import Header from '../../../layouts/header/Header';
import RecordForm from './recordForm/RecordForm';
import ListViewTable from '../../../components/listViewTable/ListViewTable';
import KanbanBoard from '../../../components/kanbanBoard/KanbanBoard';
import DetailsPage from '../../../components/detailsPage/DetailsPage';
import { today, beginningOfDay, endingOfDay, formatDate } from '../../../utils/dateUtils';
import SEO from '../../../components/seo/SEO';
import { toast } from 'react-toastify'; // ToastContainer in PageLayout component
import CustomLink from '../../../components/customLink/CustomLink';

const Tasks = ({ showDetailsPage }) => {
  const location = useLocation();
  const { getUserId } = useContext(AuthContext);
  const { listId } = useParams();
  const { data: records, refetch, isLoading} = useQuery({
    queryFn: () => getRecords(details.apiNamePlural, listId ? listViews?.find(list => list._id === listId) : null),
    queryKey: [details.apiNamePlural, listId]
  });

  const stages = [
    {
      label: 'Not Started',
      value: 'Not Started'
    },
    {
      label: 'In Progress',
      value: 'In Progress'
    },
    {
      label: 'Completed',
      value: 'Completed'
    },
    {
      label: 'Waiting on someone else',
      value: 'Waiting on someone else'
    },
    {
      label: 'Deferred',
      value: 'Deferred'
    }
  ];

  const columns = useMemo(
    () => [
      {
        accessorKey: 'subject', // Accessor for searching
        id: 'subject',
        header: 'Subject',
        Cell: ({ row }) => (
          <CustomLink to={`/task/${row.original._id}`} text={row.original.subject} />
        ),
      },
      {
        accessorKey: 'description',
        header: 'Description',
        size: 150
      },
      {
        accessorKey: 'status',
        header: 'Status'
      },
      {
        accessorKey: 'priority',
        header: 'Priority'
      },
      {
        accessorFn: (row) => formatDate(row.dueDate),
        id: 'dueDate',
        header: 'Due Date'
      },
      {
        accessorFn: (row) => row.assignedTo ? `${row.assignedTo.firstName} ${row.assignedTo.lastName}` : '', //accessorFn used to join multiple data into a single cell
        id: 'assignedTo', //id is still required when using accessorFn instead of accessorKey
        header: 'Assigned To'
      }
    ],
  []
  );

  const importColumns = useMemo(
    () => [
      {
        accessorKey: 'subject', 
        header: 'Subject'
      },
      {
        accessorKey: 'description',
        header: 'Description',
        size: 150
      },
      {
        accessorKey: 'status',
        header: 'Status'
      },
      {
        accessorKey: 'priority',
        header: 'Priority'
      },
      {
        accessorFn: (row) => formatDate(row.dueDate),
        id: 'dueDate',
        header: 'Due Date'
      },
    ],
  []
  );

  const listViews = [
    {
      _id: '15ee89d4-581a-41ab-8347-64dd157cf77d',
      label: 'All Tasks',
      filterLogic: null,
      filters: []
    },
    {
      _id: 'cec9e800-651c-4715-bdd1-f7b03beac4bb',
      label: 'My Tasks',
      filterLogic: null,
      filters: [
        {
          'field': 'assignedTo',
          'operator': 'equals',
          'value': getUserId()
        }
      ]
    },
    {
      _id: '64e2798f-ce71-4f52-950c-c997ac14d093',
      label: `Today's Tasks`,
      filterLogic: '1 AND 2',
      filters: [
        {
          'field': 'createdAt',
          'operator': 'greaterOrEqual',
          'value': beginningOfDay() // Start timestamp for today
        },
        {
          'field': 'createdAt',
          'operator': 'lessOrEqual',
          'value': endingOfDay() // End timestamp for today
        }
      ]
    },
    {
      _id: '7aff34dc-84dd-414c-b9f0-27edcf01b546',
      label: `Due Today`,
      filterLogic: null,
      filters: [
        {
          'field': 'dueDate',
          'operator': 'equals',
          'value': today()
        }
      ]
    },
    {
      _id: 'b37a9070-d6a9-4445-8940-3530488ccb51',
      label: 'Completed Tasks',
      filterLogic: null,
      filters: [
        {
          'field': 'status',
          'operator': 'equals',
          'value': 'Completed'
        }
      ]
    },
    {
      _id:'18d54876-84a4-4fd9-8dae-a8a8214b10e0',
      label: 'Overdue Tasks',
      filterLogic: '1 AND 2',
      filters: [
        {
          'field': 'dueDate',
          'operator': 'lessOrEqual',
          'value': today()
        },
        {
          'field': 'status',
          'operator': 'notEqual',
          'value': 'Completed'
        }
      ]
    },
    {
      _id: '38e751ca-65a2-4ad2-b9ec-782440330956',
      label: 'High Priority Tasks',
      filterLogic: null,
      filters: [
        {
          'field': 'priority',
          'operator': 'equals',
          'value': 'High'
        }
      ]
    }
  ];

  const statusValues =  [
    {label: 'Not Started', value: 'Not Started'},
    {label: 'In Progress', value: 'In Progress'},
    {label: 'Completed', value: 'Completed'},
    {label: 'Waiting on someone else', value: 'Waiting on someone else'},
    {label: 'Deferred', value: 'Deferred'}
  ];

  const updateTaskStatus = (taskId, destinationColumn) => {
    const updatePromise = updateRecord(details.apiName, taskId, { status: destinationColumn });
  
    toast.promise(
      updatePromise,
      {
        pending: `Updating ${details.label} status...`,
        success: 'Successfully updated record!',
        error: 'Error updating record',
      }
    );
  
    return updatePromise; // Return the promise to maintain consistency
  };

  return (
    <>
      <SEO
        title={`${details.pluralLabel} | Salesdam`}
        description={details.description}
      />
      <Header
        objectType={details.apiName}
        infoText={details.description}
        type='listview'
        listViews={listViews}
      />
      {showDetailsPage && (
        <DetailsPage
          objectType={details.apiName}
          listViewLink={`/${details.apiNamePlural}`}
          refetch={refetch}
          showStages={true}
          stages={stages}
          listViewQueryKey={details.apiNamePlural}
          RecordForm={<RecordForm />}
        />
      )}
      <div className="list-view">
        {location.pathname === `/${details.apiNamePlural}/kanban` ? (
          <KanbanBoard
            items={records}
            field="status"
            values={statusValues}
            onDragEnd={(result) => {
              updateTaskStatus(result.draggableId, result.destination.droppableId);
            }}
            objectType={details.apiName}
            objectTypePlural={details.apiNamePlural}
            navigateTo={`/${details.apiNamePlural}`}
          />
          ) : (
          <ListViewTable
            enableRowActions={true}
            enableRowSelection={true}
            isLoading={isLoading}
            objectType={details.apiName}
            objectTypePlural={details.apiNamePlural}
            refetch={refetch}
            listViewQueryKey={details.apiNamePlural}
            columns={columns}
            importColumns={importColumns}
            data={records}
            RecordForm={<RecordForm />}
          />
        )}
      </div>
    </>
  );
};

export default Tasks;