import api from './api';

const fetchData = async (url) => {
  try {
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.error(`Error fetching data from ${url}:`, error);
    throw error;
  }
};

/*
    Possible values for objectType: leads, tasks, users (All plural)
*/
const getRecords = async (objectType, filter) => {
  const url = `/api/crud/${objectType}`;
  try {
    const response = await api.get(url,{
      params: { filter: filter }
    });
    return response.data;
  } catch (error) {
    console.error(`Error fetching data from ${url}:`, error);
    throw error;
  }
};

const getSummary = async (objectType, filter) => {
  const url = `/api/${objectType}`;
  try {
    const response = await api.get(url,{
      params: { filter: filter }
    });
    return response.data;
  } catch (error) {
    console.error(`Error fetching data from ${url}:`, error);
    throw error;
  }
};

const getRecord = async (objectType, id) => {
  const url = `/api/crud/${objectType}/${id}`;

  return await fetchData(url);
};

// Possible values for objectType: user, lead (Passed in as strings)
const deleteRecord = async (objectType, id) => {
  const url = `/api/crud/${objectType}/${id}`;

  try {
    const response = await api.delete(url);
    return response.data;
  } catch (error) {
    console.error(`Error deleting ${objectType}`, error);
    throw error;
  }
}

// Possible values for objectType: leads, tasks, users (Passed in as strings)
const deleteRecords = async (objectType, ids) => {
  try {
    let url = `/api/crud/${objectType}`;

    const response = await api.delete(url, {
      data: {
        ids: ids
      }
    });

    return response.data;
  } catch (error) {
    console.error(`Error deleting ${objectType}`, error);
    throw error;
  }
}

// Possible values for objectType: user, lead (Passed in as strings)
const updateRecord = async (objectType, id, data) => {
  let url = `/api/crud/${objectType}`;
  try {
    url += '/' + id;
    const response = await api.put(url, data);
    return response.data;
  } catch (error) {
    console.error(`Error updating ${objectType}`, error);
    throw error;
  }
};

// Possible values for objectType: users, leads, tasks (Passed in as strings)
const updateRecords = async (objectType, ids, updates) => {
  let url = `/api/crud/${objectType}/update`;
  try {
    if(objectType === 'users'){
      const response = await api.put(url, {
        userIds: ids,
        updates: updates
      });
      return response;
    }
  } catch (error) {
    console.error(`Error updating ${objectType}`, error);
    throw error;
  }
};

// Possible values for objectType: user, lead, task (Passed in as strings)
const createRecord = async (objectType, data) => {
  let url = `/api/crud/${objectType}`;

  try {
    const response = await api.post(url, data);
    return response.data;
  } catch (error) {
      console.error(`Error creating ${objectType}`, error);
      throw error;
  }
};

// Possible values for objectType: users, leads, tasks (Passed in as strings)
const createRecords = async (objectTypePlural, records) => {
  try {
    const response = await api.post(`/api/crud/records/${objectTypePlural}`, records);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export {
  getRecord,
  getSummary,
  getRecords,
  deleteRecord,
  deleteRecords,
  updateRecord,
  updateRecords,
  createRecord,
  createRecords
};