const fields = [
    {
        label: 'Record Type',
        name: 'recordType',
        id: 'dealRecordType',
        type: 'picklist',
        options: ['Web', 'Email'],
        default: '',
        required: false
    },
    {
        label: 'Industry',
        name: 'industry',
        id: 'dealIndustry',
        type: 'picklist',
        options: ['Agriculture', 'Apparel', 'Banking', 'Biotechnology', 'Chemicals', 'Communications', 'Construction', 'Consulting', 'Education', 'Electronics', 'Energy', 'Engineering', 'Entertainment', 'Environmental', 'Finance', 'Food & Beverage', 'Government', 'Healthcare', 'Hospitality', 'Insurance', 'Machinery', 'Manufacturing', 'Media', 'Mining', 'Not For Profit', 'Recreation', 'Retail', 'Shipping', 'Technology', 'Telecommunications', 'Transportation', 'Utilities', 'Other'],
        default: '',
    },
    {
        label: 'Deal Name',
        name: 'name',
        id: 'dealName',
        type: 'text',
        default: '',
        required: true
    },
    {
        label: 'Title',
        name: 'title',
        id: 'dealTitle',
        type: 'picklist',
        options: ['Mr.', 'Ms.', 'Mrs.', 'Dr.', 'Prof.'],
        default: '',
        required: false
    },
    {
        label: 'Email',
        name: 'email',
        id: 'dealEmail',
        type: 'email',
        default: '',
    },
    {
        label: 'Phone',
        name: 'phone',
        id: 'dealPhone',
        type: 'text',
        default: '',
        required: false
    },
    {
        label: 'Website',
        name: 'website',
        id: 'dealWebsite',
        type: 'text',
        default: '',
        required: false
    },
    {
        label: 'First Name',
        name: 'firstName',
        id: 'dealFirstName',
        type: 'text',
        default: '',
    },
    {
        label: 'Last Name',
        name: 'lastName',
        id: 'dealLastName',
        type: 'text',
        default: '',
    },
    {
        label: 'Client',
        name: 'client',
        id: 'dealClient',
        type: 'text',
        default: '',
        required: true
    },
    {
        label: 'End User',
        name: 'endUser',
        id: 'dealEndUser',
        type: 'text',
        default: '',
    },
    {
        label: 'Stage',
        name: 'stage',
        id: 'dealStage',
        type: 'picklist',
        options: ['Work in Progress', 'Submission', 'Closed Won', 'Closed Lost'],
        default: 'Work in Progress',
        required: false
    },
    {
        label: 'Quote Submission Location',
        name: 'quoteSubmissionLocation',
        id: 'dealQuoteSubmissionLocation',
        type: 'textarea',
        default: '',
    },
    {
        label: 'Project Code',
        name: 'projectCode',
        id: 'dealProjectCode',
        type: 'text',
        default: '',
    },
    {
        label: 'RFQ Number',
        name: 'rfqNumber',
        id: 'dealRfqNumber',
        type: 'text',
        default: '',
        required: false
    },
    {
        label: 'Product Type',
        name: 'productType',
        id: 'dealProductType',
        type: 'picklist',
        options: ['Generator', 'UPS Solar Backup Power', 'Mechanical Engeneering', 'Manufacturing', 'Transformers', 'Other'],
        default: '',
    },
    {
        label: 'CIDB Number',
        name: 'cidbNumber',
        id: 'dealCIDBNumber',
        type: 'text',
        default: '',
        required: false
    },
    {
        label: 'Technical Scope',
        name: 'technicalScope',
        id: 'dealTechnicalScope',
        type: 'textarea',
        default: '',
        required: true
    },
    {
        label: 'Quote Validity Period',
        name: 'quoteValidity',
        id: 'dealQuoteValidity',
        type: 'number',
        default: 0,
    },
    {
        label: 'Reason For Closed Lost',
        name: 'closedLostReason',
        id: 'dealClosedLostReason',
        type: 'picklist',
        options: ['Price', 'Technical Scope', 'Compliance', 'Tender Recalled'],
        default: '',
    },
    {
        label: 'Closing Date',
        name: 'closingDate',
        id: 'dealClosingDate',
        type: 'datetime',
        default: '',
        required: true
    },
    {
        label: 'Compulsory Briefing?',
        name: 'compulsoryBriefing',
        id: 'dealCompulsoryBriefing',
        type: 'picklist',
        options: ['Yes', 'No'],
        default: '',
    },
    {
        label: 'Date/Time of Briefing',
        name: 'dateOfBriefing',
        id: 'dealDateOfBriefing',
        type: 'datetime',
        required: false
    },
    {
        label: 'Briefing Address',
        name: 'briefingAddress',
        id: 'dealBriefingAddress',
        type: 'textarea',
        default: '',
        required: false
    },
    {
        label: 'Order Value (R)',
        name: 'orderValue',
        id: 'dealOrderValue',
        type: 'number',
        default: 0,
    },
    {
        label: 'Sales Rep',
        name: 'salesRep',
        id: 'dealSalesRep',
        type: 'lookup',
        lookupTo: 'users',
        default: null,
    },
    {
        label: 'Account Name',
        name: 'account',
        id: 'dealAccountName',
        type: 'lookup',
        lookupTo: 'accounts',
        default: null,
    },
    {
        label: 'Lead Source',
        name: 'leadSource',
        id: 'dealLeadSource',
        type: 'picklist',
        options: ['Email', 'Web'],
        default: '',
    },
    {
        label: 'Deal Currency',
        name: 'currency',
        id: 'dealCurrency',
        type: 'picklist',
        options: ['EUR - Euro', 'ZAR - South African Rand', 'USD - US Dollar'],
        default: 'ZAR - South African Rand',
    },
    {
        label: 'Purchase Price (R)',
        name: 'purchasePrice',
        id: 'dealPurchasePrice',
        type: 'number',
        default: 0,
    },
    {
        label: '',
        name: 'internalComments',
        id: 'dealInternalComments',
        type: 'textarea',
        default: '',
        required: false
    },
    {
        label: 'Quantity',
        name: 'quantity',
        id: 'dealQuantity',
        type: 'number',
        default: 0,
    },
    {
        label: 'Type',
        name: 'type',
        id: 'dealType',
        type: 'text',
        default: '',
    },
    {
        label: 'Product Name',
        name: 'productName',
        id: 'dealProductName',
        type: 'text',
        default: '',
    },
    {
        label: 'Product Description',
        name: 'productDescription',
        id: 'dealProductDescription',
        type: 'textarea',
        default: '',
    },
];

export default fields;