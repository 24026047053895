import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Text,
} from 'recharts';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import formatToRand from '../../../utils/formatToRands';
import dayjs from 'dayjs';

const DoubleLineChart = ({ caption, data, unitsType, dataKey1, dataKey2, legendPayload, isLoading }) => {
  // Custom tooltip component to display data values when hovering over a point
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            padding: '10px',
          }}
        >
          <p className="label">{`${label}`}</p>
          <p className="intro">{`Current Year: ${
            unitsType === 'South African Rands' ? formatToRand(payload[0].value) : payload[0].value
          }`}</p>
          <p className="intro">{`Previous Year: ${
            unitsType === 'South African Rands' ? formatToRand(payload[1].value) : payload[1].value
          }`}</p>
        </div>
      );
    }

    return null;
  };

  // Custom tick rendering function to highlight the current month
  const renderCustomTick = (props) => {
    const { x, y, payload } = props; // Extract properties from the tick
    const currentMonth = dayjs().format('MMMM'); // Get the current month in 'MMMM' format
    const isCurrentMonth = payload.value === currentMonth; // Check if the tick value matches the current month

    return (
      <Text
        x={x}
        y={y + 10}
        textAnchor="middle"
        fill={isCurrentMonth ? '#FF0000' : '#666'} // Red color for the current month, default color otherwise
        fontWeight={isCurrentMonth ? 'bold' : 'normal'} // Bold font weight for the current month
      >
        {payload.value}
      </Text>
    );
  };

  return (
    <>
      {isLoading ? (
        <Box sx={{ width: '100%', height: 400 }}>
          <Skeleton variant="rectangular" width="100%" height="100%" />
        </Box>
      ) : (
        <>
          <h2 style={{ textAlign: 'center', margin: '10px 0' }}>{caption}</h2> {/* Chart caption */}
          <ResponsiveContainer width="100%" height={400}> {/* Responsive container for the chart */}
            <LineChart
              width={500}
              height={300}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 50,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" /> {/* Grid lines */}
              <XAxis dataKey="month" tick={renderCustomTick} /> {/* X-axis with custom tick rendering */}
              <YAxis width={100} tickFormatter={(value) => formatToRand(value)} /> {/* Y-axis with custom tick formatting */}
              <Tooltip content={<CustomTooltip />} /> {/* Tooltip component */}
              <Legend payload={legendPayload} iconSize={30} />
              <Line type="monotone" dataKey={dataKey1} stroke="#8884d8" strokeWidth={3} /> {/* Line for the current year's data */}
              <Line type="monotone" dataKey={dataKey2} stroke="#82ca9d" strokeWidth={3} /> {/* Line for the previous year's data */}
            </LineChart>
          </ResponsiveContainer>
        </>
      )}
    </>
  );
};

export default DoubleLineChart;