import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { deleteRecord, deleteRecords } from '../../api/crud';
import {MaterialReactTable, useMaterialReactTable, MRT_GlobalFilterTextField as GlobalFilterTextField} from 'material-react-table';
import {Box, Button, ListItemIcon, MenuItem, lighten, IconButton} from '@mui/material';
import Menu from '@mui/material/Menu';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import NewRecord from '../modals/newRecord/NewRecord';
import { toast } from 'react-toastify'; // ToastContainer in PageLayout component
import firstCharacterToUpperCase from '../../utils/firstCharacterToUpperCase';
import ImportRecords from '../modals/importRecords/ImportRecords';
import ExportRecords from '../modals/exportRecords/ExportRecords';
import SettingsMenu from './SettingsMenu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getUserPermissions } from '../../api/authentication';
import getObjectDetailsByName from '../../utils/getObjectDetailsByName';

const ListViewTable = ({objectType, objectTypePlural, enableRowActions, enableRowSelection, hideNewButton, hideMore, hideRefresh, hideSettings, hideRecordCount, disableDeleteRow, disableView, rowActions, columns, importColumns, data, RecordForm, refetch, listViewQueryKey, isLoading}) => {
  const { data: role, isLoading: isPermissionLoading } = useQuery({
    queryFn: () => getUserPermissions(),
    queryKey: ['logged-in-user-permissions']
  });
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [ newRecordModalState, setNewRecordModalState ] = useState({
    isEditing: false,
    openModal: false,
    modalHeading: ''
  });
  const [ recordImportModalState, setRecordImportModalState ] = useState({
    openModal: false,
    modalHeading: '',
    modalButtonLabel: '',
    objectTypePlural: ''
  });
  const [ exportRecordsModalState, setExportRecordsModalState ] = useState({
    openModal: false,
    objectTypePlural: '',
    data: []
  });

  const permissions = role?.permissions;
  const objectPermissions = permissions[objectTypePlural];
  
  const allObjectsOnPermission = Object.keys(permissions); // get all the objects with permissions defined

  const getObjectDetails = getObjectDetailsByName(objectType);
  const getMongooseModelCollectionName = getObjectDetails?.mongooseModelCollectionName;

  // ensures canCreate and canDelete default to true if mongoose model/object is not part of allObjectsOnPermission i.e. it has no permissions defined
  const canCreate = allObjectsOnPermission?.includes(getMongooseModelCollectionName) ? objectPermissions?.create : true;
  const canDelete = allObjectsOnPermission?.includes(getMongooseModelCollectionName) ? objectPermissions?.delete : true;

  const loading = isLoading || isPermissionLoading;

  const [anchorEl, setAnchorEl] = useState(null);

  // Determine if the menu is open
  const isMenuOpen = Boolean(anchorEl);

  // Handle opening the menu
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Handle closing the menu
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleAddRecord = () => {
    setNewRecordModalState({
      openModal: true,
      modalHeading: `New ${firstCharacterToUpperCase(objectType)}` // Convert the first character to uppercase
    });
  };

  const handleImportClick = () => {
    setRecordImportModalState({
      openModal: true,
      objectTypePlural: objectTypePlural,
      modalHeading: `Import ${firstCharacterToUpperCase(objectTypePlural)}`,
      modalButtonLabel: 'IMPORT'
    })
    handleCloseMenu()
  };

  const handleExportClick = (selectedRecords) => {
    const selectedRowIds = selectedRecords?.map(rows => rows?.original?._id);
    const selectedRows = data?.filter(record => selectedRowIds?.includes(record?._id));
    setExportRecordsModalState({
      openModal: true,
      objectTypePlural: objectTypePlural,
      data: data,
      selectedRecords: selectedRecords?.length > 0 ? selectedRows : []
    })
    handleCloseMenu();
  };

  // Refresh the data on the list view
  const handleRefresh = async () => {
    try {
      refetch();
      toast.success('Records refreshed');
    } catch (error){
      console.error('error refreshing the data', error);
      toast.error('Error refreshing the data');
    }
  };

  const handleDeleteRecord = async (row) => {
    if (window.confirm(`Are you sure you want to delete this ${objectType}?`)){
      try {
        await deleteRecord(objectType, row.original._id);
        toast.success('Successfully deleted record!');
        queryClient.invalidateQueries([listViewQueryKey]); // refresh list view query (records)
      } catch (error) {
        console.error('Error deleting record:', error);
        toast.error(`Error creating record: ${error.response.data.message}`);
      }
    }
  };

  // Delete selected records
  const handleDeleteSelectedRecords = async (table, recordIds) => {
    if (window.confirm(`Are you sure you want to delete the selected ${objectType} records?`)){
      try {
        await deleteRecords(objectTypePlural, recordIds);
        toast.success('Records Deleted Successfully!');
        table.resetRowSelection(); // Unselect the rows
        handleCloseMenu();
      } catch (error) {
        console.error('Error deleting tasks:', error);
        toast.error(error.response.data.message);
      }
    }
  };

  const table = useMaterialReactTable({
    columns,
    data: data || [],
    enableColumnFilterModes: true,
    enableColumnPinning: true,
    enableFacetedValues: true,
    enableRowActions: enableRowActions,
    enableRowSelection: enableRowSelection,
    state: {
      isLoading: loading
    },
    initialState: {
      showGlobalFilter: true,
      density: 'compact'
    },
    muiTablePaperProps: {
      elevation: 0, //change the mui box shadow
      //customize paper styles
      sx: {
        border: 'var(--thin-border)',
        borderRadius: 'var(--border-radius)'
      },
    },
    paginationDisplayMode: 'pages',
    renderRowActionMenuItems: ({ closeMenu, row }) => {
      const menuItems = [
        <MenuItem
          key={0}
          onClick={() => {
            // Show the details page modal
            navigate(`/${objectType}/${row.original?._id}`);
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          View
        </MenuItem>
      ];

      if(disableView){
        menuItems.splice(0, menuItems.length);
      }

      // Map over rowActions and add MenuItem for each action
      if (rowActions) {
        rowActions.forEach((action, index) => {
          menuItems.push(
            <MenuItem
              key={index + 1}
              onClick={() => {
                action?.onClick(row.original); // Invoke the onClick function
                closeMenu();
              }}
              sx={{ m: 0 }}
            >
              <ListItemIcon>
                {action?.icon}
              </ListItemIcon>
              {action?.label}
            </MenuItem>
          );
        });
      }

      // Add Delete MenuItem
      (!disableDeleteRow && canDelete) && menuItems.push(
        <MenuItem
          key={menuItems.length}
          onClick={() => {
            // Delete logic...
            handleDeleteRecord(row);
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          Delete
        </MenuItem>
      );

      return menuItems;
    },
    renderTopToolbar: ({ table }) => {
      const handleDeleteSelected = () => {
        const selectedRows = table.getSelectedRowModel().rows; // get selected rows
        const recordIds = selectedRows.map(row => row.original._id); // get the record ids from the selected rows
        handleDeleteSelectedRecords(table, recordIds);
      };

      return (
        <Box
          sx={(theme) => ({
            backgroundColor: lighten(theme.palette.background.default, 0.05),
            display: 'flex',
            gap: '0.5rem',
            p: '8px',
            justifyContent: 'space-between',
          })}
        >
          <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
            {/* import MRT sub-components */}
            <GlobalFilterTextField table={table} /> {/* the search textfield component for global filtering */}
          </Box>
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
              {data && !hideRecordCount && (
                <p>{data?.length} record{data?.length === 1 ? '' : 's'}</p>
              )}
              {(!hideNewButton && canCreate) && (
                <Button
                  style={{color: 'var(--primary-color)', borderColor: 'var(--primary-color)'}}
                  variant="outlined"
                  disabled={table.getSelectedRowModel()?.rows?.length !== 0}
                  size='small'
                  onClick={handleAddRecord}
                >
                  New
                </Button>
              )}
              {!hideMore && (
                <Button
                  style={{color: 'var(--primary-color)', borderColor: 'var(--primary-color)'}}
                  variant="outlined"
                  size='small'
                  onClick={handleOpenMenu}
                >
                  More <ExpandMoreIcon />
                </Button>
              )}
              <Menu
                anchorEl={anchorEl} // The element the menu is anchored to
                open={isMenuOpen} // Determines if the menu is open
                onClose={handleCloseMenu} // Close the menu when needed
              >
                {/* Menu options */}
                {canCreate && <MenuItem onClick={handleImportClick}>Import</MenuItem>}
                <MenuItem onClick={() => handleExportClick(table.getSelectedRowModel()?.rows)}>Export</MenuItem>
                {canDelete && <MenuItem onClick={handleDeleteSelected}>Delete</MenuItem>}
              </Menu>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
              {!hideRefresh && (
                <IconButton onClick={handleRefresh} aria-label="refresh data">
                  <RefreshIcon />
                </IconButton>
              )}
              {!hideSettings && (
                <SettingsMenu
                  objectType={objectType}
                  objectTypePlural={objectTypePlural}
                />
              )}
            </Box>
          </Box>
        </Box>
      );
    }
  });

  return (
    <>
      {newRecordModalState.openModal && (
        <NewRecord
          open={newRecordModalState.openModal}
          RecordForm={RecordForm}
          objectType={objectType}
          onClose={() => setNewRecordModalState({
            openModal: false
          })}
          heading={newRecordModalState.modalHeading}
        />
      )}
      {recordImportModalState.openModal && (
        <ImportRecords
          open={recordImportModalState.openModal}
          heading={recordImportModalState.modalHeading}
          columns={importColumns}
          objectType={objectType}
          objectTypePlural={recordImportModalState.objectTypePlural}
          submitButtonLabel={recordImportModalState.modalButtonLabel}
          onClose={() => setRecordImportModalState({
              openModal: false
          })}
        />
      )}
      {exportRecordsModalState.openModal && (
        <ExportRecords
          open={exportRecordsModalState.openModal}
          objectTypePlural={exportRecordsModalState.objectTypePlural}
          data={exportRecordsModalState.data}
          selectedRecords={exportRecordsModalState.selectedRecords}
          onClose={() => setExportRecordsModalState({
            openModal: false
          })}
        />
      )}
      <MaterialReactTable table={table} />
    </>
  );
};

export default ListViewTable;