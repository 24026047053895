import { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../../../contexts/AuthContext';
import getSectionsByRecordType from '../../../../objectManager/task/layouts';
import DynamicForm from '../../../../components/dynamicForm/DynamicForm';
import formatDateAndTime from '../../../../utils/formatDateAndTime';
import fields from '../../../../objectManager/task/fields';
import useEffectformValues from '../../../../utils/formValues';

const RecordForm = ({ record, isEditing, onClose, fieldsDisabled, setFieldsDisabled, recordType, handleDelete }) => {
  const { getUserId } = useContext(AuthContext);
  const [formValues, setFormValues] = useState({});

  const defaultValues = useEffectformValues(isEditing, record, fields, getUserId());

  useEffect(() => {
    setFormValues({
      ...defaultValues,
      recordType: isEditing ? record?.recordType : recordType,
      lastModifiedBy: isEditing ? record?.lastModifiedBy?._id : null,
      createdAt: isEditing ? formatDateAndTime(record?.createdAt) : '',
      updatedAt: isEditing ? formatDateAndTime(record?.updatedAt) : ''
    });
  }, [record, isEditing]);

  return (
    <DynamicForm
      objectType='task'
      listViewQueryKey='tasks'
      onClose={onClose}
      isEditing={isEditing}
      record={record}
      sections={getSectionsByRecordType(formValues?.recordType)}
      formValues={formValues}
      setFormValues={setFormValues}
      fieldsDisabled={fieldsDisabled}
      setFieldsDisabled={setFieldsDisabled}
      handleDelete={handleDelete}
    />
  );
};

export default RecordForm;
