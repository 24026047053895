// Function to used to get all the fields on the details form from the sections
const combineFields = (sections) => {
  const combinedFields = [];

  // Loop through each section
  sections.forEach((section) => {
    // Check if leftFields exist and push them to combinedFields
    if (section.leftFields) {
      combinedFields.push(...section.leftFields);
    }
    // Check if rightFields exist and push them to combinedFields
    if (section.rightFields) {
      combinedFields.push(...section.rightFields);
    }
    // Check if fullWidthFields exist and push them to combinedFields
    if (section.fullWidthFields) {
      combinedFields.push(...section.fullWidthFields);
    }
  });

  return combinedFields;
};

export default combineFields;