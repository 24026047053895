const capitalizeFirstLetter = (str) => {
    // Trim the string to remove leading and trailing spaces
    str = str.trim();

    // Remove leading and trailing double quotes
    if (str.startsWith('"') && str.endsWith('"')) {
        str = str.slice(1, -1);
    }

    // Check if the string is not empty
    if (str.length > 0) {
        // Capitalize the first letter and concatenate it with the rest of the string
        return str.charAt(0).toUpperCase();
    } else {
        return str; // Return empty string if the input was empty
    }
}

export default capitalizeFirstLetter;