import { useState } from 'react';
import styles from './css/Accordion.module.css';
import { Link, useLocation } from 'react-router-dom';
import IconContainer from '../../components/iconContainer/IconContainer';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';

const Accordion = ({ icon, title, options, isMenuOpen }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const location = useLocation();

  // Check if the current path matches any path in options
  const isDashboardLink = options.some(option => option.path === location.pathname);

  const toggleAccordion = () => {
    setIsExpanded((prev) => !prev);
  };

  const activeOption = {
    color: 'var(--blue-color)'
  };

  const activeDashboardStyles = {
    color: 'var(--background-color)',
    fontWeight: 600
  }

  return (
    <li>
      <div className={`${styles['accordion']} ${isDashboardLink ? styles['active'] : ''}`} onClick={toggleAccordion}>
        <div className={styles['accordion-header']}>
          <IconContainer isActive={isDashboardLink} icon={icon} />
          {isMenuOpen && <p className={styles['text']} style={isDashboardLink ? activeDashboardStyles : {}}>{title}</p>}
        </div>
        <KeyboardArrowRightOutlinedIcon
          className={`${styles['accordion-arrow']} ${isExpanded ? styles['rotated'] : ''}`}
        />
      </div>

      <ul
        className={`${styles['accordion-content']} ${isExpanded ? styles['expanded'] : ''}`}
      >
        {options.map((option, index) => (
          <li key={index}>
            <Link to={option.path} className={styles['link']} style={option.path === location.pathname ? activeOption : {}}>
              {isMenuOpen && <p className={styles['text']}>{option.name}</p>}
            </Link>
          </li>
        ))}
      </ul>
    </li>
  );
};

export default Accordion;