import { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import Field from '../../field/Field';
import firstCharacterToUpperCase from '../../../utils/firstCharacterToUpperCase';
import exportToExcel from '../../../utils/exportToExcel';
import { toast } from 'react-toastify'; // ToastContainer in PageLayout component
import getFieldsByObjectType from '../../../utils/getFieldsByObjectType';

// Remove system fields and change the field names into their corresponding labels
const transformData = (dataArray, fieldsArray) => {
  const fieldsMap = fieldsArray.reduce((map, field) => {
    map[field.name] = field.label;
    return map;
  }, {});

  return dataArray.map((data) => {
    let newData = {};
    for (let key in data) {
      if (
        data.hasOwnProperty(key) &&
        key !== '_id' &&
        key !== 'tenantId' &&
        key !== '__v'
      ) {
        if (fieldsMap[key]) {
          newData[fieldsMap[key]] = data[key];
        }
      }
    }
    return newData;
  });
};

const ExportRecords = ({
  open,
  onClose,
  objectTypePlural,
  data,
  selectedRecords,
}) => {
  const [exportRecordsOption, setExportRecordsOption] = useState('');

  const handleCancel = () => {
    onClose(); // Close the modal
  };

  const handleExportRecords = () => {
    if (exportRecordsOption === 'Export All Records') {
      try {
        const fields = getFieldsByObjectType(objectTypePlural);
        const transformedData = transformData(data, fields);

        exportToExcel(transformedData, objectTypePlural);
        toast.success('Records Exported Successfully');
        handleCancel();
      } catch (error) {
        console.error('Error', error);
        toast.success('Error Exporting Records');
        handleCancel();
      }
    }

    if (
      exportRecordsOption === 'Export Selected' &&
      selectedRecords?.length === 0
    ) {
      toast.warning('No Selected Records on the list view!');
      handleCancel();
    }

    if (
      exportRecordsOption === 'Export Selected' &&
      selectedRecords?.length > 0
    ) {
      try {
        const fields = getFieldsByObjectType(objectTypePlural);
        const transformedSelectedRecords = transformData(data, fields);
  
        exportToExcel(transformedSelectedRecords, objectTypePlural);
        toast.success('Selected Records Exported Successfully');
        handleCancel();
      } catch (error) {
        console.error('Error', error);
        toast.success('Error Exporting Records');
        handleCancel();
      }
    }
  };

  return (
    <Dialog fullWidth open={open}>
      <DialogTitle textAlign="center">{`Export ${firstCharacterToUpperCase(
        objectTypePlural
      )}`}</DialogTitle>
      <DialogContent style={{ display: 'flex', justifyContent: 'center' }}>
        <Field
          id="exportRecordsRadio"
          label="Choose which records to export"
          name="recordType"
          type="radio"
          options={['Export All Records', 'Export Selected']}
          onChange={(e) => setExportRecordsOption(e.target.value)}
          value={exportRecordsOption}
        />
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button
          size="small"
          style={{ color: 'var(--primary-color)' }}
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          size="small"
          disabled={!exportRecordsOption}
          onClick={handleExportRecords}
          variant="contained"
          style={{ background: 'var(--primary-color)' }}
        >
          Export
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportRecords;
