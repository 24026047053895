import styles from './css/Attachments.module.css';
import { useQuery } from 'react-query';
import { getRecords } from '../../api/crud';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';

export const Attachments = ({ objectType, relatedRecordId }) => {
  const filter = {
    filterLogic: null,
    filters: [
      {
        field: objectType, // e.g lead
        operator: 'equals',
        value: relatedRecordId,
      },
    ],
  };

  const { data: records, isLoading } = useQuery({
    queryFn: () => getRecords('attachments', filter),
    queryKey: ['attachments'],
  });

  if (isLoading) {
    return (
      <div className={styles['loading-container']}>
        <CircularProgress />
      </div>
    );
  }

  if (!records || records.length === 0) {
    return <p className={styles['no-attachments']}>No attachments available</p>;
  }

  const getBase64 = (buffer) => {
    if (!buffer || !buffer.data) return ''; // Validate the buffer

    // Create a binary string from the Uint8Array
    const binaryString = new Uint8Array(buffer.data).reduce((data, byte) => {
      return data + String.fromCharCode(byte);
    }, '');

    // Use btoa to convert the binary string to base64
    return window.btoa(binaryString);
  };

  return (
    <div className={styles['attachments-container']}>
      {records.map((attachment) => (
        <div className={styles['attachment-card']} key={attachment._id}>
          <div className={styles['attachment-header']}>
            <h3>{attachment.filename}</h3>
            <IconButton
              href={`data:${attachment.contentType};base64,${getBase64(attachment.content)}`}
              download={attachment.filename}
              className={styles['download-button']}
              aria-label="Download"
            >
              <DownloadOutlinedIcon />
            </IconButton>
          </div>
          <div className={styles['attachment-details']}>
            <p><strong>Size:</strong> {(attachment.size / 1024).toFixed(2)} KB</p>
          </div>
        </div>
      ))}
    </div>
  );
};
