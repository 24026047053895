import styles from './css/Sidebar.module.css';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { getGlobalItems, getObjects, getSettings } from '../../utils/sidebarTabs';
import { AuthContext } from '../../contexts/AuthContext';
import Logo from '../../assets/Main Logo.png';
import Icon from '../../assets/Icon.png';
import MenuItems from './MenuItems';
import IconButton from '@mui/material/IconButton';
import DoorBackOutlinedIcon from '@mui/icons-material/DoorBackOutlined';

const Sidebar = ({ isMenuOpen }) => {
  const { logout } = useContext(AuthContext);

  const handleLogoutClick = () => {
    logout();
  };

  const collapsedSidebarStyles = {
    width: 'var(--collapsed-sidebar-width)',
  };

  const collapsedMenuStyles = {
    overflow: 'hidden'
  };

  const logoutIconStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }

  return (
    <div className={styles['sidebar']} style={!isMenuOpen ? collapsedSidebarStyles : {}}>
      <div className={styles['logo-container']}>
        <Link to="/">
          <img
            alt="company logo"
            src={isMenuOpen ? Logo : Icon}
            className={!isMenuOpen ? styles['collapsed'] : ''}
            style={{ width: isMenuOpen ? '150px' : '' }} // Keep responsive for icon
          />
        </Link>
      </div>
      <div className={styles['menu-items']} style={!isMenuOpen ? collapsedMenuStyles : {}}>
        {isMenuOpen && (
          <small className={styles['sidebar-header']}>Global</small>
        )}
        <MenuItems items={getGlobalItems} isMenuOpen={isMenuOpen} />
        <hr />
        {isMenuOpen && <small className={styles['sidebar-header']}>Main</small>}
        <MenuItems items={getObjects} isMenuOpen={isMenuOpen} />
        <hr />
        {isMenuOpen && (
          <small className={styles['sidebar-header']}>Settings</small>
        )}
        <MenuItems items={getSettings} isMenuOpen={isMenuOpen} />
      </div>
      <div className={styles['logout']} style={!isMenuOpen ? logoutIconStyles : {}}>
        {/* <span className={styles['logout-text']}>Logout</span> */}
        <IconButton aria-label="menu" onClick={handleLogoutClick}>
          <DoorBackOutlinedIcon fontSize="large" color="action" />
        </IconButton>
      </div>
    </div>
  );
};

export default Sidebar;
