const sortObjectsByDateDescending = (objectsArray, fieldToSortBy) => {
    return objectsArray?.filter(obj => obj[fieldToSortBy]).sort(function(a, b) {
        return new Date(b[fieldToSortBy]) - new Date(a[fieldToSortBy]);
    });
}

const sortObjectsByDateAscending = (objectsArray, fieldToSortBy) => {
    // Remove objects without the specified field
    return objectsArray?.filter(obj => obj[fieldToSortBy]).sort(function(a, b) {
        return new Date(a[fieldToSortBy]) - new Date(b[fieldToSortBy]);
    });
}

export {sortObjectsByDateDescending, sortObjectsByDateAscending}