import { useContext, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useParams, useLocation } from 'react-router-dom';
import { AuthContext } from '../../../contexts/AuthContext';
import { getRecords, updateRecord } from '../../../api/crud';
import details from '../../../objectManager/case/objectDetails';
import Header from '../../../layouts/header/Header';
import RecordForm from './recordForm/RecordForm';
import ListViewTable from '../../../components/listViewTable/ListViewTable';
import KanbanBoard from '../../../components/kanbanBoard/KanbanBoard';
import DetailsPage from '../../../components/detailsPage/DetailsPage';
import SEO from '../../../components/seo/SEO';
import { toast } from 'react-toastify'; // ToastContainer in PageLayout component
import CustomLink from '../../../components/customLink/CustomLink';

const Cases = ({ showDetailsPage }) => {
  const location = useLocation();
  const { getUserId } = useContext(AuthContext);
  const { listId } = useParams();
  const { data: records, refetch, isLoading} = useQuery({
    queryFn: () => getRecords(details.apiNamePlural, listId ? listViews.find(list => list._id === listId) : null),
    queryKey: [details.apiNamePlural, listId]
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: 'subject', // Accessor for searching
        id: 'subject',
        header: 'Subject',
        Cell: ({ row }) => (
          <CustomLink to={`/case/${row.original._id}`} text={row.original.subject} />
        ),
      },
      {
        accessorKey: 'description',
        header: 'Description'
      },
      {
        accessorKey: 'status',
        header: 'Status'
      },
      {
        accessorKey: 'priority',
        header: 'Priority'
      },
      {
        accessorFn: (row) => row.assignedTo ? `${row.assignedTo.firstName} ${row.assignedTo.lastName}` : '', //accessorFn used to join multiple data into a single cell
        id: 'assignedTo', //id is still required when using accessorFn instead of accessorKey
        header: 'Assigned To'
      }
    ],
  []
  );

  const importColumns = useMemo(
    () => [
      {
        accessorKey: 'subject', 
        header: 'Subject'
      },
      {
        accessorKey: 'description',
        header: 'Description'
      },
      {
        accessorKey: 'status',
        header: 'Status'
      },
      {
        accessorKey: 'priority',
        header: 'Priority'
      }
    ],
  []
  );

  const listViews = [
    {
      _id: '7191fea6-197d-4e36-be32-fe8fcafa3cdc',
      label: 'All Cases',
      filterLogic: null,
      filters: []
    },
    {
      _id: 'b4811cb7-8cd2-4077-8185-9c40b26b21ee',
      label: 'My Cases',
      filterLogic: null,
      filters: [
        {
          'field': 'assignedTo',
          'operator': 'equals',
          'value': getUserId()
        }
      ]
    },
    {
      _id: '2d904dc8-668b-4ed7-b9af-2fc2c0138ff5',
      label: 'High Priority Cases',
      filterLogic: null,
      filters: [
        {
          'field': 'priority',
          'operator': 'equals',
          'value': 'High'
        }
      ]
    }
  ];

  const statusValues =  [
    {label: 'New', value: 'New'},
    {label: 'Escalated', value: 'Escalated'},
    {label: 'On hold', value: 'On hold'}
  ];

  const updateCaseStatus = (caseId, destinationColumn) => {
    const updatePromise = updateRecord(details.apiName, caseId, { status: destinationColumn });
  
    toast.promise(
      updatePromise,
      {
        pending: `Updating ${details.label} status...`,
        success: 'Successfully updated record!',
        error: 'Error updating record',
      }
    );
  
    return updatePromise; // Return the promise to maintain consistency
  };

  return (
    <>
      <SEO
        title={`${details.pluralLabel} | Salesdam`}
        description={details.description}
      />
      <Header
        objectType={details.apiName}
        infoText={details.description}
        type='listview'
        listViews={listViews}
      />
      {showDetailsPage && (
        <DetailsPage
          objectType={details.apiName}
          listViewLink={`/${details.apiNamePlural}`}
          listViewQueryKey={details.apiNamePlural}
          RecordForm={<RecordForm />}
        />
      )}
      <div className="list-view">
      {location.pathname === `/${details.apiNamePlural}/kanban` ? (
          <KanbanBoard
            items={records}
            field="status"
            values={statusValues}
            onDragEnd={(result) => {
              updateCaseStatus(result.draggableId, result.destination.droppableId);
            }}
            objectType={details.apiName}
            objectTypePlural={details.apiNamePlural}
            navigateTo={`/${details.apiNamePlural}`}
          />
          ) : (
          <ListViewTable
            enableRowActions={true}
            enableRowSelection={true}
            isLoading={isLoading}
            objectType={details.apiName}
            objectTypePlural={details.apiNamePlural}
            refetch={refetch}
            listViewQueryKey={details.apiNamePlural}
            columns={columns}
            importColumns={importColumns}
            data={records}
            RecordForm={<RecordForm />}
          />
        )}
      </div>
    </>
  );
};

export default Cases;