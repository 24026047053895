import styles from './css/IconContainer.module.css';

const IconContainer = ({ icon, isActive }) => {
    return (
        <div className={isActive ? styles['active-icon'] : styles['icon-container']}>
            {icon}
        </div>
    );
};

export default IconContainer;