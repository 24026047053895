const XLSX = require('xlsx');

const exportToExcel = (records, filename) => {
  try {
    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Convert records to a worksheet
    const ws = XLSX.utils.json_to_sheet(records);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, filename);

    // Write the workbook to a file
    const outputPath = `${filename}.xlsx`;
    const writeResult = XLSX.writeFile(wb, outputPath);

    // Check if writing was successful
    if (writeResult === undefined) {
      throw new Error(`Failed to write Excel file at path: ${outputPath}`);
    }
  } catch (error) {
    throw new Error(`Error exporting records to Excel: ${error.message}`);
  }
};

export default exportToExcel;
