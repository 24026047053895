import fields from './fields';
import getFieldsByName from '../../utils/getFieldsByName';

// Define sections
const sectionsByRecordType = {
    'Default': [
        {
            label: 'Order Information',
            leftFields: getFieldsByName(fields, ['name', 'orderNumber', 'orderDate', 'projectCode', 'endUser' ]),
            rightFields: getFieldsByName(fields, ['client', 'productName','projectManager', 'commission'])
        },
        {
            label: 'Purchase',
            leftFields: getFieldsByName(fields, ['paymentTerms', 'paymentDate', 'invoiceNumber', 'invoiceDate']),
            rightFields: getFieldsByName(fields, ['deliveryNoteNumber', 'deliveryDate', 'budgetPurchaseAmount', 'actualPurchaseAmount', 'orderValue'])
        },
    ]
};

const getSectionsByRecordType = (recordType)  => {
    return sectionsByRecordType[recordType] || [];
}

export default getSectionsByRecordType;