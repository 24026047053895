const fields = [
    {
        label: 'Record Type',
        name: 'recordType',
        id: 'leadRecordType',
        type: 'picklist',
        options: ['Default'],
        default: ''
    },
    {
        label: 'Username',
        name: 'username',
        id: 'username',
        type: 'text',
        default: '',
        required: true
    },
    {
        label: 'First Name',
        name: 'firstName',
        id: 'userFirstName',
        type: 'text',
        default: '',
        required: true
    },
    {
        label: 'Last Name',
        name: 'lastName',
        id: 'userLastName',
        type: 'text',
        default: '',
        required: true
    },
    {
        label: 'Email',
        name: 'email',
        id: 'userEmail',
        type: 'email',
        default: '',
        required: true
    },
    {
        label: 'Phone',
        name: 'phone',
        id: 'userPhone',
        type: 'text',
        default: '',
        required: false
    },
    {
        label: 'Gender',
        name: 'gender',
        id: 'userGender',
        type: 'picklist',
        options: ['Male', 'Female'],
        default: '',
        required: false
    },
    {
        label: 'Description',
        name: 'description',
        id: 'userDescription',
        type: 'textarea',
        default: '',
        required: false
    },
    {
        label: 'Is Active',
        name: 'isActive',
        id: 'userIsActive',
        type: 'checkbox',
        default: true,
    },
    {
        label: 'Title',
        name: 'title',
        id: 'userTitle',
        type: 'text',
        default: '',
        required: false
    },
    {
        label: 'Role',
        name: 'role',
        id: 'userRole',
        type: 'lookup',
        lookupTo: 'roles-and-permissions',
        // options: ['Agent', 'Admin'],
        // default: 'Agent',
        required: true
    },
];

export default fields;