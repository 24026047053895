// Importing all fields from different objects
import userFields from '../objectManager/user/fields';
import accountFields from '../objectManager/account/fields';
import contactFields from '../objectManager/contact/fields';
import orderFields from '../objectManager/order/fields';
import dealFields from '../objectManager/deal/fields';
import leadFields from '../objectManager/lead/fields';
import tenantPlanFields from '../objectManager/tenantPlan/fields';
import tenantFields from '../objectManager/tenant/fields';
import caseFields from '../objectManager/case/fields';
import taskFields from '../objectManager/task/fields';

// Mapping object types to their corresponding fields
const fieldsMap = {
    'user': userFields,
    'users': userFields,
    'account': accountFields,
    'accounts': accountFields,
    'contact': contactFields,
    'contacts': contactFields,
    'order': orderFields,
    'orders': orderFields,
    'deal': dealFields,
    'deals': dealFields,
    'lead': leadFields,
    'leads': leadFields,
    'tenant-plan': tenantPlanFields,
    'tenant-plans': tenantPlanFields,
    'tenant': tenantFields,
    'tenants': tenantFields,
    'case': caseFields,
    'cases': caseFields,
    'task': taskFields,
    'tasks': taskFields
};

// Function to get fields by object type
const getFieldsByObjectType = (objectType) => {
    // Return fields if objectType exists in the map, otherwise return an empty array
    return fieldsMap[objectType] || [];
};

export default getFieldsByObjectType;