// Function to get today's date in 'YYYY-MM-DD' format
const today = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const currentDateTime = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = new Intl.DateTimeFormat('en', { month: 'long' }).format(
    currentDate
  );
  // const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, '0');
  const hours = String(currentDate.getHours()).padStart(2, '0');
  const minutes = String(currentDate.getMinutes()).padStart(2, '0');
  // const seconds = String(currentDate.getSeconds()).padStart(2, "0");

  const formatter = new Intl.DateTimeFormat('en', {
    year: 'numeric',
    // month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false, // Ensure 24-hour format
  });

  // Set default date and time if necessary
  if (year === 0 && month === '00' && day === '00') {
    return formatter.format(currentDate);
  } else {
    return `${month} ${day}, ${year} ${hours}:${minutes}`;
  }
};

const getCurrentDateTime = () => {
  var currentDate = new Date();

  var year = currentDate.getFullYear();
  var month = String(currentDate.getMonth() + 1).padStart(2, '0');
  var day = String(currentDate.getDate()).padStart(2, '0');
  var hours = String(currentDate.getHours()).padStart(2, '0');
  var minutes = String(currentDate.getMinutes()).padStart(2, '0');

  var currentDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;
  return currentDateTime;
};

// Function to get the day of the month (1-31)
const getDay = () => {
  return new Date().getDate();
};

const calculateTimeDifference = (firstDate, secondDate) => {
  // Convert string dates to Date objects
  const firstDateObj = new Date(firstDate);
  const secondDateTimeObj = new Date(secondDate);

  // Calculate the time difference in milliseconds
  const timeDifferenceMs = firstDateObj - secondDateTimeObj;

  // Constants for conversion
  const msInAMinute = 1000 * 60;
  const msInAnHour = msInAMinute * 60;
  const msInADay = msInAnHour * 24;

  // Calculate the number of full days
  const differenceInDays = Math.floor(timeDifferenceMs / msInADay);

  // Get the remaining time after accounting for full days
  const remainingTimeAfterDays = timeDifferenceMs % msInADay;

  // Calculate the number of full hours from the remaining time
  const differenceInHours = Math.floor(remainingTimeAfterDays / msInAnHour);

  // Get the remaining time after accounting for full hours
  const remainingTimeAfterHours = remainingTimeAfterDays % msInAnHour;

  // Calculate the number of full minutes from the remaining time
  const differenceInMinutes = Math.floor(remainingTimeAfterHours / msInAMinute);

  // Return an object with days, hours, and minutes
  return {
    days: differenceInDays,
    hours: differenceInHours,
    minutes: differenceInMinutes,
  };
};

// Function to get the month (1-12)
const getMonth = () => {
  return new Date().getMonth() + 1;
};

// Function to get the year
const getYear = () => {
  return new Date().getFullYear();
};

const beginningOfDay = () => {
  return new Date(new Date().setUTCHours(0, 0, 0, 0)).toISOString();
};
const endingOfDay = () => {
  return new Date(new Date().setUTCHours(23, 59, 59, 999)).toISOString();
};

/**
 * Converts a date in 'YYYY-MM-DD' format to a more readable format, e.g., '9 May, 2024'.
 * @param {string} dateStr - The date in 'YYYY-MM-DD' format.
 * @param {string} [locale='en-GB'] - The locale for formatting (default is 'en-GB').
 * @returns {string} - The formatted date.
 */
const formatDate = (dateStr, locale = 'en-GB') => {
  // Parse the date string into a Date object
  const date = new Date(dateStr);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    return dateStr;
  }

  // Use Intl.DateTimeFormat to format the date into a readable format
  const formatter = new Intl.DateTimeFormat(locale, {
    day: 'numeric', // Include day of the month
    month: 'long', // Use the full name of the month
    year: 'numeric', // Include the year
  });

  return formatter.format(date);
};

// Function to subtract months from a given date
// monthsToSubtract: number of months to subtract
const subtractMonthsFromDate = (dateString, monthsToSubtract) => {
  const date = new Date(dateString); // Convert string to Date object
  const month = date.getMonth(); // Get the current month

  // Set the new month, handling potential year rollover
  date.setMonth(month - monthsToSubtract);

  // Return the date in 'YYYY-MM-DD' format
  return date.toISOString().split('T')[0];
};

// Function to add months to a given date
// days: number of days to add
const addDaysToDate = (dateString, days) => {
  if (!dateString) {
    return 'Invoice Date Required';
  }

  const date = new Date(dateString);

  // Convert days to a number if it's a string that can be converted, otherwise use zero
  const daysToAdd = isNaN(Number(days)) ? 0 : Number(days);

  date.setDate(date.getDate() + daysToAdd);

  // Return the date in 'YYYY-MM-DD' format
  return date.toISOString().split('T')[0];
};

const getLastDateOfMonth = (inputDate) => {
  if (!inputDate) return null; // Handle case where inputDate is not provided

  // Split the input date into year, month, and day
  const [year, month] = inputDate.split('-').map(Number);

  // Create a new Date object for the next month
  const nextMonth = new Date(year, month, 1);

  // Subtract one day from the next month to get the last day of the current month
  const lastDayOfMonth = new Date(nextMonth - 1);

  // Extract the year and month from the last day of the month
  const lastYear = lastDayOfMonth.getFullYear();
  const lastMonth = lastDayOfMonth.getMonth() + 1; // Months are zero-indexed, so add 1
  const lastDay = lastDayOfMonth.getDate();

  // Format the last day of the month as "YYYY-MM-DD"
  const formattedDate = `${lastYear}-${String(lastMonth).padStart(
    2,
    '0'
  )}-${String(lastDay).padStart(2, '0')}`;

  return formattedDate;
};

const formatDateTime = (datetimeString, shouldShowTimeIfToday) => {
  const date = new Date(datetimeString);
  const currentDate = new Date();

  // Check if it's the current day
  const isToday =
    date.getDate() === currentDate.getDate() &&
    date.getMonth() === currentDate.getMonth() &&
    date.getFullYear() === currentDate.getFullYear();

  // Format the date and time
  let formattedDateTime = '';
  if (isToday && shouldShowTimeIfToday) {
    formattedDateTime = `${formatTime(date)}`;
  } else {
    formattedDateTime = `${date.getDate()} ${getMonthName(
      date.getMonth()
    )} ${date.getFullYear()} ${formatTime(date)}`;
  }

  return formattedDateTime;
}

const formatTime = (date) => {
  let hours = date.getHours();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${hours}:${minutes} ${ampm}`;
}

const getMonthName = (month) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return months[month];
}
// Export all utility functions in one line
export {
  today,
  currentDateTime,
  getDay,
  getMonth,
  getYear,
  beginningOfDay,
  endingOfDay,
  formatDate,
  subtractMonthsFromDate,
  getCurrentDateTime,
  calculateTimeDifference,
  addDaysToDate,
  getLastDateOfMonth,
  formatDateTime
};
