import { createContext } from 'react';
import { useCookies } from 'react-cookie';
import { isLocalhost } from '../utils/isLocalhost';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [cookies, setCookie, removeCookie] = useCookies([
    'userId',
    'access_token',
    'tenantId',
    'refresh_token',
    'subdomain',
  ]);

  const cookieOptions = {
    path: '/',
    domain: isLocalhost() ? '.localhost.com' : `.${process.env.REACT_APP_DOMAIN}`,
  };

  const login = ({ user, accessToken, refreshToken, subDomain }) => {
    setCookie('userId', user?._id, cookieOptions);
    setCookie('access_token', accessToken, cookieOptions);
    setCookie('refresh_token', refreshToken, cookieOptions);
    setCookie('tenantId', user?.tenantId, cookieOptions);
    setCookie('subdomain', subDomain, cookieOptions);
  };

  const getUserId = () => {
    return cookies.userId;
  };

  const getTenantId = () => {
    return cookies.tenantId;
  };

  const isAuthenticated = () => {
    const userId = cookies.userId;
    const accessToken = cookies.access_token;
    const refreshToken = cookies.refresh_token;

    // Check if all required cookies exist
    return userId && accessToken && refreshToken;
  };

  const logout = () => {
    removeCookie('access_token', cookieOptions);
    removeCookie('refresh_token', cookieOptions);
    removeCookie('userId', cookieOptions);
    removeCookie('tenantId', cookieOptions);
    removeCookie('subdomain', cookieOptions);
  };

  return (
    <AuthContext.Provider value={{ getUserId, getTenantId, isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
