import api from './api';

const handleRequest = async (url, data) => {
    try {
        await api.post(url, data);
      } catch (error) {
        console.error(`Error resetting user's password`, error);
        throw error;
    }
}

const sendPasswordResetEmail = async (userId, email) => {
    await handleRequest('/api/auth/reset-password', {
        userId: userId,
        email: email
    });
};

const forgotPassword = async (userId, username) => {
    await handleRequest('/api/auth/forgot-password', {
        userId: userId,
        username: username
    });
};

export {
    sendPasswordResetEmail,
    forgotPassword
};