import './css/DetailsPage.css';
import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { getRecord, deleteRecord, updateRecord } from '../../api/crud';
import DetailsPageModal from '../modals/detailsPage/DetailsPageModal';
import Stages from '../stages/Stages';
import { toast } from 'react-toastify'; // ToastContainer in PageLayout component
import getModalTitle from '../../utils/getModalTitle';
import getCurrentStage from '../../utils/getCurrentStage';
import { getCurrentDateTime } from '../../utils/dateUtils';
import NewRecord from '../modals/newRecord/NewRecord';
import { default as UsersRecordForm } from '../../views/listviews/users/recordForm/RecordForm';

const DetailsPage = ({ RecordForm, objectType, listViewLink, refetch, stages, showStages }) => {
    const queryClient = useQueryClient();
    const [fieldsDisabled, setFieldsDisabled] = useState(true);
    const [isRecordDeleted, setIsRecordDeleted] = useState(false);
    const navigate = useNavigate();
    const { recordId } = useParams();
    const { data: record, isLoading } = useQuery({
        queryFn: () => getRecord(objectType, recordId),
        queryKey: [recordId],
        enabled: !isRecordDeleted
    });
    const [ newRecordModalState, setNewRecordModalState ] = useState({
        isEditing: false,
        openModal: false,
        modalHeading: ''
    });

    const tabs = [
        {
            title: 'Details'
        },
        // {
        //     title: 'Activities'
        // }
    ];

    const handleConvert = async () => {
        if (window.confirm(`Are you sure you want to convert this ${objectType}?`)){
            try {
              await updateRecord(objectType, record?._id, {isConverted: true, convertedDate: getCurrentDateTime()});
              toast.success('Successfully converted record!');
              refetch(); // refresh the list view query
              navigate(listViewLink);
            } catch (error) {
              console.error('Error converting record:', error);
              toast.error(`Error converting record: ${error.response.data.message}`);
            }
        }
    };

    const handleAddUser = () => {
        setNewRecordModalState({
            openModal: true,
            modalHeading: 'New User'
        });
    };

    const handleDelete = async () => {
        if (window.confirm(`Are you sure you want to delete this ${objectType}?`)){
            try {
              setIsRecordDeleted(true); // Set the flag to indicate that the record has been deleted
              await deleteRecord(objectType, record?._id);
              setIsRecordDeleted(false); // Set the flag to indicate that the record has been deleted
              toast.success('Successfully deleted record!');
              refetch(); // refresh the list view query
              navigate(listViewLink);
            } catch (error) {
              console.error('Error deleting record:', error);
              toast.error(`Error deleting record: ${error.response.data.message}`);
            }
        }
    };

    const handleCancelEdit = () => {
        setFieldsDisabled(!fieldsDisabled);
        queryClient.invalidateQueries([objectType]); // refresh the record being updated
    };

    return (
        <>
            {newRecordModalState.openModal && (
                <NewRecord
                    open={newRecordModalState.openModal}
                    RecordForm={
                        <UsersRecordForm
                            isCreatingUserFromTenant={true}
                            tenantId={recordId}
                        />
                    }
                    objectType='User'
                    onClose={() => setNewRecordModalState({
                        openModal: false
                    })}
                    heading={newRecordModalState.modalHeading}
                />
            )}
            {recordId && (
                <DetailsPageModal
                    isLoading={isLoading}
                    record={record}
                    objectType={objectType}
                    isOpen={true}
                    modalTitle={getModalTitle(record, objectType)}
                    fieldsDisabled={fieldsDisabled}
                    setFieldsDisabled={setFieldsDisabled}
                    tabs={tabs}
                    handleCancelEdit={handleCancelEdit}
                    handleConvert={handleConvert}
                    handleAddUser={handleAddUser}
                    handleDelete={handleDelete}
                    modalContent={
                        <>
                            <div className="stages-container">
                                {showStages && (
                                    <Stages
                                        stages={stages}
                                        isClosedSuccessful={record?.stage !== 'Closed Lost' && record?.status !== 'Unqualified'}
                                        currentStage={getCurrentStage(record, objectType)}
                                    />
                                )}
                            </div>
                            <div className="details-page">
                                {React.cloneElement( RecordForm, {
                                    isLoading: isLoading,
                                    record: record,
                                    isEditing: true,
                                    setFieldsDisabled: setFieldsDisabled,
                                    fieldsDisabled: fieldsDisabled,
                                    handleDelete: handleDelete
                                })}
                            </div>
                        </>
                    }
                    onClose={() => {
                            navigate(-1); // Navigates to the previous URL in the history stack
                        }
                    }
                />
            )}
        </>
    );
}

export default DetailsPage;