import { useContext, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useParams, useLocation } from 'react-router-dom';
import { AuthContext } from '../../../contexts/AuthContext';
import { getRecords, updateRecord } from '../../../api/crud';
import details from '../../../objectManager/deal/objectDetails';
import { detailsPageStages, kanbanStages } from '../../../objectManager/deal/stages';
import Header from '../../../layouts/header/Header';
import RecordForm from './recordForm/RecordForm';
import ListViewTable from '../../../components/listViewTable/ListViewTable';
import KanbanBoard from '../../../components/kanbanBoard/KanbanBoard';
import DetailsPage from '../../../components/detailsPage/DetailsPage';
import SEO from '../../../components/seo/SEO';
import { toast } from 'react-toastify'; // ToastContainer in PageLayout component
import formatToRand from '../../../utils/formatToRands';
import { currentDateTime } from '../../../utils/dateUtils';
import CustomLink from '../../../components/customLink/CustomLink';
import StageIndicator from '../../../components/stageIndicator/StageIndicator';
import formatDateAndTime from '../../../utils/formatDateAndTime';
import Countdown from '../../../components/countdown/Countdown';

const Deals = ({showDetailsPage}) => {
  const location = useLocation();
  const { getUserId } = useContext(AuthContext);
  const { listId } = useParams();
  const { data: records, refetch, isLoading} = useQuery({
    queryFn: () => getRecords(details.apiNamePlural, listId ? listViews.find(list => list._id === listId) : defaultFilter),
    queryKey: [details.apiNamePlural, listId]
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name', // Accessor for searching
        id: 'name',
        header: 'Name',
        Cell: ({ row }) => (
          <CustomLink to={`/deal/${row.original._id}`} text={`${row.original.technicalScope ? row.original.technicalScope : 'N/A'}`} />
        ),
      },
      {
        accessorKey: 'client',
        header: 'Client'
      },
      {
        accessorKey: 'projectCode',
        header: 'Project Code'
      },
      {
        accessorFn: (row) => <StageIndicator stage={row.stage} />,
        id: 'stage',
        header: 'Stage'
      },
      {
        accessorFn: (row) => formatToRand(row.orderValue || 0),
        id: 'orderValue',
        header: 'Order Value'
      },
      {
        accessorFn: (row) => row.closingDate ? formatDateAndTime(row.closingDate) : currentDateTime(), // Use currentDateTime if closingDate is empty
        id: 'closingDate',
        header: 'Closing Date',
        sortingFn: (rowA, rowB) => {
          const dateA = new Date(rowA.original.closingDate || currentDateTime);
          const dateB = new Date(rowB.original.closingDate || currentDateTime);
          return dateA - dateB;
        }
      },
      {
        accessorFn: (row) => <Countdown closingDate={row.closingDate}/>,
        id: 'countdown',
        header: 'Countdown',
      },
      {
        accessorFn: (row) => row.salesRep ? `${row.salesRep.firstName} ${row.salesRep.lastName}` : '', //accessorFn used to join multiple data into a single cell
        id: 'salesRep', //id is still required when using accessorFn instead of accessorKey
        header: 'Sales Rep'
      },
    ],
    []
  );

  const importColumns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Name'
      },
      {
        accessorFn: (row) => <StageIndicator stage={row.stage} />,
        id: 'stage',
        header: 'Stage'
      },
      {
        accessorFn: (row) => formatToRand(row.orderValue || 0),
        id: 'orderValue',
        header: 'Order Value'
      },
      {
        accessorFn: (row) => row.closingDate ? formatDateAndTime(row.closingDate) : currentDateTime(), // Use currentDateTime if closingDate is empty
        id: 'closingDate',
        header: 'Closing Date'
      },
    ],
    []
  );

  const defaultFilter = {
    filterLogic: null,
    filters: [
      {
        field: 'isConverted',
        operator: 'notEqual',
        value: true
      }
    ]
  };

  const listViews = [
    {
      _id: '6c5d82d0-faf9-4b44-a1ce-e8a449d78e44',
      label: 'All Deals',
      filterLogic: null,
      filters: [
        {
          field: 'isConverted',
          operator: 'notEqual',
          value: true
        }
      ]
    },
    {
      _id: '929cc345-e0fa-4853-b679-f1ed500d4116',
      label: 'My Deals',
      filterLogic: '1 AND 2',
      filters: [
        {
          field: 'owner',
          operator: 'equals',
          value: getUserId()
        },
        {
          field: 'isConverted',
          operator: 'notEqual',
          value: true
        }
      ]
    },
    {
      _id: '485df45a-8c98-4f01-9246-0772b1176eaa',
      label: 'Open Deals',
      filterLogic: '1 AND 2 AND 3',
      filters: [
        {
          field: 'stage',
          operator: 'notEqual',
          value: 'Closed Lost'
        },
        {
          field: 'stage',
          operator: 'notEqual',
          value: 'Closed Won'
        },
        {
          field: 'isConverted',
          operator: 'notEqual',
          value: true
        }
      ]
    },
    {
      _id: '2d61824c-b805-48b6-8206-4adb954be8da',
      label: 'Closed Won',
      filterLogic: '1 AND 2',
      filters: [
        {
          field: 'stage',
          operator: 'equals',
          value: 'Closed Won'
        },
        {
          field: 'isConverted',
          operator: 'notEqual',
          value: true
        }
      ]
    },
    {
      _id: 'fd64d58a-bc97-48ce-9065-a3cc031fc483',
      label: 'My Recently Updated Deals',
      filterLogic: '1 AND 2',
      filters: [
        {
          field: 'salesRep',
          operator: 'equals',
          value: getUserId()
        },
        {
          field: 'isConverted',
          operator: 'notEqual',
          value: true
        }
      ]
    }
  ];

  const updateDealStage = (dealId, destinationColumn) => {
    const updatePromise = updateRecord(details.apiName, dealId, { stage: destinationColumn });
  
    toast.promise(
      updatePromise,
      {
        pending: `Updating ${details.label} status...`,
        success: 'Successfully updated record!',
        error: 'Error updating record',
      }
    );
  
    return updatePromise; // Return the promise to maintain consistency
  };

  return (
    <>
      <SEO
        title={`${details.pluralLabel} | Salesdam`}
        description={details.description}
      />
      <Header
        objectType={details.apiName}
        infoText={details.description}
        type='listview'
        listViews={listViews}
      />
      {showDetailsPage && (
        <DetailsPage
          objectType={details.apiName}
          listViewLink={`/${details.apiNamePlural}`}
          refetch={refetch}
          showStages={true}
          stages={detailsPageStages}
          RecordForm={<RecordForm />}
        />
      )}
      <div className="list-view">
        {location.pathname === `/${details.apiNamePlural}/kanban` ? (
          <KanbanBoard
            items={records}
            field="stage"
            values={kanbanStages}
            onDragEnd={(result) => {
              updateDealStage(result.draggableId, result.destination.droppableId);
            }}
            objectType={details.apiName}
            objectTypePlural={details.apiNamePlural}
            navigateTo={`/${details.apiNamePlural}`}
          />
          ) : (
          <ListViewTable
            enableRowActions={true}
            enableRowSelection={true}
            isLoading={isLoading}
            objectType={details.apiName}
            objectTypePlural={details.apiNamePlural}
            refetch={refetch}
            listViewQueryKey={details.apiNamePlural}
            columns={columns}
            importColumns={importColumns}
            data={records}
            RecordForm={<RecordForm />}
          />
        )}
      </div>
    </>
  );
};

export default Deals;