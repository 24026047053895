import { useQuery } from 'react-query';
import { getRecords } from '../../../../api/crud'; 
import TotalRecordsRangeCalculator from '../../../../components/charts/rangeCalculator/TotalRecordsRangeCalculator';

const TotalConvertedLeads = () => {
    const chartFilter = {
        filterLogic: null,
        filters: [
            {
                field: 'name',
                operator: 'equals',
                value: 'total_converted_leads',
            },
        ],
    };

    const { data: records = [], isLoading: loading } = useQuery({
        queryFn: () => getRecords('standard-charts', chartFilter),
        queryKey: ['total-converted-leads'],
    });

    const chartRecord = records.length > 0 ? records[0] : {};
    const caption = chartRecord.caption || '';
    const from = chartRecord.from || '';
    const to = chartRecord.to || '';
    const field = chartRecord.targetField || '';
    const dateField = chartRecord.dateField || '';


    // Query to fetch won deals
    const { data: leads, isLoading } = useQuery({
        queryFn: () => getRecords('leads', convertedLeadsFilter),
        queryKey: ['converted-leads', from, to],
        enabled: !!from && !!to,  // Only run the query if from and to dates are set
    });

    const convertedLeadsFilter = {
        filterLogic: '1 AND 2 AND 3',
        filters: [
            {
                field: 'isConverted',
                operator: 'equals',
                value: true
            },
            {
                field: dateField,
                operator: 'greaterOrEqual',
                value: from,
            },
            {
                field: dateField,
                operator: 'lessOrEqual',
                value: to,
            }
        ],
    };

    //const total = calculateTotal(orders, field);
    const isLoadingTotal = loading || isLoading;

    return (
        <TotalRecordsRangeCalculator
            chartId={chartRecord?._id}
            caption={caption}
            records={leads}
            isLoading={isLoadingTotal}
            field={field} // Field used for the count
            value={true} // Value on the count field
            from={from}
            to={to}
            queryKey='total-converted-leads'
        />
    );
};

export default TotalConvertedLeads;