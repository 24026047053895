import { getCurrentDateTime, calculateTimeDifference } from '../../utils/dateUtils';
import daysAndHoursLeft from '../../utils/daysAndHoursLeft';

const Countdown = ({ closingDate }) => {
  const { days, hours, minutes } = calculateTimeDifference(
    closingDate,
    getCurrentDateTime()
  );

  let color;
  if (days <= 5) {
    color = 'red';
  } else if (days <= 10) {
    color = 'orange';
  } else {
    color = 'green';
  }

  return (
    <span style={{ color }}>{daysAndHoursLeft(days, hours, minutes)}</span>
  );
};

export default Countdown;
