const fields = [
    {
        label: 'Name',
        name: 'name',
        id: 'tenantName',
        type: 'text',
        default: '',
        required: true
    },
    {
        label: 'Type',
        name: 'recordType',
        id: 'tenantRecordType',
        type: 'picklist',
        options: ['Internal', 'Customer'],
        default: 'Customer',
        required: true
    },
    {
        label: 'Email',
        name: 'email',
        id: 'tenantEmail',
        type: 'text',
        default: '',
        required: true
    },
    {
        label: 'Is Active',
        name: 'isActive',
        id: 'tenantIsActive',
        type: 'checkbox',
        default: true
    },
    {
        label: 'Phone',
        name: 'phone',
        id: 'tenantPhone',
        type: 'text',
        default: '',
        required: false
    },
    {
        label: 'Website',
        name: 'website',
        id: 'tenantWebsite',
        type: 'text',
        default: '',
        required: false
    },
    {
        label: 'Description',
        name: 'description',
        id: 'tenantDescription',
        type: 'textarea',
        default: '',
        required: true
    },
    {
        label: 'Tenant Plan',
        name: 'plan',
        id: 'tenantPlan',
        type: 'lookup',
        lookupTo: 'tenant-plans',
        default: null,
        required: true
    },
    {
        label: 'Sub Domain',
        name: 'subDomain',
        id: 'tenantSubDomain',
        type: 'text',
        default: '',
    },
];

export default fields;