import React, { useState } from 'react';
import { read, utils } from 'xlsx';
import styles from './ConvertExcelToJSON.module.css';
import FieldMapping from './fieldMapping/FieldMapping';

const ConvertExcelToJSON = ({ onFileProcessed, fields }) => {
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [isMapping, setIsMapping] = useState(false);

  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = read(data, { type: 'array', cellDates: true });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = utils.sheet_to_json(worksheet, { header: 1, raw: false }); // Use raw: false to keep date strings
        setColumns(json[0]);
        setData(json.slice(1));
        setIsMapping(true);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  const handleMappingComplete = (mapping) => {
    const transformedData = data.map((row) => {
      const record = {};
      columns.forEach((column, index) => {
        const fieldName = mapping[column];
        if (fieldName) {
          let value = row[index];
          if (value instanceof Date) {
            value = value.toISOString().split('T')[0];
            // Convert Date to YYYY-MM-DD format
          }
          record[fieldName] = value;
        }
      });
      return record;
    });
    onFileProcessed(transformedData);
    setIsMapping(false);
  };

  return (
    <div>
      {!isMapping ? (
        <form className={styles.form_container}>
          <label htmlFor="upload">Upload From Excel File</label>
          <input
            className={styles.file_upload_input}
            type="file"
            name="upload"
            id="upload"
            onChange={readUploadFile}
          />
        </form>
      ) : (
        <FieldMapping
          columns={columns}
          fields={fields}
          onMappingComplete={handleMappingComplete}
        />
      )}
    </div>
  );
};

export default ConvertExcelToJSON;
