import { useQuery } from 'react-query';
import { getRecords } from '../../api/crud';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import ClipLoader from 'react-spinners/ClipLoader';

const DefaultTenderDetails = ({ tender }) => {
    return (
        <>
            <p>Tender Number: {tender?.tenderNumber}</p>
            <p>Briefing Date: {tender?.briefingDate}</p>
            <p>Closing Date: {tender?.closingDate}</p>
        </>
    );
}

const TenderDialog = ({ open, handleClose, tender }) => {
    const { data: scrapedTender, isLoading} = useQuery({
        queryFn: () => getRecords('tender-details', tenderUrl),
        queryKey: [tender?.url],
        enabled: !!tender?.url // Only enable the query when url is truthy
    });

    const tenderUrl = {
        url: tender?.url
    };

    const loaderStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '200px'
    };

     // Check if tender object is not empty
    const isScrapedTenderNotEmpty = Object.keys(scrapedTender || {}).length > 0;

    return (
        <Dialog open={open} onClose={handleClose} fullWidth={true} >
            {isLoading ? (
                <div style={loaderStyles}>
                    <ClipLoader
                        color='var(--primary-color)'
                        loading={isLoading}
                        size={100}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                </div>
            ) : (
                <>
                    <DialogTitle>
                        <a style={{color: 'var(--primary-text-color)'}} href={tender?.url} target='_blank'>{tender?.tenderTitle}</a>
                    </DialogTitle>
                    <DialogContent>
                        {isScrapedTenderNotEmpty ? (
                            <>
                                {Object?.entries(scrapedTender)?.map(([key, value]) => (
                                    <p style={{padding: '3px'}} key={key}>
                                        <strong>{key}:</strong> {value}
                                    </p>
                                ))}
                            </>
                        ) : (
                            <DefaultTenderDetails
                                tender={tender}
                            />
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button variant='contained' size='small' style={{backgroundColor: 'var(--primary-color)'}}>
                            Convert To Lead
                        </Button>
                        <Button onClick={handleClose} style={{color: 'var(--primary-color)'}}>
                            Close
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default TenderDialog;