import { useState } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LookupField from './LookupField';
import MultiSelectPicklist from './MultiSelectPicklist';
import { BeatLoader } from 'react-spinners';
import styles from './css/Field.module.css';

const Field = ({
  lookupTo,
  id,
  label,
  textAreaRows,
  maxLength,
  required,
  name,
  helpText,
  placeholder,
  type,
  options,
  value,
  disabled,
  onChange,
  errorMessage,
  customStyles,
  isLoading,
}) => {
  const showError = errorMessage && errorMessage.length > 0;
  const [showHelpText, setShowHelpText] = useState(false);

  const toggleHelpText = () => {
    setShowHelpText(!showHelpText);
  };

  const loadingColor = 'var(--primary-color)';

  return (
    <div className={styles['Custom-field-container']} style={customStyles}>
      <label htmlFor={id} className={styles['field-label']}>
        {label} {required && <span className={styles['required']}>*</span>}
        {helpText && (
          <span
            className={styles['info-icon']}
            onMouseEnter={toggleHelpText}
            onMouseLeave={toggleHelpText}
          >
            <InfoOutlinedIcon fontSize="small" />
          </span>
        )}
        {helpText && <span className={styles['help-text']}>{helpText}</span>}
      </label>
      {[
        'text',
        'password',
        'search',
        'email',
        'number',
        'date',
        'month',
        'time',
        'datetime',
      ].includes(type) && (
        <>
          <div className={styles['input-wrapper']}>
            <input
              disabled={disabled || isLoading}
              maxLength={maxLength}
              id={id}
              className={`${showError ? styles['error'] : ''} ${
                styles['input-field']
              }`}
              name={name}
              type={type === 'datetime' ? 'datetime-local' : type}
              placeholder={placeholder}
              value={value || ''}
              onChange={onChange}
            />
            {isLoading && (
              <BeatLoader
                size={8}
                color={loadingColor}
                className={styles['loader']}
              />
            )}
          </div>
          {showError && (
            <div className={styles['error-message']}>{errorMessage}</div>
          )}
        </>
      )}
      {type === 'picklist' && (
        <>
          <div className={styles['input-wrapper']}>
            <select
              disabled={disabled || isLoading}
              id={id}
              className={showError ? styles['error'] : ''}
              name={name}
              value={value}
              onChange={onChange}
            >
              {placeholder ? (
                <option value="" disabled>
                  {placeholder}
                </option>
              ) : (
                <option value="">--None--</option>
              )}
              {options?.map((option) => (
                <option key={option} value={option || ''}>
                  {option}
                </option>
              ))}
            </select>
            {isLoading && (
              <BeatLoader
                size={8}
                color={loadingColor}
                className={styles['loader']}
              />
            )}
          </div>
          {showError && (
            <div className={styles['error-message']}>{errorMessage}</div>
          )}
        </>
      )}
      {type === 'radio' && (
        <>
          {options?.map((option) => (
            <div key={option} className={styles['radio-option']}>
              <input
                type="radio"
                id={`${id}_${option}`}
                name={name}
                value={option}
                checked={value === option}
                onChange={onChange}
                disabled={disabled || isLoading}
              />
              <label htmlFor={`${id}_${option}`}>{option}</label>
            </div>
          ))}
          {showError && (
            <div className={styles['error-message']}>{errorMessage}</div>
          )}
        </>
      )}
      {type === 'textarea' && (
        <>
          <div className={styles['input-wrapper']}>
            <textarea
              disabled={disabled || isLoading}
              rows={textAreaRows ? textAreaRows : 2}
              maxLength={maxLength}
              id={id}
              className={`${showError ? styles['error'] : ''} ${
                styles['input-field']
              }`}
              name={name}
              value={value}
              onChange={onChange}
              style={{ resize: 'vertical' }}
            ></textarea>
            {isLoading && (
              <BeatLoader
                size={8}
                color={loadingColor}
                className={styles['loader']}
              />
            )}
          </div>
          {showError && (
            <div className={styles['error-message']}>{errorMessage}</div>
          )}
        </>
      )}
      {type === 'checkbox' && (
        <div className={styles['checkbox-field']}>
          <FormControlLabel
            id={id}
            control={
              <Checkbox
                disabled={disabled || isLoading}
                checked={value}
                onChange={onChange}
              />
            }
            name={name}
          />
          {isLoading && (
            <BeatLoader
              size={8}
              color={loadingColor}
              className={styles['loader']}
            />
          )}
          {showError && (
            <div className={styles['error-message']}>{errorMessage}</div>
          )}
        </div>
      )}
      {type === 'output' && (
        <>
          <input
            disabled
            id={id}
            name={name}
            type="text"
            placeholder={placeholder}
            value={value || ''}
            readOnly
          />
        </>
      )}
      {type === 'lookup' && (
        <LookupField
          disabled={disabled || isLoading}
          id={id}
          name={name}
          lookupTo={lookupTo}
          placeholder={placeholder}
          onChange={onChange}
          lookupRecordId={value}
        />
      )}
      {type === 'multi-select' && (
        <MultiSelectPicklist
          disabled={disabled || isLoading}
          id={id}
          label={label}
          name={name}
          value={value || []}
          options={options}
          onChange={onChange}
        />
      )}
    </div>
  );
};

export default Field;
