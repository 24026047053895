import { useQuery } from 'react-query';
import Card from '@mui/material/Card';
import Donut from '../../../../../components/charts/donut/Donut';
import { getSummary } from '../../../../../api/crud'; 
import convertObjectToArray from '../../../../../utils/convertObjectToArray';

const DealsSummary = () => {
    const { data: dealsSummary, isLoading } = useQuery({
        queryFn: () => getSummary('deal-summary', {}),
        queryKey: ['deal-summary'],
    });

    const dealsBreakdown = dealsSummary?.summaries?.stage?.breakdown;
    const data = convertObjectToArray(dealsBreakdown);

    return (
        <Card sx={{padding: '15px' }}>
            <Donut caption='Deals Summary' data={data} isLoading={isLoading} />
        </Card>
    );
};

export default DealsSummary;