import fields from './fields';
import getFieldsByName from '../../utils/getFieldsByName';

// Define sections
const sectionsByRecordType = {
    'Default': [
        {
            label: 'Contact Information',
            leftFields: getFieldsByName(fields, ['owner', 'salutation', 'firstName', 'lastName', 'department', 'title']),
            rightFields: getFieldsByName(fields, ['account', 'phone', 'email', 'emailOptOut', 'reportsTo', 'leadSource'])
        },
        {
            label: 'Address Information',
            leftFields: getFieldsByName(fields, ['mailingAddress.street', 'mailingAddress.city', 'mailingAddress.province', 'mailingAddress.zip', 'mailingAddress.country']),
            rightFields: getFieldsByName(fields, ['otherAddress.street', 'otherAddress.city', 'otherAddress.province', 'otherAddress.zip', 'otherAddress.country'])
        },
        {
            label: 'Description Information',
            fullWidthFields: getFieldsByName(fields, ['description'])
        },
        /*{
            label: 'Additional Information',
            leftFields: getFieldsByName(fields, ['fax', 'homePhone', 'otherPhone', 'assistant', 'assistantPhone']),
            rightFields: getFieldsByName(fields, ['leadSource', 'birthday', 'department'])
        },*/
    ]
};

const getSectionsByRecordType = (recordType)  => {
    return sectionsByRecordType[recordType] || [];
}

export default getSectionsByRecordType;