const formatDateAndTime = (dateTimeString) => {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const date = new Date(dateTimeString);
    const monthText = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const time = hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0');

    return `${monthText} ${day}, ${year} ${time}`;
}

export default formatDateAndTime;