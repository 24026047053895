import fields from './fields';
import getFieldsByName from '../../utils/getFieldsByName';

// Define sections
const sectionsByRecordType = {
    'Default': [
        {
            label: 'Task Information',
            leftFields: getFieldsByName(fields, ['assignedTo', 'subject', 'description', 'dueDate', 'currency']),
            rightFields: getFieldsByName(fields, ['relatedToModel'])
        },
        {
            label: 'Additional Information',
            leftFields: getFieldsByName(fields, ['status', 'priority'])
        }
    ]
};

const getSectionsByRecordType = (recordType)  => {
    return sectionsByRecordType[recordType] || [];
}

export default getSectionsByRecordType;