import { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import styles from './css/Profile.module.css';
import SEO from '../../components/seo/SEO';
import Header from '../../layouts/header/Header';
import Field from '../../components/field/Field';
import { Button } from '@mui/material';
import { useQuery, useQueryClient } from 'react-query';
import { getRecord, updateRecord } from '../../api/crud';
import { toast } from 'react-toastify';
import { BeatLoader } from 'react-spinners';
import FileBase64 from 'react-file-base64';
import Avatar from '@mui/material/Avatar';
import ChangePasswordModal from './ChangePasswordModal';
// import EmailSettings from './EmailSettings';

const CustomLoader = ({ loading }) => {
  return (
    <BeatLoader
      color="var(--primary-color)"
      loading={loading}
      size={10}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  );
};

const Profile = () => {
  const queryClient = useQueryClient();
  const { getUserId } = useContext(AuthContext);
  const userId = getUserId(); // Get the userId of the logged in user
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data: user, isLoading } = useQuery({
    queryFn: () => getRecord('user', userId),
    queryKey: ['logged-in-user'],
  });
  const [formData, setFormData] = useState({
    username: '',
    role: '',
    title: '',
    phone: '',
    firstName: '',
    gender: '',
    lastName: '',
    email: '',
    profilePicture: '',
  });
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);

  useEffect(() => {
    if (user) {
      setFormData({
        username: user.username || '',
        role: user.role || '',
        title: user.title || '',
        phone: user.phone || '',
        firstName: user.firstName || '',
        gender: user.gender || '',
        lastName: user.lastName || '',
        email: user.email || '',
        profilePicture: user.profilePicture || '',
      });
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleRemoveProfilePicture = () => {
    setFormData({ ...formData, profilePicture: '' });
  };

  const handleSubmit = async () => {
    setIsSubmitting(true); // Disable the button when clicked
    try {
      await updateRecord('user', userId, formData);
      setIsSubmitting(false);
      // Invalidate and refetch the query
      queryClient.invalidateQueries(['logged-in-user']);
      toast.success('Successfully updated record!');
    } catch (error) {
      setIsSubmitting(false);
      console.error('Error updating record:', error);
      toast.error(`Error updating record: ${error.response.data.message}`);
    }
  };

  const buttonStyles = {
    color: 'var(--primary-color)',
    border: 'var(--thin-border)',
    backgroundColor: 'var(--secondary-text-color)',
  };

  const removePPButtonStyles = {
    color: 'var(--primary-color)',
    border: 'var(--thin-border)',
    backgroundColor: 'var(--secondary-text-color)',
    marginTop: '10px'
  };

  const changePasswordButtonStyles = {
    backgroundColor: 'var(--primary-color)',
  };

  return (
    <>
      <SEO
        title="Profile Settings | Salesdam"
        description="Edit user profile settings"
      />
      <Header
        headerText="Profile Settings"
        infoText="Edit your profile settings"
      />
      {isLoading ? (
        <div className={styles['loading-spinner']}>
          <CustomLoader loading={isLoading} />
        </div>
      ) : (
        <>
          <div className={styles['buttons-container']}>
            <Button
              size="small"
              variant="outlined"
              style={buttonStyles}
              disabled={isSubmitting}
              onClick={handleSubmit}
            >
              Update Profile
            </Button>
            <Button
              size="small"
              variant="contained"
              style={changePasswordButtonStyles}
              onClick={() => setIsPasswordModalOpen(true)}
            >
              Change Password
            </Button>
          </div>
          <ChangePasswordModal
            open={isPasswordModalOpen}
            onClose={() => setIsPasswordModalOpen(false)}
            userId={userId}
          />
          <div className={styles['profile-container']}>
            <div className={styles['picture-container']}>
              <Avatar
                alt={`${formData.firstName} ${formData.lastName}`}
                src={formData.profilePicture}
                sx={{ width: 250, height: 250 }}
              />
              <>
                <label>Upload Profile Picture:</label>
                <FileBase64
                  className="form-field"
                  type="file"
                  multiple={false}
                  value={formData.picture}
                  name="profilePicture"
                  onDone={({ base64 }) =>
                    setFormData({ ...formData, profilePicture: base64 })
                  }
                />
                <Button
                  size="small"
                  variant="outlined"
                  style={removePPButtonStyles}
                  onClick={handleRemoveProfilePicture}
                >
                  Remove Profile Picture
                </Button>
              </>
            </div>
            <form className={styles['profile-form']} onSubmit={handleSubmit}>
              <Field
                type="text"
                id="username"
                name="username"
                label="Username"
                value={formData.username}
                onChange={handleChange}
                required={true}
              />
              <Field
                type="lookup"
                lookupTo="roles-and-permissions"
                id="role"
                name="role"
                label="Role"
                value={formData?.role?._id}
                onChange={handleChange}
                required={true}
              />
              <Field
                type="text"
                id="title"
                name="title"
                label="Title"
                value={formData.title}
                onChange={handleChange}
              />
              <Field
                type="text"
                id="phone"
                name="phone"
                label="Phone"
                value={formData.phone}
                onChange={handleChange}
                required={true}
              />
              <Field
                type="text"
                id="firstName"
                name="firstName"
                label="First Name"
                value={formData.firstName}
                onChange={handleChange}
                required={true}
              />
              <Field
                type="picklist"
                options={['Male', 'Female']}
                id="gender"
                name="gender"
                label="Gender"
                value={formData.gender}
                onChange={handleChange}
                required={true}
              />
              <Field
                type="text"
                id="lastName"
                name="lastName"
                label="Last Name"
                value={formData.lastName}
                onChange={handleChange}
                required={true}
              />
              <Field
                type="email"
                id="email"
                name="email"
                label="Email"
                value={formData.email}
                onChange={handleChange}
                required={true}
              />
            </form>
          </div>
          <hr className={styles['horizontal-line']} />
          {/* <EmailSettings user={user}/> */}
        </>
      )}
    </>
  );
};

export default Profile;
