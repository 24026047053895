import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getRecords } from '../../../api/crud';
import details from '../../../objectManager/tenant/objectDetails';
import Header from '../../../layouts/header/Header';
import RecordForm from './recordForm/RecordForm';
import ListViewTable from '../../../components/listViewTable/ListViewTable';
import DetailsPage from '../../../components/detailsPage/DetailsPage';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import SEO from '../../../components/seo/SEO';
import CustomLink from '../../../components/customLink/CustomLink';

const Tenants = ({ showDetailsPage }) => {
  const { listId } = useParams();
  const { data: records, refetch, isLoading} = useQuery({
    queryFn: () => getRecords(details.apiNamePlural, listId ? listViews.find(list => list._id === listId) : null),
    queryKey: [details.apiNamePlural, listId]
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name', // Accessor for searching
        id: 'name',
        header: 'Name',
        Cell: ({ row }) => (
          <CustomLink to={`/tenant/${row.original._id}`} text={row.original.name} />
        ),
      },
      {
        accessorKey: 'email', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        enableClickToCopy: true,
        filterVariant: 'autocomplete',
        header: 'Email'
      },
      {
        accessorKey: 'phone',
        header: 'Phone'
      },
      {
        accessorFn: (row) => row.plan?.name,
        id: 'plan', //id is still required when using accessorFn instead of accessorKey
        header: 'Tenant Plan'
      },
      {
        accessorFn: (row) => row.isActive ? <CheckBoxOutlinedIcon /> : <CheckBoxOutlineBlankOutlinedIcon /> , //accessorFn used to join multiple data into a single cell
        id: 'isActive', //id is still required when using accessorFn instead of accessorKey
        header: 'Is Active'
      },
    ],
  []
  );

  const importColumns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
      },
      {
        accessorKey: 'email', 
        header: 'Email'
      },
      {
        accessorKey: 'phone',
        header: 'Phone'
      },
    ],
  []
  );

  const listViews = [
    {
      _id: 'f19d8c48-d3c6-46fb-9f7d-fcef5f93ba3b',
      label: 'All Tenants',
      filterLogic: null,
      filters: []
    },
    {
      _id: '2ebd8786-6261-460b-a378-fe5b8f1350a5',
      label: 'Active Tenant Plans',
      filterLogic: null,
      filters: [
        {
          field: 'isActive',
          operator: 'equals',
          value: true
        }
      ]
    },
    {
      _id: '5b9e59f3-0514-4d7e-bb81-9ae013d104ac',
      label: 'Inactive Tenants',
      filterLogic: null,
      filters: [
        {
          field: 'isActive',
          operator: 'equals',
          value: false
        }
      ]
    },
    {
      _id: '3913783a-b529-4353-b0f0-fae84660ef64',
      label: 'Internal Tenant',
      filterLogic: null,
      filters: [
        {
          field: 'recordType',
          operator: 'equals',
          value: 'Internal'
        }
      ]
    }
  ];

  return (
    <>
      <SEO
        title={`${details.pluralLabel} | Salesdam`}
        description={details.description}
      />
      <Header
        objectType={details.apiName}
        infoText={details.description}
        type='listview'
        listViews={listViews}
      />
      {showDetailsPage && (
        <DetailsPage
          objectType={details.apiName}
          listViewLink={`/${details.apiNamePlural}`}
          refetch={refetch}
          RecordForm={<RecordForm />}
        />
      )}
      <div className="list-view">
        <ListViewTable
          enableRowActions={true}
          enableRowSelection={true}
          isLoading={isLoading}
          objectType={details.apiName}
          objectTypePlural={details.apiNamePlural}
          refetch={refetch}
          listViewQueryKey={details.apiNamePlural}
          columns={columns}
          importColumns={importColumns}
          data={records}
          RecordForm={<RecordForm />}
        />
      </div>
    </>
  );
};

export default Tenants;