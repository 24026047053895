import fields from './fields';
import getFieldsByName from '../../utils/getFieldsByName';

const commonLayout = [
    {
        label: 'Tenant Plan Information',
        leftFields: getFieldsByName(fields, ['name', 'recordType']),
        rightFields: getFieldsByName(fields, ['price', 'isActive'])
    },
    {
        label: 'Tenant Plan Limits',
        fullWidthFields: getFieldsByName(fields, ['maxUsers', 'maxLeads', 'maxDeals', 'maxContacts', 'maxAccounts', 'maxTasks', 'maxCases'])
    },
    {
        label: 'Description Information',
        fullWidthFields: getFieldsByName(fields, ['description'])
    }
];

// Define sections
const sectionsByRecordType = {
    'Standard': commonLayout,
    'Custom': commonLayout
};

const getSectionsByRecordType = (recordType)  => {
    return sectionsByRecordType[recordType] || [];
}

export default getSectionsByRecordType;