const PageNotFound = () => {
    return (
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <div>
            <h1>404 - Page Not Found</h1>
            <p>Sorry, the page you are looking for could not be found.</p>
        </div>
      </div>
    );
};

export default PageNotFound;