import { useState, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
} from '@mui/material';
import { IconButton } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Field from '../../field/Field';
import { updateRecord } from '../../../api/crud';
import { toast } from 'react-toastify';
import validateForm from '../../../utils/formValidation/validateForm';
import getFieldsPerChartName from '../../../objectManager/standardChart/getFieldsPerChartName';

const cancelButtonStyles = {
  color: 'var(--primary-color)',
  border: 'var(--thin-border)',
  backgroundColor: 'var(--secondary-text-color)',
};

const saveButtonStyles = {
  backgroundColor: 'var(--primary-color)',
};

const EditChartModal = ({ chartRecord }) => {
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    from: '',
    to: '',
    caption: '',
    description: '',
    targetValue: 0,
  });

  useEffect(() => {
    if (chartRecord) {
      setFormData({
        from: chartRecord.from || '',
        to: chartRecord.to || '',
        caption: chartRecord.caption || '',
        description: chartRecord.description || '',
        targetValue: chartRecord.targetValue || 0,
      });
    }
  }, [chartRecord]);

  const fields = getFieldsPerChartName(chartRecord.name);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    // Logic to Update existing record
    setIsSubmitting(true); // Disable the button when clicked

    const validationErrors = validateForm(formData, '', fields); // Call the validation function to get errors
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      try {
        await updateRecord('standard-chart', chartRecord?._id, formData);
        setIsSubmitting(false);
        // Invalidate and refetch the query
        queryClient.invalidateQueries(['sales-target']);
        queryClient.invalidateQueries(['orders-targets']);
        queryClient.invalidateQueries(['completed_orders']);
        queryClient.invalidateQueries(['annual-target']);
        toast.success('Successfully updated record!');
        handleClose();
      } catch (error) {
        setIsSubmitting(false);
        console.error('Error updating record:', error);
        toast.error(`Error updating record: ${error.response.data.message}`);
      }
    } else {
      const message =
        'Oops! Looks like there are some missing or incorrect fields. Please review and fix them.';
      toast.error(message);
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <IconButton
        style={{ position: 'absolute', top: 0, right: 0 }}
        aria-label="edit"
        onClick={handleClickOpen}
      >
        <EditOutlinedIcon />
      </IconButton>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Edit Details</DialogTitle>
        <DialogContent>
          <Field
            id="caption"
            name="caption"
            label="Caption"
            type="text"
            value={formData.caption}
            onChange={(e) => handleInputChange(e)}
            required={true}
            errorMessage={errors.caption}
          />
          <Field
            id="description"
            name="description"
            label="Description"
            type="textarea"
            value={formData.description}
            onChange={(e) => handleInputChange(e)}
            required={true}
            errorMessage={errors.description}
          />
          <div style={{ display: 'flex', gap: '10px', width: '100%' }}>
            <Field
              id="from"
              name="from"
              label={chartRecord.name === 'completed_orders' ? 'Select month for the start of the financial year' : 'From Date'}
              type={chartRecord.name === 'completed_orders' ? 'picklist' : 'date'}
              options={chartRecord.name === 'completed_orders' ? ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"] : []}
              value={formData.from}
              onChange={(e) => handleInputChange(e)}
              required={true}
              errorMessage={errors.from}
              customStyles={{ flexGrow: 1 }} // Make the field occupy full width
            />
            {chartRecord.name !== 'completed_orders' && (
              <Field
                id="to"
                name="to"
                label="To Date"
                type="date"
                value={formData.to}
                onChange={(e) => handleInputChange(e)}
                required={true}
                errorMessage={errors.to}
                customStyles={{ flexGrow: 1 }} // Make the field occupy full width
              />
            )}
          </div>
          {(chartRecord.name === 'sales_target' || chartRecord.name === 'annual_target') && (
            <Field
              id="targetValue"
              name="targetValue"
              label="Target Value"
              type="number"
              value={formData.targetValue}
              onChange={(e) => handleInputChange(e)}
              required={true}
              errorMessage={errors.targetValue}
            />
          )}
        </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            size="small"
            variant="contained"
            style={saveButtonStyles}
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            Save
          </Button>
          <Button
            size="small"
            variant="outlined"
            style={cancelButtonStyles}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditChartModal;
