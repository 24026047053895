import Header from '../../layouts/header/Header';
import SEO from '../../components/seo/SEO';
import TotalOrdersPerMonth from '../dashboards/ordersDashboard/charts/TotalOrdersPerMonth';
import WinLossRatio from './charts/winLossRatio/WinLossRatio';
import OpenDealsAboutToClose from './charts/openDealsAboutToClose/OpenDealsAboutToClose';
import AnnualTarget from './charts/annualTarget/AnnualTarget';

const Home = () => {
    return (
        <>
            <SEO
                title='Home | Salesdam'
                description='Explore the latest updates and activities with the overview below, and check out your most recent items to stay up-to-date with important interactions.'
            />
            <Header
                headerText='Home'
                infoText='Explore the latest updates and activities with the overview below, and check out your most recent items to stay up-to-date with important interactions.'
            />
            <div className='dashboard-charts'>
                <AnnualTarget />
                <OpenDealsAboutToClose />
                <TotalOrdersPerMonth />
                <WinLossRatio />
            </div>
        </>
    );
};

export default Home;