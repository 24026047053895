import { useState } from 'react';
import { useQuery } from 'react-query';
import Card from '@mui/material/Card';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import DoubleLineChart from '../../../../components/charts/lineChart/DoubleLineChart';
import DoubleBarChart from '../../../../components/charts/barChart/DoubleBarChart';
import { getRecords } from '../../../../api/crud';
import EditChartModal from '../../../../components/modals/editChartModal/EditChartModal';
import aggregateOrdersByMonth from '../../../../utils/aggregateOrdersByMonth';

// Main component to render the Order Chart with toggle
const MonthlyOrdersChart = () => {
  const [showLineChart, setShowLineChart] = useState(true);

  const chartFilter = {
    filterLogic: null,
    filters: [
      {
        field: 'name',
        operator: 'equals',
        value: 'completed_orders',
      },
    ],
  };

  const { data: charts, isLoading: loadingCharts } = useQuery({
    queryFn: () => getRecords('standard-charts', chartFilter),
    queryKey: ['completed_orders'],
  });

  const chartRecord = charts?.length > 0 ? charts[0] : null;

  const { data: orders, isLoading: loadingOrders } = useQuery({
    queryFn: () => getRecords('orders', {}),
    queryKey: ['orders-line-chart'],
  });

  if (!chartRecord) {
    return <div>No chart configuration found.</div>;
  }

  const { result: chartData, hasCurrentYearData } = aggregateOrdersByMonth(orders, chartRecord.from);

  const legendPayload = [
    { value: 'Current Year', type: 'rect', id: 'ID01', color: '#8884d8' },
    { value: 'Previous Year', type: 'rect', id: 'ID02', color: '#82ca9d' },
  ];

  return (
    <Card style={{ position: 'relative' }}>
      {!loadingCharts && !loadingOrders && (
        <>
          <EditChartModal chartRecord={chartRecord} />
          <FormControlLabel
            label={showLineChart ? 'Show Bar Chart' : 'Show Line Chart'}
            control={<Switch checked={showLineChart} onChange={() => setShowLineChart(!showLineChart)} />}
            sx={{
              padding: '10px',
            }}
          />
        </>
      )}
      {showLineChart ? (
        <DoubleLineChart
          isLoading={loadingCharts || loadingOrders}
          caption={chartRecord.caption}
          data={chartData}
          unitsType={chartRecord.unitsType}
          dataKey1={hasCurrentYearData ? 'currentYear' : null}
          dataKey2="previousYear"
          legendPayload={legendPayload}
        />
      ) : (
        <DoubleBarChart
          isLoading={loadingCharts || loadingOrders}
          caption={chartRecord.caption}
          data={chartData}
          unitsType={chartRecord.unitsType}
          dataKey1={hasCurrentYearData ? 'currentYear' : null}
          dataKey2="previousYear"
          legendPayload={legendPayload}
        />
      )}
    </Card>
  );
};

export default MonthlyOrdersChart;