import fields from './fields';
import getFieldsByName from '../../utils/getFieldsByName';

// Define sections
const sectionsByRecordType = {
    'Default': [
        {
            label: 'User Information',
            leftFields: getFieldsByName(fields, ['username', 'title', 'firstName', 'lastName', 'email']),
            rightFields: getFieldsByName(fields, ['role', 'phone', 'gender', 'isActive'])
        }
    ]
};

const getSectionsByRecordType = (recordType)  => {
    return sectionsByRecordType[recordType] || [];
}

export default getSectionsByRecordType;