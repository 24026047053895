import Header from '../../../layouts/header/Header';
import SEO from '../../../components/seo/SEO';
import OrdersTarget from './charts/OrdersTarget';
import TotalOrdersPerMonth from './charts/TotalOrdersPerMonth';
import MonthlyOrdersChart from './charts/MonthlyOrdersChart';
import IncomeProjection from './charts/IncomeProjection';

const DealsDashboard = () => {
  const infoText =
    'Welcome to the Orders Dashboard! Here, you can track the progress of your sales targets in real-time.';

  return (
    <>
      <SEO title="Orders Dashboard | Salesdam" description={infoText} />
      <Header headerText="Orders Dashboard" infoText={infoText} />
      <div className="dashboard-charts-three-columns">
        <OrdersTarget />
        <TotalOrdersPerMonth />
        <IncomeProjection />
      </div>
      <div className="full-width-chart">
        <MonthlyOrdersChart />
      </div>
    </>
  );
};

export default DealsDashboard;
