import { useQuery } from 'react-query';
import Card from '@mui/material/Card';
import Donut from '../../../../components/charts/donut/Donut';
import { getSummary } from '../../../../api/crud';
import convertObjectToArray from '../../../../utils/convertObjectToArray';

const LeadsSummary = () => {
    const { data: leadsSummary, isLoading } = useQuery({
        queryFn: () => getSummary('lead-summary?isConverted=false'),
        queryKey: ['lead-summary'],
    });

    const leadssBreakdown = leadsSummary?.summaries?.status?.breakdown;
    const data = convertObjectToArray(leadssBreakdown);

    return (
        <Card sx={{padding: '15px' }}>
            <Donut caption='Leads Summary' data={data} isLoading={isLoading} />
        </Card>
    );
};

export default LeadsSummary;