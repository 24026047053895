import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { getRecords } from '../../../../api/crud';
import Card from '@mui/material/Card';
import Table from '../../../../components/charts/table/Table';
import { getCurrentDateTime } from '../../../../utils/dateUtils';
import CustomLink from '../../../../components/customLink/CustomLink';
import formatDateAndTime from '../../../../utils/formatDateAndTime';
import { sortObjectsByDateAscending } from '../../../../utils/sortRecords';
import Countdown from '../../../../components/countdown/Countdown';

const OpenDealsAboutToClose = () => {
  // Assuming you have a 'closingDate' field in your data
  const { data: deals, isLoading } = useQuery({
    queryFn: () => getRecords('deals', openDealsAboutToCloseFilter),
    queryKey: ['open-deals-about-to-close'],
  });
  
  const openDealsAboutToCloseFilter = {
    filterLogic: null,
    filters: [
      {
        field: 'stage',
        operator: 'equals',
        value: 'Work in Progress' 
      },
    ]
  };
  
  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => <CustomLink to={`/deal/${row._id}`} text={row.name} />,
        id: 'name',
        header: 'Deal Name',
      },
      {
        accessorKey: 'client',
        id: 'client',
        header: 'Client',
      },
      {
        accessorFn: (row) => formatDateAndTime(row.closingDate),
        id: 'closingDate',
        header: 'Closing Date',
      },
      {
        accessorFn: (row) => <Countdown closingDate={row.closingDate}/>,
        id: 'countdown',
        header: 'Countdown',
      },
    ],
    []
  );

    
  const sortedDeals = sortObjectsByDateAscending(deals, 'closingDate');
  const  removeDeadlineDeals = sortedDeals?.filter(deal => deal?.closingDate > getCurrentDateTime());

  return (
    <Card sx={{ padding: '15px' }}>
      <Table isLoading={isLoading} caption='Open Deals About to Close' columns={columns} data={removeDeadlineDeals?.slice(0, 5)} />
    </Card>
  );
};
  
export default OpenDealsAboutToClose;
  