import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { today } from '../../../utils/dateUtils';

const isTaskOverdue = (dueDate) => {
    return dueDate < today();
};

// Function to calculate the difference in days between two dates
const dealCountDown = (closeDate) => {
    
    const currentDate = today(); // Get the current date
    const diffInTime = new Date(currentDate) - new Date(closeDate); // Difference in milliseconds
    const diffInDays = Math.floor(diffInTime / (1000 * 60 * 60 * 24)); // Convert to days
  
    return diffInDays; // Return the difference in days
};

const CardItem = ({ item, objectType }) => {
    const navigate = useNavigate();

    const handleViewTask = () => {
        navigate(`/${objectType}/${item?._id}`);
    };

    const dueDateColor = {
        color: isTaskOverdue(item?.dueDate) ? 'red' : '',
    };

    return (
        <Card sx={{ minWidth: 250 }}>
            <CardContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {item?.subject}
                </Typography>
                {objectType === 'task' && 
                    <Typography variant="body2">
                        Due Date: <span style={dueDateColor}>{item?.dueDate}</span>
                    </Typography>
                }
                {objectType === 'case' && 
                    <Typography variant="body2">
                        Type: {item?.type}
                    </Typography>
                }
                {objectType === 'lead' && 
                    <>
                        <Typography variant="body3">
                            {item?.firstName} {item?.lastName}
                        </Typography>
                        <Typography variant="body2">
                            {item?.company}
                        </Typography>
                        <Typography variant="body2">
                            {item?.phone}
                        </Typography>
                        <Typography variant="body2">
                            {item?.recordType}
                        </Typography>
                    </>
                }
                {objectType === 'deal' && 
                    <>
                        <Typography variant="body3">
                            {item?.name}
                        </Typography>
                        <Typography variant="body2">
                            Closing Date: {item?.closeDate}
                        </Typography>
                        <Typography variant="body2">
                            Countdown in days: {item?.closeDate ? dealCountDown(item?.closeDate) : '--'}
                        </Typography>
                    </>
                }
                {(objectType === 'task' || objectType === 'case') && 
                    <Typography variant="body2">
                        Priority: {item?.priority}
                    </Typography>
                }
            </CardContent>
            <CardActions>
                <Button onClick={handleViewTask} style={{color: 'var(--primary-color)'}} size="small">{`view ${objectType}`}</Button>
            </CardActions>
        </Card>
    );
}

export default CardItem;