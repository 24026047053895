import Header from '../../../layouts/header/Header';
import SEO from '../../../components/seo/SEO';
import LeadsSummary from './charts/LeadsSummary';
import TotalConvertedLeads from './charts/TotalConvertedLeads';

const LeadsDashboard = () => {
    const infoText = 'The Leads Dashboard provides a comprehensive view of all your sales leads.';

    return (
        <>
            <SEO
                title='Leads Dashboard | Salesdam'
                description={infoText}
            />
            <Header
                headerText='Leads Dashboard'
                infoText={infoText}
            />
            <div className='dashboard-charts'>
                <LeadsSummary />
                <TotalConvertedLeads />
            </div>
        </>
    );
};

export default LeadsDashboard;