import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import Skeleton from '@mui/material/Skeleton';

// Function to calculate the percentage of each segment
const calculatePercentage = (value, total) => ((value / total) * 100).toFixed(0);

const Donut = ({ data, caption, isLoading }) => {
  // Default colors for the chart segments
  const defaultColors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#4793AF', '#FFC470', '#DD5746'];

  // Calculate the total sum of all data values
  const total = data.reduce((acc, curr) => acc + curr.value, 0);

  // Skeleton for Donut Chart
  const donutSkeleton = (
    <div style={{ textAlign: 'center', paddingTop: '40px' }}>
      {/* Outer circle to simulate the outer ring of the donut */}
      <Skeleton variant="circular" width={200} height={200} style={{ margin: '0 auto', zIndex: 1 }} />
      {/* Smaller inner circle to create the donut hole effect */}
      <Skeleton
        variant="circular"
        width={100}
        height={100}
        style={{ position: 'absolute', zIndex: 2, backgroundColor: 'transparent', top: 100, left: 90 }}
      />
    </div>
  );

  const chartContent = isLoading ? (
    donutSkeleton
  ) : (
    <ResponsiveContainer width="100%" height={300}>
      <PieChart>
        <Pie
          data={data}
          dataKey="value"
          nameKey="label"
          innerRadius="60%"
          outerRadius="75%"
          fill="#8884d8"
          paddingAngle={1}
          label={({ value }) => `${calculatePercentage(value, total)}%`}
          labelPosition="inside"
          labelStyle={{
            fontSize: '12px',
            fill: '#fff', // White font color for contrast
          }}
        >
          {data.map((entry, index) => {
            const color = entry.color || defaultColors[index % defaultColors.length];
            return <Cell key={`cell-${index}`} fill={color} />;
          })}
        </Pie>
        <Tooltip />
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  );

  return (
    <div style={{ textAlign: 'center' }}>
      <h4>{caption}</h4>
      {chartContent} {/* Display the chart content or skeleton loading based on isLoading */}
    </div>
  );
};

export default Donut;