import { useQuery } from 'react-query';
import { getSummary } from '../../../../api/crud';
import Donut from '../../../../components/charts/donut/Donut';
import { Card } from '@mui/material';

const WinLossRatio = () => {
  const { data: dealsSummary, isLoading } = useQuery({
    queryFn: () => getSummary('deal-summary', {}),
    queryKey: ['deal-summary'],
  });

  // Get the Deals breakdown
  const dealsBreakdown = dealsSummary?.summaries?.stage?.breakdown;
  
  // Extract Closed Won and Closed Lost
  const wins = dealsBreakdown?.['Closed Won'] || 0; // Default to 0 if undefined
  const losses = dealsBreakdown?.['Closed Lost'] || 0; // Default to 0 if undefined

  // Example data showing wins and losses
  const winLossData = [
    { label: 'Wins', value: wins, color: 'var(--green-color)' },
    { label: 'Losses', value: losses, color: 'var(--red-color)' },
  ];

  return (
    <Card>
      <Donut data={winLossData} caption='Win/Loss Ratio' isLoading={isLoading} />
    </Card>
  );
};

export default WinLossRatio;
