const daysAndHoursLeft = (days, hours, minutes) => {
  if (days < 0 || hours < 0) {
    return <span style={{color: 'var(--primary-text-color)'}}>Deadline has passed.</span>;
  }

  const isDaysPlural = days !== 1;
  const isHoursPlural = hours !== 1;
  const isMinutesPlural = minutes !== 1;

  if (days === 0 && hours === 0) {
    return `${minutes} minute${isMinutesPlural ? "s" : ""}`;
  }

  if (days === 0) {
    return `${hours} hour${isHoursPlural ? "s" : ""} left`;
  }

  if (hours === 0) {
    return `${days} day${isDaysPlural ? "s" : ""}`;
  }

  return `${days} day${isDaysPlural ? "s" : ""} and ${hours} hour${
    isHoursPlural ? "s" : ""
  } left`;
};

export default daysAndHoursLeft;
