import TargetsDashboard from './TargetsDashboard';

const RevenueTargetsDashboard = () => {
  return (
    <TargetsDashboard 
      title="Revenue Targets"
      description="Welcome to the Revenue Targets Dashboard"
      targetKey="revenueTargets"
      valueField="orderValue"
      dateField="invoiceDate"
    />
  );
};

export default RevenueTargetsDashboard;