import styles from './css/RangeCalculator.module.css';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Field from '../../field/Field';
import formatToRand from '../../../utils/formatToRands';
import { formatDate } from '../../../utils/dateUtils';
import Skeleton from '@mui/material/Skeleton';
import { updateRecord } from '../../../api/crud';
import { toast } from 'react-toastify';

// Component to calculate the total sum for a specified field within a given date range
const SumRangeCalculator = ({ chartId, caption, total, isLoading, from, to, queryKey }) => {
  const queryClient = useQueryClient();
  const [fromDate, setFromDate] = useState(from);
  const [toDate, setToDate] = useState(to);
  const [isUpdatingFilter, setIsUpdatingFilter] = useState(false);

  useEffect(() => {
    setFromDate(from);
    setToDate(to);
  }, [from, to]);

  // Event handler for filtering based on the user-specified date range
  const handleFilter = () => {
    if (fromDate && toDate) {
      setIsUpdatingFilter(true);
      toast.promise(
          updateRecord('standard-chart', chartId, {from: fromDate, to: toDate}),
          {
              pending: 'Updating filter...',
              success: 'Succesfully updated filter',
              error: 'Error updating filter'
          }
      ).then(() => {
        setIsUpdatingFilter(false);
        queryClient.invalidateQueries([queryKey]);
        queryClient.invalidateQueries(['orders-count']);
      }).catch(error => {
        setIsUpdatingFilter(false);
        console.error('Error updating filter', error);
      });
    }
  };

  return (
    <Card className={styles['range-calculator']}>
      <div className={styles['caption']}>
        <h2>{caption}</h2>
        <small className={styles['date-range']}>
          {isLoading ? <Skeleton variant='rectangular' width={130} height={20} /> : <>{formatDate(fromDate)} - {formatDate(toDate)}</>}
        </small>
      </div>
      <span className={styles['total-amount']}>
        {isLoading ? <Skeleton variant='rectangular' width={300} height={50} /> : formatToRand(total.toFixed(2))}
      </span>
      <div className={styles['filter-container']}>
        <div className={styles['date-fields-container']}>
          <Field
            id='from-date'
            label='From Date'
            name='fromDate'
            type='date'
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
          />
          <Field
            id='to-date'
            label='To Date'
            name='toDate'
            type='date'
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
          />
        </div>
        <div className={styles['submit-button']}>
          <Button disabled={isUpdatingFilter} style={{ color: 'var(--primary-color)' }} onClick={handleFilter}>Apply Filter</Button>
        </div>
      </div>
    </Card>
  );
};

export default SumRangeCalculator;