import fields from './fields';

const getFieldsPerChartName = (chartType) => {
  // Define the fields to be included for each chart type
  const inclusions = {
    'Sales targets': ['caption', 'description', 'from', 'to', 'targetValue'],
    'completed_orders': ['caption', 'description', 'from']
  };

  // If the chart type is not in the inclusions, return all fields
  if (!inclusions[chartType]) {
    return fields;
  }

  // Get the list of fields to include for the given chart type
  const fieldsToInclude = inclusions[chartType];

  // Filter the fields to include only those specified
  return fields.filter(field => fieldsToInclude.includes(field.name));
};

export default getFieldsPerChartName;
