import fields from './fields';
import getFieldsByName from '../../utils/getFieldsByName';

const commonLayout = [
    {
        label: 'Lead Information',
        leftFields: getFieldsByName(fields, ['title', 'firstName', 'lastName', 'client', 'jobTitle', 'industry', 'currency']),
        rightFields: getFieldsByName(fields, ['salesRep', 'recordType', 'source', 'status', 'phone', 'email', 'website'])
    },
    {
        label: 'RFQ Details',
        leftFields: getFieldsByName(fields, ['rfqNumber', 'projectCode', 'cidbNumber', 'productType', 'technicalScope', 'endUser']),
        rightFields: getFieldsByName(fields, ['closingDate', 'quoteValidity', 'quoteSubmissionLocation', 'compulsoryBriefing', 'dateOfBriefing', 'briefingAddress'])
    },
    {
        label: 'Product Details',
        fullWidthFields: getFieldsByName(fields, ['productName', 'productDescription'])
    },
    {
        label: 'Address',
        leftFields: getFieldsByName(fields, ['address.street', 'address.city', 'address.province', 'address.zip', 'address.country']),
       // rightFields: getFieldsByName(fields, ['website'])
    },
    {
        label: 'Internal Comments',
        fullWidthFields: getFieldsByName(fields, ['internalComments'])
    },
    {
        label: 'System',
        leftFields: getFieldsByName(fields, ['createdAt']),
        rightFields: getFieldsByName(fields, ['updatedAt'])
    },
     /*{
        label: 'Briefing Details',
        fullWidthFields: getFieldsByName(fields, ['compulsoryBriefing', 'dateOfBriefing', 'briefingAddress'])
    },*/
];

// Define sections
const sectionsByRecordType = {
    'Web Lead': commonLayout,
    'Email Lead': commonLayout
};

const getSectionsByRecordType = (recordType)  => {
    return sectionsByRecordType[recordType] || [];
}

export default getSectionsByRecordType;