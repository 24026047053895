import styles from './GaugeChart.module.css';
import GaugeChart from 'react-gauge-chart';
import { formatDate } from '../../../utils/dateUtils';
import { useEffect, useState } from 'react';
import Confetti from 'react-confetti';

const colors = ['var(--red-color)', 'var(--orange-color)', 'var(--green-color)'];

const getTextColor = (percentage) => {
    if(percentage <= 0.3){
        return styles['red'];
    }

    if(percentage > 0.3 && percentage <= 0.6){
        return styles['orange'];
    }

    if(percentage > 0.6){
        return styles['green'];
    }
};

const GaugeChartComponent = ({ value, target, caption, bottomText, fromDate, toDate, isLoading }) => {
  const percentage = value / target;
  const [isTargetReached, setIsTargetReached] = useState(false);

  useEffect(() => {
    if (value >= target) {
      setIsTargetReached(true);
      // Optionally, you can reset the confetti after some time
      const timer = setTimeout(() => setIsTargetReached(false), 5000);
      return () => clearTimeout(timer);
    }
  }, [value, target]);

  return (
    <>
      <div style={{ textAlign: 'center', margin: '10px' }}>
        <h2>{caption}</h2>
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <>
            <small className={styles['date-range']}>
              {formatDate(fromDate)} - {formatDate(toDate)}
            </small>
            <GaugeChart
              id="gauge-chart"
              nrOfLevels={3}
              percent={percentage >= 1 ? 1 : percentage}
              textColor={getTextColor(percentage)}
              colors={colors}
              arcWidth={0.3}
              textComponent={<p>{Math.round(percentage*100)}%</p>}
              textComponentContainerClassName={`${styles['text-component']} ${getTextColor(percentage)}`}
            />
            { value >= target && (
              <>
                <div className={styles['congratulations']}>
                  Congratulations! Target reached!
                </div>
                {isTargetReached && <Confetti /> }
              </>
            )}
            <h2 className={styles['bottom-text']}>
              {bottomText}
            </h2>
          </>
        )}
      </div>
    </>
  );
};

export default GaugeChartComponent;
