import styles from '../css/EmailToLeadForwardingConfig.module.css';
import { useEffect, useState } from 'react';
import { updateRecord } from '../../../api/crud';
import { useQueryClient } from 'react-query';
import { Button } from '@mui/material';
import Field from '../../../components/field/Field';
import { toast } from 'react-toastify';

const CreateEmailParsingRules = ({ fieldsForParsingRules, data, isLoading }) => {
  const queryClient = useQueryClient();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [parsingRulesData, setParsingRulesData] = useState({});

  useEffect(() => {
    if (!isLoading && data) {
      setParsingRulesData(data[0]?.parsingRules || {});
    }
  }, [isLoading, data]);

  const handleFieldChange = (field, key, value) => {
    setParsingRulesData((prevData) => ({
      ...prevData,
      [field]: {
        ...prevData[field],
        [key]: value,
      },
    }));
  };

  // Validate the form before submitting
  const validateForm = () => {
    // Add your validation logic if needed
    return true;
  };

  // Handle form submission for saving the parsing rules
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      toast.error('Please fill out all required fields.');
      return;
    }

    setIsSubmitting(true);

    try {
      const mappingId = data[0]?._id;
      await updateRecord('email-to-lead-mapping', mappingId, {
        parsingRules: parsingRulesData,
      });
      queryClient.invalidateQueries(['email-to-lead-parsing-rules']);
      toast.success('Parsing rules saved successfully.');
      setIsSubmitting(false);
    } catch (error) {
      console.error('Failed to save parsing rules:', error);
      toast.error(`Failed to save parsing rules: ${error.message}`);
      setIsSubmitting(false);
    }
  };

  return (
    <div className={styles['parsing-rules']}>
      <form onSubmit={handleSubmit}>
        <table>
          <thead>
            <tr>
              <th scope="col">Lead Fields</th>
              <th scope="col">Extract Text After</th>
              <th scope="col">Extract To</th>
            </tr>
          </thead>
          <tbody className={styles['table-body']}>
            {fieldsForParsingRules.map((field) => (
              <tr key={field?.name}>
                <th scope="row">{field?.label}</th>
                <td>
                  <Field
                    type="text"
                    value={parsingRulesData[field.name]?.fromText || ''}
                    onChange={(e) =>
                      handleFieldChange(field.name, 'fromText', e.target.value)
                    }
                  />
                </td>
                <td>
                  <Field
                    type="text"
                    value={parsingRulesData[field.name]?.toText || ''}
                    onChange={(e) =>
                      handleFieldChange(field.name, 'toText', e.target.value)
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="3">
                <Button
                  size="small"
                  type="submit"
                  variant="contained"
                  style={{ background: 'var(--primary-color)' }}
                  disabled={isSubmitting || isLoading}
                >
                  {isSubmitting ? 'Saving' : 'Save Rules'}
                </Button>
              </td>
            </tr>
          </tfoot>
        </table>
      </form>
    </div>
  );
};

export default CreateEmailParsingRules;