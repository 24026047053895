import fields from './fields';
import getFieldsByName from '../../utils/getFieldsByName';

// Define sections
const sectionsByRecordType = {
    'Default': [
        {
            label: 'Case Information',
            leftFields: getFieldsByName(fields, ['owner', 'contact', 'account', 'currency', 'lead']),
            rightFields: getFieldsByName(fields, ['assignedTo'])
        },
        {
            label: 'Additional Information',
            leftFields: getFieldsByName(fields, ['status', 'origin', 'priority']),
            rightFields: getFieldsByName(fields, ['type', 'caseReason'])
        },
        {
            label: 'Description Information',
            fullWidthFields: getFieldsByName(fields, ['subject', 'description', 'internalComments'])
        },
        {
            label: 'Web Information',
            leftFields: getFieldsByName(fields, ['webEmail', 'webName']),
            rightFields: getFieldsByName(fields, ['webCompany', 'webPhone'])
        }
    ]
};

const getSectionsByRecordType = (recordType)  => {
    return sectionsByRecordType[recordType] || [];
}

export default getSectionsByRecordType;