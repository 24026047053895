import { useContext } from 'react';
import { useQuery } from 'react-query';
import { AuthContext } from '../../../contexts/AuthContext';
import { getRecord, getRecords } from '../../../api/crud';
import Header from '../../../layouts/header/Header';
import SEO from '../../../components/seo/SEO';
import SalesTargets from '../../../components/salesTargets/SalesTargets';

// Function to calculate the total value of a specified field from records within a date range
const calculateTotal = (records, from, to, field, dateField) => {
  return records
    ?.filter(record => {
      const date = new Date(record[dateField]);
      const value = record[field];
      return date >= new Date(from) && date <= new Date(to) && value !== undefined && !isNaN(value);
    })
    ?.reduce((total, record) => total + record[field], 0);
};

// Function to get the start and end dates for a given quarter
const getQuarterRange = (startMonth, quarter) => {
  const startYear = new Date().getFullYear();
  const start = new Date(startYear, startMonth + (quarter - 1) * 3, 1); // Start of the quarter
  const end = new Date(startYear, startMonth + quarter * 3, 0); // End of the quarter
  return { from: start, to: end };
};

// Function to convert month name to month index
const getMonthIndex = (monthName) => {
  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  return monthNames.indexOf(monthName);
};

const TargetsDashboard = ({ title, description, targetKey, valueField, dateField }) => {
  // Retrieve tenant ID from AuthContext
  const { getTenantId } = useContext(AuthContext);
  const tenantId = getTenantId();

  // Fetch tenant data using React Query
  const { data: tenant, isLoading: tenantLoading } = useQuery({
    queryFn: () => getRecord('tenant', tenantId),
    queryKey: ['tenant'],
    enabled: !!tenantId, // Ensure query only runs if tenantId exists
  });

  // Convert startOfFinancialYear from month name to month index
  const startOfFinancialYear = getMonthIndex(tenant?.startOfFinancialYear) || 0;

  // Fetch orders data using React Query
  const { data: records, isLoading: recordsLoading } = useQuery({
    queryFn: () => getRecords('orders', { tenantId }),
    queryKey: ['orders-targets'],
  });

  const infoText = `Welcome to the ${title} Dashboard`;

  // Array representing the four quarters of the fiscal year
  const quarters = [1, 2, 3, 4];
  const targets = tenant?.[targetKey] || {}; // Retrieve targets from tenant data

  const quartersNumericMap = {
    1: 'One',
    2: 'Two',
    3: 'Three',
    4: 'Four'
  };

  return (
    <>
      {/* SEO component for setting page title and description */}
      <SEO title={`${title} | Salesdam`} description={description} />
      
      {/* Header component for displaying the page header */}
      <Header headerText={title} infoText={infoText} />
      
      <div className='dashboard-charts'>
        {/* Iterate over each quarter to render targets components */}
        {quarters?.map((quarter) => {
          // Calculate the date range for the current quarter
          const { from, to } = getQuarterRange(startOfFinancialYear, quarter);
          
          // Calculate the total order value for the current quarter
          const total = calculateTotal(records, from, to, valueField, dateField) || 0;
          
          // Retrieve the target for the current quarter based on the updated schema
          const target = targets[`quarter${quartersNumericMap[quarter]}`] || 0;

          return (
            // Render targets component for the current quarter
            <SalesTargets
              key={quarter}
              caption={`Q${quarter}`}
              target={target}
              value={total}
              from={from?.toDateString()}
              to={to?.toDateString()}
              isLoading={tenantLoading || recordsLoading} // Show loading state if tenant or records data is loading
            />
          );
        })}
      </div>
    </>
  );
};

export default TargetsDashboard;