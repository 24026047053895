const tenderCategories = [
  'Wholesale and retail trade and repair of motor vehicles and motorcycles',
  'Water transport',
  'Water supply; sewerage, waste management and remediation activities',
  'Water collection, treatment and supply',
  'Waste collection, treatment and disposal activities; materials recovery',
  'Warehousing and support activities for transportation',
  'Travel agency, tour operator, reservation service and related activities',
  'Transportation and storage',
  'Telecommunications',
  'Supplies: Stationery/Printing',
  'Supplies: Perishable Provisions',
  'Supplies: Medical',
  'Supplies: General',
  'Supplies: Electrical Equipment',
  'Supplies: Computer Equipment',
  'Supplies: Clothing/Textiles/Footwear',
  'Sports activities and amusement and recreation activities',
  'Specialised construction activities',
  'Sewerage',
  'Services: Professional',
  'Services: General',
  'Services: Functional (Including Cleaning and Security Services)',
  'Services: Electrical',
  'Services: Civil',
  'Services: Building',
  'Services to buildings and landscape activities',
  'Security and investigation activities',
  'Scientific research and development',
  'Residential care activities',
  'Repair and installation of machinery and equipment',
  'Rental and leasing activities',
  'Remediation activities and other waste management services',
  'Real estate activities',
  'Publishing activities',
  'Programming and broadcasting activities',
  'Professional, scientific and technical activities',
  'Printing and reproduction of recorded media',
  'Postal and courier activities',
  'Other service activities',
  'Other professional, scientific and technical activities',
  'Other personal service activities',
  'Other manufacturing',
  'Office administrative, office support and other business support activities',
  'Motion picture, video and television programme production, sound recording and music publishing activities',
  'Mining support service activities',
  'Mining of coal and lignite',
  'Mining and quarrying',
  'Manufacturing',
  'Manufacture of textiles',
  'Manufacture of rubber and plastics products',
  'Manufacture of paper and paper products',
  'Manufacture of motor vehicles, trailers and semi-trailers',
  'Manufacture of machinery and equipment n.e.c.',
  'Manufacture of furniture',
  'Manufacture of fabricated metal products, except machinery and equipment',
  'Manufacture of electrical equipment',
  'Manufacture of computer, electronic and optical products',
  'Manufacture of coke and refined petroleum products',
  'Manufacture of chemicals and chemical products',
  'Manufacture of basic metals',
  'Libraries, archives, museums and other cultural activities',
  'Legal and accounting activities',
  'Land transport and transport via pipelines',
  'Insurance, reinsurance and pension funding, except compulsory social security',
  'Information service activities',
  'Information and communication',
  'Human health and social work activities',
  'Human health activities',
  'Food and beverage service activities',
  'Financial service activities, except insurance and pension funding',
  'Financial and insurance activities',
  'Employment activities',
  'Electricity, gas, steam and air conditioning',
  'Education',
  'Disposals: General',
  'Creative, arts and entertainment activities',
  'Construction of buildings',
  'Construction',
  'Computer programming, consultancy and related activities',
  'Civil engineering',
  'Arts, entertainment and recreation',
  'Architectural and engineering activities; technical testing and analysis',
  'Air transport',
  'Advertising and market research',
  'Administrative and support activities',
  'Activities of households as employers of domestic personnel',
  'Activities of head offices; management consultancy activities',
  'Activities auxiliary to financial service and insurance activities',
  'Accommodation',
];

export default tenderCategories;
