import styles from './css/RolesAndPermissions.module.css';
import { useMemo, useState, useEffect, useContext } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { AuthContext } from '../../contexts/AuthContext';
import Header from '../../layouts/header/Header';
import SEO from '../../components/seo/SEO';
import { Button, CircularProgress, Switch, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getRecord, getRecords, updateRecord } from '../../api/crud';
import { MaterialReactTable } from 'material-react-table';
import { toast } from 'react-toastify';
import getFieldsByObjectType from '../../utils/getFieldsByObjectType';

// Function to check if the user is able to edit based on their role
const isAbleToEdit = (role) => {
    const rolesAbleToEdit = ['Admin', 'Superuser'];
    return rolesAbleToEdit.includes(role);
};

// Function to check if the role is 'Superuser'
const isSuperuser = (roleName) => {
    return roleName === 'Superuser';
};

const RolesAndPermissions = () => {
    const queryClient = useQueryClient();
    const { getUserId } = useContext(AuthContext); // Get user ID from AuthContext
    const userId = getUserId();
    
    // Fetch user data
    const { data: user, isLoading: userLoading } = useQuery({
        queryFn: () => getRecord('user', userId),
        queryKey: ['logged-in-user-roles-and-permissions'],
    });

    const [isUpdatingRole, setIsUpdatingRole] = useState(false);
    
    // Fetch roles and permissions data
    const { data: rolesData, isLoading, isError } = useQuery({
        queryFn: () => getRecords('roles-and-permissions', {}),
        queryKey: ['roles-and-permissions'],
        initialData: [],
        enabled: !!user, // Fetch roles data only if user data is available
    });

    const [updatedRoles, setUpdatedRoles] = useState(rolesData);

    // Update roles state when rolesData changes
    useEffect(() => {
        setUpdatedRoles(rolesData);
    }, [rolesData]);

    // Handle permission change for a specific role and model
    const handlePermissionChange = (roleName, model, permission) => {
        const updatedRolesCopy = [...updatedRoles];
        const roleToUpdate = updatedRolesCopy.find(role => role.name === roleName);

        if (!roleToUpdate || !roleToUpdate.permissions[model]) {
            console.error(`Role ${roleName} or permissions for model ${model} not found.`);
            return;
        }

        roleToUpdate.permissions[model][permission] = !roleToUpdate.permissions[model][permission];

        const updatedRolesUpdated = updatedRolesCopy.map(role => {
            if (role.name === roleName) {
                return {
                    ...role,
                    permissions: {
                        ...role.permissions,
                        [model]: {
                            ...role.permissions[model],
                            [permission]: roleToUpdate.permissions[model][permission],
                        },
                    },
                };
            }
            return role;
        });

        setUpdatedRoles(updatedRolesUpdated);
    };

    // Save updated permissions for a role
    const handleSave = async (roleName) => {
        const role = updatedRoles.find((role) => role.name === roleName);
        setIsUpdatingRole(true);
        toast.promise(
            updateRecord('roles-and-permissions', role._id, { permissions: role.permissions }),
            {
                pending: 'Updating role...',
                success: 'Successfully updated role',
                error: {
                    render({ data }) {
                        // Check if the error response has a message property
                        if (data.response && data.response.data && data.response.data.message) {
                            return data.response.data.message;
                        } else {
                            return 'Error updating role';
                        }
                    }
                }
            }
        ).then(() => {
            setIsUpdatingRole(false);
            queryClient.invalidateQueries(['roles-and-permissions']); // Invalidate query to refresh data
        }).catch(error => {
            setIsUpdatingRole(false);
            console.error('Error updating role', error);
        });
    };

    // Check if a specific permission is granted for a role and model
    const handlePermissionCheck = (roleName, model, permission) => {
        const role = updatedRoles.find((role) => role.name === roleName);
        if (!role || !role.permissions[model]) {
            return false;
        }
        return role.permissions[model][permission];
    };

    // Handle field permission change for a specific role, model, and field
    const handleFieldPermissionChange = (roleName, model, fieldName, permission) => {
        // Create a copy of the updatedRoles array to avoid direct mutation
        const updatedRolesCopy = [...updatedRoles];
        
        // Find the role to update by its name
        const roleToUpdate = updatedRolesCopy.find(role => role.name === roleName);
    
        // Check if the role and model exist in the permissions
        if (!roleToUpdate || !roleToUpdate.permissions[model]) {
            console.error(`Role ${roleName} or model ${model} not found.`);
            return;
        }
    
        // Find the specific field to update by its name
        const fieldToUpdate = roleToUpdate.permissions[model].fields.find(field => field.name === fieldName);
    
        // Check if the field exists
        if (!fieldToUpdate) {
            console.error(`Field ${fieldName} not found.`);
            return;
        }
    
        // Toggle the specified permission (view or edit) for the field
        fieldToUpdate[permission] = !fieldToUpdate[permission];
    
        // Update the roles array with the modified permissions
        const updatedRolesUpdated = updatedRolesCopy.map(role => {
            if (role.name === roleName) {
                return {
                    ...role,
                    permissions: {
                        ...role.permissions,
                        [model]: {
                            ...role.permissions[model],
                            fields: roleToUpdate.permissions[model].fields,
                        },
                    },
                };
            }
            return role;
        });
    
        // Set the updated roles array
        setUpdatedRoles(updatedRolesUpdated);
    };    

    // Function to render field permissions
    const renderFieldPermissions = (row) => {
        // Extract the original data from the row
        const { original } = row;
        
        // Get the fields array and the model type from the original data
        const fields = original?.permissions?.fields || [];
        const objectType = original?.model;
    
        // Function to get the label for a field by its name
        const getFieldLabel = (name) => {
            return getFieldsByObjectType(objectType)?.find(field => field?.name === name)?.label;
        };
    
        // Add a label to each field in the fields array
        fields.forEach(field => {
            field.label = getFieldLabel(field.name);
        });
    
        // Sort the fields by their labels in ascending order
        const sortedFields = fields.filter(field => field.label).sort((a, b) => {
            if (a.label < b.label) return -1;
            if (a.label > b.label) return 1;
            return 0;
        });
    
        return (
            <div className={styles['field-permissions-container']}>
                <Typography variant="h6" className={styles['field-permissions-title']}>
                    Field Permissions
                </Typography>
                <table className={styles['field-permissions-table']}>
                    <thead>
                        <tr>
                            <th>Field Name</th>
                            <th>View</th>
                            <th>Edit</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedFields.map((field) => (
                            <tr key={field.name}>
                                <td>{field?.label}</td>
                                <td>
                                    <Switch
                                        // Disable the switch if the user is not allowed to edit or if the role is 'Superuser'
                                        disabled={!isAbleToEdit(user?.role?.name) || isSuperuser(original?.name)}
                                        // Set the switch state based on the field's view permission
                                        checked={field?.view}
                                        // Toggle the view permission when the switch is changed
                                        onChange={() => handleFieldPermissionChange(original?.name, original?.model, field.name, 'view')}
                                    />
                                </td>
                                <td>
                                    <Switch
                                        // Disable the switch if the user is not allowed to edit or if the role is 'Superuser'
                                        disabled={!isAbleToEdit(user?.role?.name) || isSuperuser(original?.name)}
                                        // Set the switch state based on the field's edit permission
                                        checked={field?.edit}
                                        // Toggle the edit permission when the switch is changed
                                        onChange={() => handleFieldPermissionChange(original?.name, original?.model, field.name, 'edit')}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };               

    // Define columns for the MaterialReactTable
    const columns = useMemo(() => [
        {
            accessorKey: 'model',
            header: 'Model',
            Cell: ({ row }) => row.original.model,
        },
        {
            accessorKey: 'permissions.create',
            header: 'Create',
            Cell: ({ row }) => (
                <Switch
                    disabled={!isAbleToEdit(user?.role?.name) || isSuperuser(row.original.name)}
                    checked={handlePermissionCheck(row.original.name, row.original.model, 'create')}
                    onChange={() => handlePermissionChange(row.original.name, row.original.model, 'create')}
                />
            ),
        },
        {
            accessorKey: 'permissions.view',
            header: 'View',
            Cell: ({ row }) => (
                <Switch
                    disabled={!isAbleToEdit(user?.role?.name) || isSuperuser(row.original.name)}
                    checked={handlePermissionCheck(row.original.name, row.original.model, 'view')}
                    onChange={() => handlePermissionChange(row.original.name, row.original.model, 'view')}
                />
            ),
        },
        {
            accessorKey: 'permissions.edit',
            header: 'Edit',
            Cell: ({ row }) => (
                <Switch
                    disabled={!isAbleToEdit(user?.role?.name) || isSuperuser(row.original.name)}
                    checked={handlePermissionCheck(row.original.name, row.original.model, 'edit')}
                    onChange={() => handlePermissionChange(row.original.name, row.original.model, 'edit')}
                />
            ),
        },
        {
            accessorKey: 'permissions.delete',
            header: 'Delete',
            Cell: ({ row }) => (
                <Switch
                    disabled={!isAbleToEdit(user?.role?.name) || isSuperuser(row.original.name)}
                    checked={handlePermissionCheck(row.original.name, row.original.model, 'delete')}
                    onChange={() => handlePermissionChange(row.original.name, row.original.model, 'delete')}
                />
            ),
        },
    ], [updatedRoles, user]);

    // Prepare data for the table
    const data = useMemo(() => {
        if (!updatedRoles) return [];

        return updatedRoles?.flatMap(role =>
            Object.keys(role?.permissions)?.map(model => ({
                name: role?.name,
                model,
                permissions: role?.permissions[model],
            }))
        );
    }, [updatedRoles]);

    const infoText = 'Manage roles and permissions for users';

    const loadingStyles = {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        color: 'var(--primary-color)'
    };

    if (isLoading || userLoading) return <CircularProgress style={loadingStyles} />;
    if (isError) return <p>Error loading roles</p>;

    return (
        <>
            <SEO
                title='Roles And Permissions | Salesdam'
                description={infoText}
            />
            <Header
                headerText='Home'
                infoText={infoText}
            />
            <div className={styles['roles-and-permissions-page']}>
                {updatedRoles.map((role, roleIndex) => (
                    <Accordion key={roleIndex}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${roleIndex}-content`}
                            id={`panel${roleIndex}-header`}
                        >
                            <Typography>{role.name}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <MaterialReactTable
                                columns={columns}
                                data={data.filter(item => item.name === role.name)}
                                initialState={{ isLoading: isLoading || userLoading, density: 'compact', columnVisibility: { _id: false } }}
                                enableGlobalFilter={false}
                                enableColumnFilters={false}
                                enableHiding={false}
                                enableDensityToggle={false}
                                enableFullScreenToggle={false}
                                enableTopToolbar={isAbleToEdit(user?.role?.name)}
                                renderTopToolbarCustomActions={() => (
                                    <Button
                                        disabled={isUpdatingRole || isSuperuser(role.name)}
                                        size="small"
                                        variant="contained"
                                        onClick={() => handleSave(role.name)}
                                        style={{ backgroundColor: 'var(--primary-color)' }}
                                    >
                                        Save
                                    </Button>
                                )}
                                renderDetailPanel={({ row }) => renderFieldPermissions(row)}
                                muiTablePaperProps={{
                                    elevation: 0,
                                    sx: {
                                        border: 'var(--thin-border)',
                                        borderRadius: 'var(--border-radius)',
                                    },
                                }}
                            />
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>
        </>
    );
};

export default RolesAndPermissions;