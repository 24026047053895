import styles from './css/Accordion.module.css';
import { useState } from 'react';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';

const Accordion = ({ headerText, accordionContent }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const iconRotationStyle = {
    transform: isOpen ? 'rotate(0deg)' : 'rotate(-180deg)',
    transition: 'transform 0.3s ease',
  };

  return (
    <div className={styles['accordion-container']}>
      <div className={`${styles['accordion-header']} prevent-select`} onClick={toggleAccordion}>
        <span className={styles['header-text']}>{headerText}</span>
        <ExpandLessOutlinedIcon style={iconRotationStyle} />
      </div>
      {isOpen && (
        <div className={styles['accordion-content']}>{accordionContent}</div>
      )}
    </div>
  );
};

export default Accordion;
