import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Field from '../../field/Field';
import getRecordTypes from '../../../utils/getRecordTypes';

const NewRecord = ({ open, onClose, heading, RecordForm, objectType }) => {
    const [ recordType, setRecordType ] = useState('');
    const [ showRecordForm, setShowRecordForm ] = useState(false);
    const [ showCancelIcon, setShowCancelIcon ] = useState(false);

    useEffect(() => {
        if(getRecordTypes(objectType)?.length === 1 && getRecordTypes(objectType)[0] === 'Default'){
            setRecordType(getRecordTypes(objectType)[0]);
            setShowRecordForm(true);
            setShowCancelIcon(true);
        };
    }, []);

    const handleRecordTypeChange = (e) => {
        setRecordType(e.target.value);
    };

    const recordTypeSelectStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    };

    const handleCancel = () => {
        onClose();
        setRecordType('');
        setShowRecordForm(false);
    };

    // show record edit form after clicking next
    const handleNextClick = () => {
        setShowRecordForm(true);
        setShowCancelIcon(true);
    };

    const modalTitleStyle = showCancelIcon ? { display: 'flex', alignItems: 'center' } : { textAlign: 'center' };
    const dialogStyles = {
        zIndex: 2000
    };

    return (
        <Dialog style={dialogStyles} fullWidth open={open}>
            <DialogTitle style={modalTitleStyle}>
                <span style={{ flex: 1 }}>{heading}</span>
                {showCancelIcon && (
                    <div>
                        <IconButton onClick={handleCancel} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </div>
                )}
            </DialogTitle>
            <DialogContent>
                {(getRecordTypes(objectType)?.length > 0 && !showRecordForm) && (
                    <div style={recordTypeSelectStyles}>
                        <Field
                            id='recordType'
                            label='Select Record Type:'
                            name='recordType'
                            type='radio'
                            options={getRecordTypes(objectType)}
                            onChange={handleRecordTypeChange}
                            value={recordType}
                        />
                    </div>
                )}
                {showRecordForm && (
                    React.cloneElement(RecordForm, {
                    onClose: handleCancel,
                    recordType: recordType
                }))}
            </DialogContent>
            {!showRecordForm && (
                <DialogActions>
                    <Button
                        size="small"
                        onClick={handleCancel}
                        style={{color: 'var(--primary-color)'}}
                    >
                    Cancel
                    </Button>
                    <Button
                        disabled={!recordType}
                        variant="contained"
                        onClick={handleNextClick}
                        size="small"
                        style={{backgroundColor: 'var(--primary-color)'}}
                    >
                    Next
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
};

export default NewRecord;