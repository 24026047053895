const objectTypePluralMap = {
    'lead': 'leads',
    'order': 'orders',
    'deal': 'deals',
    'account': 'accounts',
    'contact': 'contacts',
    'task': 'tasks',
    'case': 'cases',
    'user': 'users'
};

const getObjectPluralName = (objectType) => {
    return objectTypePluralMap[objectType];
};

export default getObjectPluralName;