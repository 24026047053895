import TargetsDashboard from './TargetsDashboard';

const SalesTargetsDashboard = () => {
  return (
    <TargetsDashboard 
      title="Sales Targets"
      description="Welcome to the Sales Targets Dashboard"
      targetKey="salesTargets"
      valueField="orderValue"
      dateField="orderDate"
    />
  );
};

export default SalesTargetsDashboard;