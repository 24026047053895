import api from './api';

const handleRequest = async (url, data) => {
  try {
    const response = await api.post(`/api/auth${url}`, data);
    return response.data;
  } catch (error) {
    console.error(`Error for ${url}:`, error);
    throw error;
  }
};


const login = async (loginForm) => {
  return handleRequest('/login', loginForm);
};

const logout = async (user) => {
  return handleRequest('/logout', { user });
};

const changePassword = async (userId, currentPassword, newPassword) => {
  return handleRequest('/change-password', { userId, currentPassword, newPassword });
};

const resetPassword = async (form, token) => {
  return handleRequest(`/password-reset-payload/${token}`, form);
};

const getUserPermissions = async () => {
  try {
    const response = await api.get('/api/user-permission-by-user-id');
    return response.data;
  } catch (error) {
    console.error(`Error fetching user permissions`, error);
    throw error;
  }
};

export { login, logout, changePassword, resetPassword, getUserPermissions };
