import { useMemo, useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams, useLocation } from 'react-router-dom';
import { AuthContext } from '../../../contexts/AuthContext';
import { getRecords, updateRecord } from '../../../api/crud';
import details from '../../../objectManager/lead/objectDetails';
import Header from '../../../layouts/header/Header';
import RecordForm from './recordForm/RecordForm';
import ListViewTable from '../../../components/listViewTable/ListViewTable';
import KanbanBoard from '../../../components/kanbanBoard/KanbanBoard';
import DetailsPage from '../../../components/detailsPage/DetailsPage';
import { beginningOfDay, endingOfDay } from '../../../utils/dateUtils';
import SEO from '../../../components/seo/SEO';
import { toast } from 'react-toastify'; // ToastContainer in PageLayout component
import CustomLink from '../../../components/customLink/CustomLink';
import StageIndicator from '../../../components/stageIndicator/StageIndicator';

const Leads = ({ showDetailsPage }) => {
  const location = useLocation();
  const { getUserId } = useContext(AuthContext);
  const { listId } = useParams();
  const { data: records, refetch, isLoading} = useQuery({
    queryFn: () => getRecords(details.apiNamePlural, listId ? listViews.find(list => list._id === listId) : defaultFilter),
    queryKey: [details.apiNamePlural, listId]
  });

  const stages = [
    {
      label: 'Open',
      value: 'Open'
    },
    {
      label: 'Contacted',
      value: 'Contacted'
    },
    {
      label: 'Unqualified',
      value: 'Unqualified'
    },
    // {
    //   label: 'Qualified',
    //   value: 'Qualified'
    // }
  ];

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name', // Accessor for searching
        id: 'name',
        header: 'Name',
        Cell: ({ row }) => (
          <CustomLink to={`/lead/${row.original._id}`} text={`${row.original.technicalScope ? row.original.technicalScope : 'N/A'}`} />
        ),
      },
      {
        accessorKey: 'client',
        header: 'Client'
      },
      {
        accessorKey: 'projectCode',
        header: 'Project Code'
      },
      // {
      //   accessorKey: 'email', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
      //   enableClickToCopy: true,
      //   filterVariant: 'autocomplete',
      //   header: 'Email'
      // },
      {
        accessorFn: (row) => <StageIndicator stage={row.status} />,
        id: 'status',
        header: 'Status'
      },
      {
        accessorFn: (row) => row.salesRep ? `${row.salesRep.firstName} ${row.salesRep.lastName}` : '', //accessorFn used to join multiple data into a single cell
        id: 'salesRep', //id is still required when using accessorFn instead of accessorKey
        header: 'Sales Rep'
      },
    ],
  []
  );

  const importColumns = useMemo(
    () => [
      {
        accessorKey: 'name', // Accessor for searching
        id: 'name',
        header: 'Name',
        Cell: ({ row }) => (
          <CustomLink to={`/lead/${row.original._id}`} text={row.original.name} />
        ),
      },
      {
        accessorKey: 'client',
        header: 'Client'
      },
      {
        accessorKey: 'email',
        header: 'Email'
      },
      {
        accessorFn: (row) => <StageIndicator stage={row.status} />,
        id: 'status',
        header: 'Status'
      }
    ],
  []
  );

  const defaultFilter = {
    filterLogic: null,
    filters: [
      {
        field: 'isConverted',
        operator: 'notEqual',
        value: true
      }
    ]
  };

  // List of available list views
  // operator: equals, notEqual, lessThan, greaterThan, lessOrEqual, greaterOrEqual, contains, notContain, startsWith, includes, excludes
  // filterLogic: '1 AND 2', and or, '1 OR 2', etc
  const listViews = [
    {
      _id: '808a69bd-39b4-4ab0-90d3-96609ef5192a',
      label: 'All Leads',
      filterLogic: null,
      filters: [
        {
          field: 'isConverted',
          operator: 'notEqual',
          value: true
        }
      ]
    },
    {
      _id: '0b7c2e7a-2ba1-4f46-9f0e-32a869e1c43b',
      label: 'My Leads',
      filterLogic: '1 AND 2',
      filters: [
        {
          field: 'salesRep',
          operator: 'equals',
          value: getUserId()
        },
        {
          field: 'isConverted',
          operator: 'equals',
          value: false
        }
      ]
    },
    {
      _id: 'e2f6b33f-351c-4516-aa01-19fe5f5d1c19',
      label: 'My Open Leads',
      filterLogic: '1 AND 2 AND 3',
      filters: [
        {
          field: 'status',
          operator: 'equals',
          value: 'Open'
        },
        {
          field: 'salesRep',
          operator: 'equals',
          value: getUserId()
        },
        {
          field: 'isConverted',
          operator: 'equals',
          value: false
        }
      ]
    },
    {
      _id: '91ad2938-ca9e-43ec-8557-4d6f1594149a',
      label: 'All Open Leads',
      filterLogic: '1 AND 2',
      filters: [
        {
          field: 'status',
          operator: 'equals',
          value: 'Open'
        },
        {
          field: 'isConverted',
          operator: 'equals',
          value: false
        }
      ]
    },
    {
      _id: '5c63780d-5dfb-4968-8264-230447283c48',
      label: `Today's Leads`,
      filterLogic: '1 AND 2 AND 3',
      filters: [
        {
          'field': 'createdAt',
          'operator': 'greaterOrEqual',
          'value': beginningOfDay // Start timestamp for today
        },
        {
          'field': 'createdAt',
          'operator': 'lessOrEqual',
          'value': endingOfDay // End timestamp for today
        },
        {
          field: 'isConverted',
          operator: 'equals',
          value: false
        }
      ]
    }
  ];

  const statusValues =  [
    {label: 'Open', value: 'Open'},
    {label: 'Contacted', value: 'Contacted'},
    {label: 'Completed', value: 'Completed'},
    {label: 'Unqualified', value: 'Unqualified'},
    {label: 'Qualified', value: 'Qualified'}
  ];

  const updateLeadStatus = (leadId, destinationColumn) => {
    const updatePromise = updateRecord(details.apiName, leadId, { status: destinationColumn });
  
    toast.promise(
      updatePromise,
      {
        pending: `Updating ${details.label} status...`,
        success: 'Successfully updated record!',
        error: 'Error updating record',
      }
    );
  
    return updatePromise; // Return the promise to maintain consistency
  };

  return (
    <>
      <SEO
        title={`${details.pluralLabel} | Salesdam`}
        description={details.description}
      />
      <Header
        objectType={details.apiName}
        infoText={details.description}
        type='listview'
        listViews={listViews}
      />
      {showDetailsPage && (
        <DetailsPage
          objectType={details.apiName}
          listViewLink={`/${details.apiNamePlural}`}
          refetch={refetch}
          showStages={true}
          stages={stages}
          RecordForm={<RecordForm />}
        />
      )}
      <div className='list-view'>
        {location.pathname === `/${details.apiNamePlural}/kanban` ? (
          <KanbanBoard
            items={records}
            field="status"
            values={statusValues}
            onDragEnd={(result) => {
              updateLeadStatus(result.draggableId, result.destination.droppableId);
            }}
            objectType={details.apiName}
            objectTypePlural={details.apiNamePlural}
            navigateTo={`/${details.apiNamePlural}`}
          />
          ) : (
          <ListViewTable
            enableRowActions={true}
            enableRowSelection={true}
            isLoading={isLoading}
            objectType={details.apiName}
            objectTypePlural={details.apiNamePlural}
            refetch={refetch}
            listViewQueryKey={details.apiNamePlural}
            columns={columns}
            importColumns={importColumns}
            data={records}
            RecordForm={<RecordForm />}
          />
        )}
      </div>
    </>
  );
};

export default Leads;