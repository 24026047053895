import fields from './fields';
import getFieldsByName from '../../utils/getFieldsByName';

// Define sections
const sectionsByRecordType = {
    'Business Account': [
        {
            label: 'Account Information',
            leftFields: getFieldsByName(fields, ['owner', 'name', 'parentAccount', 'currency']),
            rightFields: getFieldsByName(fields, ['accountNumber','phone', 'email', 'website'])
        },
        {
            label: 'Company Information',
            leftFields: getFieldsByName(fields, ['type', 'industry']),
            rightFields: getFieldsByName(fields, ['employees','annualRevenue'])
        },
        {
            label: 'Address Information',
            leftFields: getFieldsByName(fields, ['billingAddress.street', 'billingAddress.city', 'billingAddress.province', 'billingAddress.zip', 'billingAddress.country']),
            rightFields: getFieldsByName(fields, ['shippingAddress.street', 'shippingAddress.city', 'shippingAddress.province', 'shippingAddress.zip', 'shippingAddress.country'])
        },
        {
            label: 'Additional Information',
            fullWidthFields: getFieldsByName(fields, ['description'])
        },
        /*{
            label: 'Additional Information',
            leftFields: getFieldsByName(fields, ['type', 'industry']),
            rightFields: getFieldsByName(fields, ['employees', 'annualRevenue'])
        },*/
        
    ],
    'Person Account': [
        {
            label: 'Account Information',
            leftFields: getFieldsByName(fields, ['owner', 'salutation', 'firstName', 'lastName', 'currency']),
            rightFields: getFieldsByName(fields, ['accountNumber','phone', 'email', 'website'])
        },
        {
            label: 'Additional Information',
            leftFields: getFieldsByName(fields, ['type', 'industry']),
            rightFields: getFieldsByName(fields, ['employees', 'annualRevenue'])
        },
        {
            label: 'Address Information',
            leftFields: getFieldsByName(fields, ['billingAddress.street', 'billingAddress.city', 'billingAddress.province', 'billingAddress.zip', 'billingAddress.country']),
            rightFields: getFieldsByName(fields, ['shippingAddress.street', 'shippingAddress.city', 'shippingAddress.province', 'shippingAddress.zip', 'shippingAddress.country'])
        },
        {
            label: 'Description Information',
            fullWidthFields: getFieldsByName(fields, ['description'])
        },
    ]
};

const getSectionsByRecordType = (recordType)  => {
    return sectionsByRecordType[recordType] || [];
}

export default getSectionsByRecordType;