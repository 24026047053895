/**
 * Converts a number to a currency format in South African Rand (ZAR).
 * @param {number} value - The number to be converted.
 * @param {string} [locale='en-ZA'] - The locale for formatting (default is 'en-ZA').
 * @returns {string} - The formatted currency string in South African Rand.
 */
const formatToRand = (value, locale = 'en-ZA') => {
    const formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: 'ZAR',
    });
  
    return formatter.format(value);
};

export default formatToRand;
  