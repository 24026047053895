import isAddressField from '../isAddressField';

const getNestedValue = (obj, path) => {
  return path.split('.').reduce((acc, part) => acc && acc[part], obj);
}

const validation = (formValues, formType, fields) => {
  const errors = {};

  // validate required fields
  fields?.forEach((field) => {
    const value = isAddressField(field) ? getNestedValue(formValues, field.name) : formValues[field.name];
    if (field.required && (value === '' || value === undefined)) {
      errors[field.name] = `${field.label} is required`;
    }
  });

  // Form-specific validation logic...
  if (formType === 'user' || formType === 'lead') {
    if (!/^0\d{9}$/.test(formValues.phone) && formValues.phone !== '') {
      errors.phone = `Phone must follow South Africa's standard format. e.g 0723456789`;
    }
  } else if (formType === 'lead') {
    // if () {}
  }

  return errors;
}

export default validation;
