import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

const monthMap = {
  'January': 0,
  'February': 1,
  'March': 2,
  'April': 3,
  'May': 4,
  'June': 5,
  'July': 6,
  'August': 7,
  'September': 8,
  'October': 9,
  'November': 10,
  'December': 11
};

// Function to aggregate order values by month for the current and previous fiscal year
const aggregateOrdersByMonth = (orders, fromMonth) => {
  const startMonthIndex = monthMap[fromMonth]; // Get numeric month index from monthMap

  // Calculate current fiscal year and previous fiscal year
  const currentDate = dayjs();
  const currentFiscalYearStart = currentDate.month(startMonthIndex).startOf('month');
  const previousFiscalYearStart = currentFiscalYearStart.subtract(1, 'year');

  const result = Array(12).fill(0).map((_, index) => {
    const month = currentFiscalYearStart.add(index, 'month').format('MMMM');
    return {
      month: month,
      currentYear: 0,
      previousYear: 0,
    };
  });

  let hasCurrentYearData = false;

  orders?.forEach(order => {
    const { invoiceDate, orderValue } = order;
    if (invoiceDate && orderValue && !isNaN(orderValue)) {
      const orderDate = dayjs(invoiceDate);
      // const orderYear = orderDate.year();
      const orderMonth = orderDate.month();

      if (orderDate.isBetween(currentFiscalYearStart, currentFiscalYearStart.add(1, 'year'), null, '[)')) {
        hasCurrentYearData = true;
        const adjustedMonthIndex = (orderMonth - startMonthIndex + 12) % 12;
        result[adjustedMonthIndex].currentYear += orderValue;
      } else if (orderDate.isBetween(previousFiscalYearStart, previousFiscalYearStart.add(1, 'year'), null, '[)')) {
        const adjustedMonthIndex = (orderMonth - startMonthIndex + 12) % 12;
        result[adjustedMonthIndex].previousYear += orderValue;
      }
    }
  });

  return { result, hasCurrentYearData };
};

export default aggregateOrdersByMonth;