import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
// import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import CrisisAlertOutlinedIcon from '@mui/icons-material/CrisisAlertOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import DomainAddOutlinedIcon from '@mui/icons-material/DomainAddOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
// import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import LockPersonOutlinedIcon from '@mui/icons-material/LockPersonOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
// import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';

const getGlobalItems = [
  {
    name: 'Home',
    path: '/',
    icon: <HomeOutlinedIcon />,
  },
  {
    name: 'Dashboards',
    icon: <DashboardOutlinedIcon />,
    isAccordion: true,
    options: [
      {
        name: 'Orders Dashboard',
        path: '/orders-dashboard',
      },
      {
        name: 'Deals Dashboard',
        path: '/deals-dashboard',
      },
      {
        name: 'Leads Dashboard',
        path: '/leads-dashboard',
      },
      {
        name: 'Revenue Targets',
        path: '/revenue-targets',
      },
      {
        name: 'Sales Targets',
        path: '/sales-targets',
      },
    ],
  },
  // {
  //     name: 'Activities',
  //     path: '/activities',
  //     icon: <EventAvailableOutlinedIcon />
  // },
  {
    name: 'Tasks',
    path: '/tasks',
    icon: <AssignmentOutlinedIcon />,
  },
  {
    name: 'Cases',
    path: '/cases',
    icon: <WorkOutlineOutlinedIcon />,
  },
  // {
  //     name: 'Email',
  //     path: '/emails',
  //     icon: <EmailOutlinedIcon />,
  //     otherPaths: ['/emails/unseen']
  // },
  {
    name: 'Tenants',
    path: '/tenants',
    icon: <DomainAddOutlinedIcon />,
  },
  {
    name: 'Tenant Plans',
    path: '/tenant-plans',
    icon: <LocalOfferOutlinedIcon />,
  },
];

const getObjects = [
  {
    name: 'Find Tenders',
    path: '/tenders',
    icon: <BusinessCenterOutlinedIcon />,
  },
  {
    name: 'Leads',
    path: '/leads',
    icon: <CrisisAlertOutlinedIcon />,
  },
  {
    name: 'Deals',
    path: '/deals',
    icon: <MonetizationOnOutlinedIcon />,
  },
  {
    name: 'Orders',
    path: '/orders',
    icon: <InventoryOutlinedIcon />,
  },
  {
    name: 'Contacts',
    path: '/contacts',
    icon: <ContactsOutlinedIcon />,
  },
  {
    name: 'Accounts',
    path: '/accounts',
    icon: <ApartmentOutlinedIcon />,
  },
];

const getSettings = [
  {
    name: 'Profile',
    path: '/profile',
    icon: <ManageAccountsOutlinedIcon />,
  },
  {
    name: 'Manage Users',
    path: '/users',
    icon: <PeopleAltOutlinedIcon />,
  },
  {
    name: 'Parameters',
    path: '/parameter/company-info',
    icon: <BarChartOutlinedIcon />,
  },
  // {
  //     name: 'Standard Charts',
  //     path: '/standard-charts',
  //     icon: <InsertChartOutlinedIcon />
  // },
  {
    name: 'Roles And Permissions',
    path: '/roles-and-permissions',
    icon: <LockPersonOutlinedIcon />,
  },
];

export { getGlobalItems, getObjects, getSettings };
