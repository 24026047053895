import { useQuery } from 'react-query';
import { getRecords } from '../../../../api/crud';
import SumRangeCalculator from '../../../../components/charts/rangeCalculator/SumRangeCalculator';

const calculateTotal = (records, field) => {
    if (!records || !field) {
        return 0;
    }
    return records.reduce((total, record) => total + (record[field] || 0), 0);
};

const TotalOrdersPerMonth = () => {
    const chartFilter = {
        filterLogic: null,
        filters: [
            {
                field: 'name',
                operator: 'equals',
                value: 'total_amount_of_orders',
            },
        ],
    };

    const { data: records = [], isLoading: loading } = useQuery({
        queryFn: () => getRecords('standard-charts', chartFilter),
        queryKey: ['total-amount-of-orders'],
    });

    const chartRecord = records.length > 0 ? records[0] : {};
    const caption = chartRecord.caption || '';
    const from = chartRecord.from || '';
    const to = chartRecord.to || '';
    const field = chartRecord.targetField || '';
    const dateField = chartRecord.dateField || '';

    const ordersFilter = {
        filterLogic: '1 AND 2',
        filters: [
            {
                field: dateField,
                operator: 'greaterOrEqual',
                value: from,
            },
            {
                field: dateField,
                operator: 'lessOrEqual',
                value: to,
            },
        ],
    };

    const { data: orders = [], isLoading: ordersLoading } = useQuery({
        queryFn: () => getRecords('orders', ordersFilter),
        queryKey: ['orders-count', from, to],
        enabled: !!from && !!to,  // Only run the query if from and to dates are set
    });

    const total = calculateTotal(orders, field);
    const isLoadingTotal = loading || ordersLoading;

    return (
        <SumRangeCalculator
            chartId={chartRecord._id}
            caption={caption}
            total={total}
            isLoading={isLoadingTotal}
            from={from}
            to={to}
            queryKey='total-amount-of-orders'
        />
    );
};

export default TotalOrdersPerMonth;