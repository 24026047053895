// Implements different search methods based on the object type (lookupTo)
const lookupSearchFilter = (lookupTo, records, searchTerm) => {
  const searchTerms = searchTerm.toLowerCase().split(' ');

  switch (lookupTo) {
    case 'leads':
    case 'contacts':
    case 'users':
      return records.filter((record) => {
        return searchTerms.every((term) => {
          return (
            record?.firstName?.toLowerCase().includes(term) ||
            record?.lastName?.toLowerCase().includes(term)
          );
        });
      });
    case 'deals':
    case 'tenants':
    case 'tenant-plans':
    case 'roles-and-permissions':
      return records.filter((record) => {
          return searchTerms.every((term) => {
            return (
              record?.name?.toLowerCase().includes(term)
            );
          });
        });
    case 'accounts':
      return records.filter((record) => {
        return searchTerms.every((term) => {
          return (
            record?.name?.toLowerCase().includes(term) ||
            record?.firstName?.toLowerCase().includes(term) ||
            record?.lastName?.toLowerCase().includes(term)
          );
        });
      });
    case 'tasks':
      return records.filter((record) => {
          return searchTerms.every((term) => {
            return (
              record?.subject?.toLowerCase().includes(term)
            );
          });
      });
    case 'cases':
      return records.filter((record) => {
          return searchTerms.every((term) => {
            return (
              record?.subject?.toLowerCase().includes(term) ||
              record?.caseNumber?.toLowerCase().includes(term)
            );
          });
      });
    default:
      // Default case if objectType is not recognized
      return records;
  }
};

// Sets the value that shows on the lookup field based on the object type (lookupTo)
const lookupFieldDisplayValue = (lookupTo, record) => {
    switch (lookupTo) {
        case 'leads':
        case 'contacts':
        case 'users':
          return `${record?.firstName} ${record?.lastName}`
        case 'deals':
        case 'tenants':
        case 'tenant-plans':
        case 'roles-and-permissions':
          return record?.name;
        case 'accounts':
          return record?.name ? record?.name : `${record?.firstName} ${record?.lastName}`;
        case 'tasks':
          return record?.subject
        case 'cases':
          return record?.caseNumber;
        default:
          // Default case if objectType is not recognized
          return 'lookupTo object unknown';
      }
};

export { lookupSearchFilter, lookupFieldDisplayValue };
