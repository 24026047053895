import styles from './css/KanbanBoard.module.css';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CardItem from './cards/CardItem'; 

// Utility function to move items between columns
const moveItem = (sourceItems, destItems, sourceIndex, destIndex) => {
  const sourceClone = [...sourceItems];
  const destClone = [...destItems];
  const [removedItem] = sourceClone.splice(sourceIndex, 1);

  destClone.splice(destIndex, 0, removedItem);

  return {
    sourceItems: sourceClone,
    destItems: destClone,
  };
};

// Kanban board component
const KanbanBoard = ({ items, field, values, onDragEnd, objectType, objectTypePlural, navigateTo }) => {
  const [columns, setColumns] = useState({});

  // Set up columns based on the field and values
  useEffect(() => {
    const initialColumns = {};

    // Create a column for each value
    values.forEach((val) => {
      initialColumns[val.value] = {
        title: val.label,
        items: items?.filter((item) => item[field] === val.value),
      };
    });

    setColumns(initialColumns);
  }, [items, field, values]);

  // Handle drag-and-drop events
  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    if (source.droppableId === destination.droppableId) {
      // Reorder within the same column
      const reorderedItems = [...columns[source.droppableId].items];
      const [movedItem] = reorderedItems.splice(source.index, 1);
      reorderedItems.splice(destination.index, 0, movedItem);

      const updatedColumns = {
        ...columns,
        [source.droppableId]: {
          ...columns[source.droppableId],
          items: reorderedItems,
        },
      };

      setColumns(updatedColumns);
    } else {
      // Move between columns
      const sourceItems = columns[source.droppableId].items;
      const destItems = columns[destination.droppableId].items;

      const { sourceItems: updatedSourceItems, destItems: updatedDestItems } = moveItem(
        sourceItems,
        destItems,
        source.index,
        destination.index
      );

      const updatedColumns = {
        ...columns,
        [source.droppableId]: {
          ...columns[source.droppableId],
          items: updatedSourceItems,
        },
        [destination.droppableId]: {
          ...columns[destination.droppableId],
          items: updatedDestItems,
        },
      };

      setColumns(updatedColumns);
    }

    if (onDragEnd) {
      onDragEnd(result);
    }
  };

  return (
    <div className={styles['kanban-container']}>
      <Link className={styles['navigate-to-list-view']} to={navigateTo}>
        {`${objectTypePlural} > List view`}
      </Link>
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className={styles['kanban-board']}>
          {Object.entries(columns)?.map(([key, column]) => (
            <Droppable key={key} droppableId={key}>
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  className={styles['kanban-column']}
                  {...provided.droppableProps}
                >
                  <h2>{column?.title}</h2>
                  {column?.items?.map((item, index) => (
                  <Draggable
                    key={item?._id}
                    draggableId={item?._id}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={styles['kanban-item']}
                      >
                        <CardItem item={item} objectType={objectType}/>
                      </div>
                    )}
                  </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ))}
        </div>
      </DragDropContext>
    </div>
  );
};

export default KanbanBoard;