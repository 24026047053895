import fields from './fields';
import getFieldsByName from '../../utils/getFieldsByName';

const commonLayout = [
    {
        label: 'Tenant Information',
        leftFields: getFieldsByName(fields, ['name', 'recordType', 'subDomain']),
        rightFields: getFieldsByName(fields, ['plan', 'isActive'])
    },
    {
        label: 'Contact Details',
        leftFields: getFieldsByName(fields, ['email', 'phone']),
        rightFields: getFieldsByName(fields, ['website'])
    },
    {
        label: 'Description Information',
        fullWidthFields: getFieldsByName(fields, ['description'])
    }
];

// Define sections
const sectionsByRecordType = {
    'Internal': commonLayout,
    'Customer': commonLayout
};

const getSectionsByRecordType = (recordType)  => {
    return sectionsByRecordType[recordType] || [];
}

export default getSectionsByRecordType;