import { useQuery } from 'react-query';
import { getRecords } from '../../../../api/crud';
import SumRangeCalculator from '../../../../components/charts/rangeCalculator/SumRangeCalculator';
import { getLastDateOfMonth, addDaysToDate } from '../../../../utils/dateUtils';

const setPaymentDates = (orders) => {
    orders?.forEach(order => {
        // Set the paymentDate to the last day of the month
        order.paymentDate = getLastDateOfMonth(addDaysToDate(order?.invoiceDate, order?.paymentTerms));
    });

    return orders;
};

const calculateTotal = (records, from, to, field, dateField) => {
    if (!records || !field) {
        return 0;
    }
    return records?.filter(record => {
        const date = new Date(record[dateField]);
        const value = record[field];
        return date >= new Date(from) && date <= new Date(to) && value !== undefined && !isNaN(value);
      })?.reduce((total, record) => total + (record[field] || 0), 0);
};

const IncomeProjection = () => {
    const chartFilter = {
        filterLogic: null,
        filters: [
            {
                field: 'name',
                operator: 'equals',
                value: 'income_projection',
            },
        ],
    };

    const { data: records = [], isLoading: loading } = useQuery({
        queryFn: () => getRecords('standard-charts', chartFilter),
        queryKey: ['income-projection'],
    });

    const chartRecord = records.length > 0 ? records[0] : {};
    const caption = chartRecord.caption || '';
    const from = chartRecord.from || '';
    const to = chartRecord.to || '';
    const field = chartRecord.targetField || '';
    const dateField = chartRecord.dateField || '';

    const { data: orders, isLoading: ordersLoading } = useQuery({
        queryFn: () => getRecords('orders', {}),
        queryKey: ['orders-count', from, to],
        enabled: !!from && !!to,  // Only run the query if from and to dates are set
    });

    const total = calculateTotal(setPaymentDates(orders), from, to, field, dateField);
    const isLoadingTotal = loading || ordersLoading;

    return (
        <SumRangeCalculator
            chartId={chartRecord?._id}
            caption={caption}
            total={total}
            isLoading={isLoadingTotal}
            from={from}
            to={to}
            queryKey='income-projection'
        />
    );
};

export default IncomeProjection;