import firstCharacterToUpperCase from './firstCharacterToUpperCase';

const getModalTitle = (record, objectType) => {
  switch (objectType) {
    case 'lead':
      return `${firstCharacterToUpperCase(objectType)} > ${record?.client}`;
    case 'contact':
    case 'user':
      return `${firstCharacterToUpperCase(objectType)} > ${record?.firstName} ${record?.lastName}`;
    case 'deal':
    case 'account':
    case 'tenant':
    case 'tenant-plan':
    case 'order':
      return `${firstCharacterToUpperCase(objectType)} > ${record?.name}`;
    case 'task':
    case 'case':
      return `${firstCharacterToUpperCase(objectType)} > ${record?.subject}`
    default:
      // Default case if objectType is not recognized
      return 'Unknown Title';
  }
};

export default getModalTitle;