import { useState, useEffect, useMemo, useContext } from 'react';
import { AuthContext } from '../../../../contexts/AuthContext';
import getSectionsByRecordType from '../../../../objectManager/user/layouts';
import DynamicForm from '../../../../components/dynamicForm/DynamicForm';
import formatDateAndTime from '../../../../utils/formatDateAndTime';
import fields from '../../../../objectManager/user/fields';
import useEffectformValues from '../../../../utils/formValues';
import RelatedList from '../../../../components/relatedList/RelatedList';
import CustomLink from '../../../../components/customLink/CustomLink';

const RecordForm = ({ isLoading, record, isEditing, onClose, fieldsDisabled, setFieldsDisabled, recordType, handleDelete, isCreatingUserFromTenant, tenantId }) => {
  const { getUserId } = useContext(AuthContext);
  const [formValues, setFormValues] = useState({});

  const defaultValues = useEffectformValues(isEditing, record, fields, getUserId());

  useEffect(() => {
    const newFormValues = {
      ...defaultValues,
      recordType: isEditing ? record?.recordType : recordType,
      lastModifiedBy: isEditing ? record?.lastModifiedBy?._id : null,
      createdAt: isEditing ? formatDateAndTime(record?.createdAt) : '',
      updatedAt: isEditing ? formatDateAndTime(record?.updatedAt) : ''
    };
  
    if (isCreatingUserFromTenant) {
      newFormValues.tenantId = tenantId;
    }

    setFormValues(newFormValues);
  }, [record, isEditing, isCreatingUserFromTenant, tenantId]);

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => <CustomLink to={`/task/${row._id}`} text={row.subject} />,
        id: 'subject',
        header: 'Subject',
      },
      {
        accessorKey: 'status', 
        header: 'Status'
      }
    ],
  []
  );

  const leadColumns = useMemo(
    () => [
      {
        accessorFn: (row) => <CustomLink to={`/lead/${row._id}`} text={`${row.firstName} ${row.lastName}`} />,
        id: 'name',
        header: 'Name',
      },
      {
        accessorKey: 'status',
        header: 'Status'
      }
    ],
  []
  );

  const dealColumns = useMemo(
    () => [
      {
        accessorFn: (row) => <CustomLink to={`/order/${row._id}`} text={row.name} />,
        id: 'name',
        header: 'Name',
      },
      {
        accessorKey: 'stage',
        header: 'Stage'
      }
    ],
  []
  );

  const orderColumns = useMemo(
    () => [
      {
        accessorFn: (row) => <CustomLink to={`/order/${row._id}`} text={row.name} />,
        id: 'name',
        header: 'Name',
      },
      {
        accessorKey: 'orderNumber',
        header: 'Order Number'
      }
    ],
  []
  );

  const passwordChangeAuditHistoryColumns = useMemo(
    () => [
      {
        accessorKey: 'changeDate',
        header: 'change Date',
      },
      {
        accessorKey: 'ipAddress',
        header: 'IP Address'
      },
      {
        accessorKey: 'userAgent',
        header: 'user Agent'
      }
    ],
  []
  );

  const filter = {
    field: 'assignedTo',
    value: record?._id
  }

  const orderFilter = {
    field: 'projectManager',
    value: record?._id
  }

  const dealFilter = {
    field: 'salesRep',
    value: record?._id
  }

  const passwordChangeAuditHistoryFilter = {
    field: 'userId',
    value: record?._id
  }

  return (
    <div className={isEditing ? 'record-form' : ''}>
      {!isLoading && 
        <DynamicForm
          objectType='user'
          listViewQueryKey='users'
          onClose={onClose}
          isEditing={isEditing}
          record={record}
          sections={getSectionsByRecordType(formValues?.recordType)}
          formValues={formValues}
          setFormValues={setFormValues}
          fieldsDisabled={fieldsDisabled}
          setFieldsDisabled={setFieldsDisabled}
          handleDelete={handleDelete}
        />
      }
      {(!isLoading && isEditing) && (
        <div className='related-list'>
          <RelatedList
            title='Tasks'
            filter={filter}
            relatedItem='tasks'
            columns={columns}
            isExpandedByDefault={true}
          />
          <RelatedList
            title='Cases'
            filter={filter}
            relatedItem='cases'
            columns={columns}
            isExpandedByDefault={false}
          />
          <RelatedList
            title='Leads'
            filter={dealFilter}
            relatedItem='leads'
            columns={leadColumns}
            isExpandedByDefault={false}
          />
          <RelatedList
            title='Deals'
            filter={dealFilter}
            relatedItem='deals'
            columns={dealColumns}
            isExpandedByDefault={false}
          />
          <RelatedList
            title='Orders'
            filter={orderFilter}
            relatedItem='orders'
            columns={orderColumns}
            isExpandedByDefault={false}
          />
          <RelatedList
            title='Password Change Audit History'
            filter={passwordChangeAuditHistoryFilter}
            relatedItem='password-change-audit-history'
            columns={passwordChangeAuditHistoryColumns}
            isExpandedByDefault={false}
          />
        </div>
      )}
    </div>
  );
};

export default RecordForm;