import lead from '../objectManager/lead/objectDetails';
import deal from '../objectManager/deal/objectDetails';
import order from '../objectManager/order/objectDetails';
import contact from '../objectManager/contact/objectDetails';
import account from '../objectManager/account/objectDetails';
import user from '../objectManager/user/objectDetails';
import task from '../objectManager/task/objectDetails';
import caseDetails from '../objectManager/case/objectDetails';
import tenant from '../objectManager/tenant/objectDetails';
import tenantPlan from '../objectManager/tenantPlan/objectDetails';

const getObjectDetailsByName = (name) => {
    switch(name.toLowerCase()){
        case 'lead':
        case 'leads':
            return lead;
        case 'deal':
        case 'deals':
            return deal;
        case 'order':
        case 'orders':
            return order;
        case 'contact':
        case 'contacts':
            return contact;
        case 'account':
        case 'accounts':
            return account;
        case 'user':
        case 'users':
            return user;
        case 'task':
        case 'tasks':
            return task;
        case 'case':
        case 'cases':
            return caseDetails;
        case 'tenant':
        case 'tenants':
            return tenant;
        case 'tenant-plan':
        case 'tenant-plans':
            return tenantPlan;
        default:
            return null;
    }
};

export default getObjectDetailsByName;