import { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { toast } from 'react-toastify';
import { changePassword } from '../../api/authentication';

const ChangePasswordModal = ({ open, onClose, userId }) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isLengthValid, setIsLengthValid] = useState(false);
  const [hasUppercase, setHasUppercase] = useState(false);
  const [hasLowercase, setHasLowercase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);

  useEffect(() => {
    validatePassword(newPassword);
  }, [newPassword]);

  const validatePassword = (password) => {
    const lengthValid = password.length >= 8;
    const uppercaseValid = /[A-Z]/.test(password);
    const lowercaseValid = /[a-z]/.test(password);
    const numberValid = /\d/.test(password);
    const specialCharValid = /[@$!%*?&]/.test(password);

    setIsLengthValid(lengthValid);
    setHasUppercase(uppercaseValid);
    setHasLowercase(lowercaseValid);
    setHasNumber(numberValid);
    setHasSpecialChar(specialCharValid);

    return lengthValid && uppercaseValid && lowercaseValid && numberValid && specialCharValid;
  };

  const handlePasswordChange = async () => {
    if (!validatePassword(newPassword)) {
      toast.error(
        'Password must be at least 8 characters long and include uppercase and lowercase letters, a number, and a special character'
      );
      return;
    }

    if (newPassword !== confirmPassword) {
      toast.error('New password and confirm password do not match');
      return;
    }

    setIsSubmitting(true);
    try {
      await changePassword(userId, currentPassword, newPassword);
      setIsSubmitting(false);
      clearInputs();
      toast.success('Password successfully updated!');
      onClose();
    } catch (error) {
      setIsSubmitting(false);
      console.error('Error updating password:', error);
      toast.error(`Error updating password: ${error.response.data.message}`);
    }
  };

  const cancelButtonStyles = {
    color: 'var(--primary-color)',
    border: 'var(--thin-border)',
    backgroundColor: 'var(--secondary-text-color)',
  };

  const changePasswordButtonStyles = {
    color: 'var(--secondary-text-color)',
    backgroundColor: 'var(--primary-color)',
  };

  const clearInputs = () => {
    setCurrentPassword('');
    setNewPassword('');
    setConfirmPassword('');
  };

  const handleClose = () => {
    clearInputs();
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Change Password</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          id="currentPassword"
          label="Old Password"
          type="password"
          fullWidth
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
        />
        <TextField
          margin="dense"
          id="newPassword"
          label="New Password"
          type="password"
          fullWidth
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <TextField
          margin="dense"
          id="confirmPassword"
          label="Confirm Password"
          type="password"
          fullWidth
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <List>
          <ListItem>
            <ListItemIcon>
              {isLengthValid ? (
                <CheckCircleIcon style={{ color: 'green' }} />
              ) : (
                <CancelIcon style={{ color: 'red' }} />
              )}
            </ListItemIcon>
            <ListItemText primary="At least 8 characters" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              {hasUppercase ? (
                <CheckCircleIcon style={{ color: 'green' }} />
              ) : (
                <CancelIcon style={{ color: 'red' }} />
              )}
            </ListItemIcon>
            <ListItemText primary="At least one uppercase letter" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              {hasLowercase ? (
                <CheckCircleIcon style={{ color: 'green' }} />
              ) : (
                <CancelIcon style={{ color: 'red' }} />
              )}
            </ListItemIcon>
            <ListItemText primary="At least one lowercase letter" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              {hasNumber ? (
                <CheckCircleIcon style={{ color: 'green' }} />
              ) : (
                <CancelIcon style={{ color: 'red' }} />
              )}
            </ListItemIcon>
            <ListItemText primary="At least one number" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              {hasSpecialChar ? (
                <CheckCircleIcon style={{ color: 'green' }} />
              ) : (
                <CancelIcon style={{ color: 'red' }} />
              )}
            </ListItemIcon>
            <ListItemText primary="At least one special character (@, $, !, %, *, ?, &)" />
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          style={cancelButtonStyles}
          onClick={handleClose}
          size="small"
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button
          style={changePasswordButtonStyles}
          onClick={handlePasswordChange}
          size="small"
          disabled={isSubmitting}
        >
          Change Password
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangePasswordModal;