import styles from './css/EmailView.module.css';
import { formatDateTime } from '../../utils/dateUtils';
import { useRef, useEffect } from 'react';

const EmailView = ({ emailContent }) => {
  const { subject, htmlBody, textBody, textAsHtml, date } = emailContent;
  const iframeRef = useRef(null);

  useEffect(() => {
    if (iframeRef.current && htmlBody) {
      const iframe = iframeRef.current;
      const doc = iframe.contentDocument;

      // Write the content into the iframe
      doc.open();
      doc.write(htmlBody);
      doc.close();

      // Adjust the height of the iframe based on the content height
      iframe.onload = () => {
        const iframeBody = doc.body;
        iframe.style.height = `${iframeBody.scrollHeight}px`;
      };
    }
  }, [htmlBody]);

  return (
    <div className={styles['email-container']}>
      <h2 className={styles['email-subject']}>{subject || 'No Subject'}</h2>
      <p className={styles['email-date']}>{formatDateTime(date)}</p>
      <div className={styles['email-body']}>
        {htmlBody ? (
          <iframe ref={iframeRef} className={styles['email-iframe']} title="Email Content" />
        ) : textAsHtml ? (
          <iframe
            ref={iframeRef}
            className={styles['email-iframe']}
            title="Email Text as HTML"
            dangerouslySetInnerHTML={{ __html: textAsHtml }}
          />
        ) : (
          <p className={styles['email-text']}>{textBody || 'No content available.'}</p>
        )}
      </div>
    </div>
  );
};

export default EmailView;